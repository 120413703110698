import axios from 'axios';
import { decryptData, encryptData, encryptSession } from './crypto.utils';
import store from '../redux/store';

/*
  this instance used to handle protected API calls
  in the future will check how to consolidate all instances into one if possible
*/
export const APIEnigma = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  validateStatus: false,
});

APIEnigma.interceptors.request.use(async (config) => {
  const state = await store.getState();

  const user = state.auth.userInfo;
  const session = user.session;
  const userToken = state.auth.userToken;
  if (session) {
    //encrypt data
    config.data = { data: encryptData(JSON.stringify(config.data), session) };
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${userToken}`,
      'X-Enigma': encryptSession(user.publicKey, user.session).toString(
        'base64'
      ),
    };
  }
  return config;
});

APIEnigma.interceptors.response.use((response) => {
  const state = store.getState();
  const user = state.auth.userInfo;
  const session = user.session;

  if (session && response.data) {
    //decrypt data
    response.data = JSON.parse(decryptData(response.data, session));
  }
  return response;
});
