import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormControl } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import FieldTextField from '../../../commonComponents/FormikMuiFields/FieldTextField';
import * as Yup from 'yup';
import { UserAPI } from '../../../services/user.api';
import { useDispatch } from 'react-redux';
import { setError } from '../../../redux/features/message.slice';
const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required Field'),
});
const ForgotPasswordForm = () => {
  const dispatch = useDispatch();
  const [resetRequestSent, setResetRequestSent] = useState(false);
  const resetPasswordHandler = async (values) => {
    try {
      await UserAPI.forgotPassword(values.email);
      setResetRequestSent(true);
    } catch (error) {
      console.log(error);
      dispatch(
        setError('Forgot password request failed, Please try again later')
      );
    }
  };
  if (resetRequestSent) {
    return (
      <div className='flex flex-col align-middle items-center justify-center'>
        <p className='text-lg font-semibold'>
          An Email with Reset Password link will be sent shortly to your Email
        </p>
        <p>Please check your email.</p>
      </div>
    );
  }
  return (
    <>
      <Formik
        initialValues={{ email: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          resetPasswordHandler(values);
        }}
      >
        <Form noValidate>
          <FormControl fullWidth margin='normal' className='drop-shadow-md '>
            <Field
              component={FieldTextField}
              name='email'
              label='Email'
              placeholder='example@company.com'
              required
            />
          </FormControl>
          <div className='flex flex-col justify-between md:flex-row mt-6'>
            <button
              type='submit'
              className='button-primary login-btn py-4 bg-secondary hover:bg-primary text-white font-bold'
            >
              Forgot Password
            </button>

            <Link to='/login' className='font-bold text-secondary py-4'>
              Back to Login
            </Link>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
