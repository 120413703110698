import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import OnboardCompany from "../pages/Onboarding/OnboardCompany";
import CompanySubscriptionPlans from "../pages/Onboarding/OnboardCompanyForms/CompanySubscriptionPlans";
import { CompanySubscriptionPayment } from "../pages/Onboarding/OnboardCompanyForms/CompanySubscriptionPayment";
import CompanyOnboardCompleted from "../pages/Onboarding/OnboardCompanyForms/CompanyOnboardCompleted";

import EmployeeDocumentSignForm from "../pages/Onboarding/OnboardEmployeeForms/EmployeeDocumentSignForm";
import EmployeePaymentMethodForm from "../pages/Onboarding/OnboardEmployeeForms/EmployeePaymentMethodForm";
import EmployeeIntroduceYourself from "../pages/Onboarding/OnboardEmployeeForms/EmployeeIntroduceYourself";
import EmployeeOnboardingCompleted from "../pages/Onboarding/OnboardEmployeeForms/EmployeeOnboardCompleted";
import OnboardEmployee from "../pages/Onboarding/OnboardEmployee";
import CompanyConnectBankAccount from "../pages/Onboarding/OnboardCompanyForms/CompanyConnectBankAccount";

const OnboardingRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to='/onboarding/account' replace />} />

      <Route path='/company/*' element={<OnboardCompany />} />
      <Route path='/subscription' element={<CompanySubscriptionPlans />} />
      <Route
        path='/company/bank-account'
        element={<CompanyConnectBankAccount />}
      />
      <Route
        path='/company/checkout'
        element={<CompanySubscriptionPayment />}
      />
      <Route path='/company/payment' element={<CompanySubscriptionPayment />} />
      <Route path='/company/completed' element={<CompanyOnboardCompleted />} />

      <Route path='/employee/*' element={<OnboardEmployee />} />
      <Route
        path='/employee/documents'
        element={<EmployeeDocumentSignForm />}
      />
      <Route path='/employee/payment' element={<EmployeePaymentMethodForm />} />
      <Route
        path='/employee/introduction'
        element={<EmployeeIntroduceYourself />}
      />
      <Route
        path='/employee/completed'
        element={<EmployeeOnboardingCompleted />}
      />
    </Routes>
  );
};

export default OnboardingRoutes;
