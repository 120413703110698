import React, { useEffect, useState } from "react";
import Layout from "../../commonComponents/Layout";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Document = () => {
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const [docs, setDocs] = useState([]);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [docErr, setDocErr] = useState(null); // {common:"general err message"}
  console.log(docs);

  const getAllDocs = async () => {
    setIsDocLoading(true);
    setDocErr(null);
    const docRes = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/documents/${userInfo._id}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    const docsResult = await docRes.json();
    if (docsResult.success) {
      setDocs(docsResult.data);
    } else {
      setDocErr(docsResult.error);
    }
    setIsDocLoading(false);
  };

  useEffect(() => {
    if (userToken) {
      getAllDocs();
    }
  }, [userToken]);
  // console.table(user)
  return (
    <Layout className="text-center">
      <div className="box-wrapper flex justify-between align-middle">
        <div className="title box">
          <h3 className="text-5xl">Documents</h3>{" "}
        </div>
        <div className="button-box">
          {" "}
          <Link
            to="/upload-file"
            className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md"
          >
            Upload Files
          </Link>
        </div>
      </div>
      <div className="table-wrapper border border-borderColor rounded-md mt-9 overflow-hidden">
        <div className="table-heading grid grid-cols-4 bg-gray p-5">
          <span className="font-bold">File</span>
          <span className="font-bold inline-flex items-center">
            Year <Icon icon="ic:round-keyboard-arrow-down" />
          </span>
          <span className="font-bold">Uploaded By</span>
          <span className="flex justify-end">
            <Icon className="" height={30} icon="bi:filter-left" />
          </span>
        </div>
        {
          // <div className="grid grid-cols-4 p-5 border-b border-borderColor">
          //     <span className=''><a href="/" rel="noopener noreferrer"><Icon icon="bi:file-pdf" /></a></span>
          //     <span className=''>2022</span>
          //     <span className=''>Kader</span>
          //     <span className=' text-right'>Edit | Delete</span>
          // </div>
          // <div className="grid grid-cols-4 p-5 border-b border-borderColor">
          //     <span className=''><a href="/" rel="noopener noreferrer"><Icon icon="bi:file-pdf" /></a></span>
          //     <span className=''>2012</span>
          //     <span className=''>Kader</span>
          //     <span className=' text-right'>Edit | Delete</span>
          // </div>
          // <div className="grid grid-cols-4 p-5 border-b border-borderColor">
          //     <span className=''><a href="/" rel="noopener noreferrer"><Icon icon="bi:file-pdf" /></a></span>
          //     <span className=''>2021</span>
          //     <span className=''>Kader</span>
          //     <span className=' text-right'>Edit | Delete</span>
          // </div>
        }
        {isDocLoading ? (
          <p>Loading........</p>
        ) : (
          docs.map((doc) => (
            <div className="grid grid-cols-4 p-5 border-b border-borderColor">
              {
                //<span className=''><a href="/" rel="noopener noreferrer"><Icon icon="bi:file-pdf" />{doc.name}</a></span>
                // <span className=''>{doc.year}</span>
                // <span className=''>Kader</span>
                // <span className=' text-right'>Edit | Delete</span
              }
            </div>
          ))
        )}
      </div>
    </Layout>
  );
};

export default Document;
