import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FormControl } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import FieldTextField from '../../../commonComponents/FormikMuiFields/FieldTextField';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserAPI } from '../../../services/user.api';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required Field'),
  confirmPassword: Yup.string()
    .required('Required Field')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
const ResetPasswordForm = () => {
  const navigate = useNavigate();

  const [queryParameters] = useSearchParams();
  const [passwordSaved, setPasswordSaved] = useState(false);

  const email = queryParameters.get('email');
  const code = queryParameters.get('code');
  const hasCorrectParams = !!code && !!email;

  const resetPasswordHandler = async (values) => {
    try {
      const result = await UserAPI.resetPassword(email, code, values.password);
      if (result.ok === true) {
        setPasswordSaved(true);
        toast.success('Password Updated successfully');
      } else throw new Error(result.message);
    } catch (error) {
      toast.error(
        error.message || 'Password Reset Failed, Please try again later.'
      );
      navigate('/forgot-password');
    }
  };

  useEffect(() => {
    if (passwordSaved) {
      navigate('/login');
    }
  }, [passwordSaved, navigate]);

  if (!hasCorrectParams) {
    return (
      <div className='flex flex-col align-middle items-center justify-center'>
        <p className='text-3xl font-bold mb-4'>
          Invalid Reset Password Request!
        </p>
        <p className='text-base'>
          Please use the link from the Password Reset Email that was sent to you
          earlier
        </p>
        <div className='flex w-full justify-end  m-4'>
          <Link to='/forgot-password' className='font-bold text-secondary py-4'>
            Go To Forgot Password
          </Link>
        </div>
      </div>
    );
  }
  return (
    <>
      <Formik
        initialValues={{ password: '', confirmPassword: '' }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          resetPasswordHandler(values);
        }}
      >
        <Form noValidate>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              component={FieldTextField}
              name='password'
              label='New Password'
              type='password'
              required
            />
          </FormControl>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              component={FieldTextField}
              name='confirmPassword'
              label='Confirm Password'
              type='password'
              required
            />
          </FormControl>
          <div className='flex flex-col justify-between md:flex-row mt-6'>
            <button
              type='submit'
              className='button-primary login-btn py-4 bg-secondary hover:bg-primary text-white font-bold'
            >
              Reset Password
            </button>

            <Link to='/login' className='font-bold text-secondary py-4'>
              Back to Login
            </Link>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
