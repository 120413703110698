import React from "react";
import Payment from "../../../commonComponents/Payment/Payment";
import { PaymentTypes } from "../../../commonComponents/Payment/paymentConstants";
import OnboardingContentWrapper from "../components/OnboardingContentWrapper";

export const CompanySubscriptionPayment = () => {
  return (
    <OnboardingContentWrapper>
      <div className="flex-1 text-2xl text-center font-bold px-2 mt-10">
        Checkout
      </div>
      <div className="onboard_form_wrapper border-2 border-gray rounded-lg">
        {/* <div className="mt-60 flex flex-wrap justify-around py-20 md:px-8"> */}
        <Payment _paymentType={PaymentTypes.SUBSCRIPTION} />
      </div>
    </OnboardingContentWrapper>
  );
};
