import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SessionAPI } from '../../services/session.api';
import { setError } from './message.slice';
import { generateSessionKeys } from '../../utility/crypto.utils';

export const initSession = createAsyncThunk(
  'secure/initiateSession',
  async (_, thunkAPI) => {
    try {
      const result = await SessionAPI.initiateSession();
      if (result?.ok === true) {
        const session = generateSessionKeys();
        thunkAPI.dispatch(setPublicKey(result.data.publicKey));
        thunkAPI.dispatch(setSession(session));
        return result.data;
      } else {
        const message = result?.error.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.log(error?.response);
        const result = error.response?.data;
        const message = result?.error?.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    }
  }
);

const secureSlice = createSlice({
  name: 'secure',
  initialState: {
    isLoading: false,
    error: null,
    publicKey: null,
    session: null,
  },
  reducers: {
    setPublicKey(state, action) {
      state.publicKey = action.payload;
    },
    setSession(state, action) {
      state.session = action.payload;
    },
    clearSecureSession(state) {
      state.isLoading = false;
      state.error = null;
      state.publicKey = null;
      state.session = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initSession.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(initSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(initSession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectSecurePublicKey = (state) => state.secure.publicKey;

export const { setPublicKey, setSession, clearSecureSession } =
  secureSlice.actions;
export default secureSlice.reducer;
