import React, { useEffect } from "react";
import OnboardingContentWrapper from "./components/OnboardingContentWrapper";
import OnboardingStepper from "./components/OnboardingStepper";
import Wizard from "../../commonComponents/FormikRoutedWizard/Wizard";
import WizardStep from "../../commonComponents/FormikRoutedWizard/WizardStep";
import { useDispatch, useSelector } from "react-redux";

import EmployeeWorkAddressForm from "./OnboardEmployeeForms/EmployeeWorkAddressForm";
import EmployeeFederalTaxForm from "./OnboardEmployeeForms/EmployeeFederalTaxForm";
import EmployeePersonalInformationForm from "./OnboardEmployeeForms/EmployeePersonalInformationForm";
import EmployeeResidentAddressForm from "./OnboardEmployeeForms/EmployeeResidentAddressForm";
import {
  updateCompanyEmployee,
  selectOnboardEmployeeStatus,
  selectOnboardUserActiveStep,
  selectOnboardUserFormData,
  selectOnboardUserSteps,
  setOnboardUserSteps,
  updateOnboardUserActiveStep,
} from "../../redux/features/onboardEmployee.slice";
import { useNavigate } from "react-router-dom";
import EmployeeEmploymentEligibilityForm from "./OnboardEmployeeForms/EmployeeEmploymentEligibilityForm";
import { validationSchema as personalInfoValidation } from "./OnboardEmployeeForms/EmployeePersonalInformationForm";
import { validationSchema as residentAddressValidation } from "./OnboardEmployeeForms/EmployeeResidentAddressForm";
import { validationSchema as workAddressValidations } from "./OnboardEmployeeForms/EmployeeWorkAddressForm";
import { validationSchema as employmentEligibilityValidation } from "./OnboardEmployeeForms/EmployeeEmploymentEligibilityForm";
import { validationSchema as federalTaxValidation } from "./OnboardEmployeeForms/EmployeeFederalTaxForm";
import {
  selectEmployeeInfo,
  selectUserInfo,
} from "../../redux/features/auth.slice";
import { toast } from "react-toastify";

const validationSchema = [
  personalInfoValidation,
  residentAddressValidation,
  workAddressValidations,
  employmentEligibilityValidation,
  federalTaxValidation,
];

const EmployeeOnboardPhases = {
  NONE: "none",
  // MAIN_INFO: "main information",
  PAYMENT: "payment",
  DOCS: "documents",
  ABOUT: "about",
};
const OnboardEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUserInfo);
  const employee = useSelector(selectEmployeeInfo);
  const activeStep = useSelector(selectOnboardUserActiveStep);
  const userOnboardingData = useSelector(selectOnboardUserFormData);
  const steps = useSelector(selectOnboardUserSteps);
  const onboardEmployeeStatus = useSelector(selectOnboardEmployeeStatus);

  const handleFormStepsChange = (steps) => {
    //TODO: change index to read from userOnboard state
    steps.push(
      {
        index: 6,
        label: "Payment Method",
        path: "payment",
        description: "User Payment Method information",
      },
      {
        index: 7,
        label: "Documents",
        path: "documents",
        description: "Sign Employment Documents",
      },
      {
        index: 8,
        label: "About You",
        path: "introduction",
        description: "Introduce yourself to the team",
      }
    );
    dispatch(setOnboardUserSteps(steps));
  };

  const handleFormSubmit = (values) => {
    // alert(JSON.stringify(values));
    dispatch(updateCompanyEmployee(values));
  };

  useEffect(() => {
    if (onboardEmployeeStatus === "succeeded") {
      navigate("/onboarding/employee/payment");
      toast.success("Progress saved");
    } else if (onboardEmployeeStatus === "failed") {
      toast.error("Company was not created. Please try again later.");
    }
  }, [onboardEmployeeStatus]);

  const handleActiveStepChange = (activeStep) => {
    dispatch(updateOnboardUserActiveStep(activeStep));
  };

  useEffect(() => {
    if (employee && steps) {
      if (employee?.phase === EmployeeOnboardPhases.PAYMENT) {
        navigate("/onboarding/employee/payment");
      } else if (employee?.phase === EmployeeOnboardPhases.DOCS) {
        navigate("/onboarding/employee/documents");
      } else if (employee?.phase === EmployeeOnboardPhases.ABOUT) {
        navigate("/onboarding/employee/introduction");
      }
    }
  }, [employee, steps]);
  return (
    <OnboardingContentWrapper>
      <div className="flex-1 text-2xl mt-10 text-center font-bold px-2">
        <OnboardingStepper isUserOnboard={true} />
      </div>
      <div className="flex-1 p-1 flex flex-row ">
        <Wizard
          wizardRoutePath="/onboarding/employee"
          initialValues={userOnboardingData}
          onSubmit={handleFormSubmit}
          validationSchema={validationSchema[activeStep]}
          // validationSchema={validationSchema[activeStep]}
          onFormStepsChange={handleFormStepsChange}
          onActiveStepChange={handleActiveStepChange}
        >
          <WizardStep
            label="Personal Information"
            path="personal-information"
            element={<EmployeePersonalInformationForm />}
            description={"user personal information"}
          />
          <WizardStep
            label="Resident Address"
            path="resident-address"
            element={<EmployeeResidentAddressForm />}
            description={"user resident address information"}
          />
          <WizardStep
            label="Work Address"
            path="work-address"
            element={<EmployeeWorkAddressForm />}
            description={"user work address information"}
          />
          <WizardStep
            label="Employment Eligibility"
            path="eligibility"
            element={<EmployeeEmploymentEligibilityForm />}
            description={"user employment eligibility information"}
          />
          <WizardStep
            label="Federal Tax"
            path="tax"
            element={<EmployeeFederalTaxForm />}
            description={"user federal tax information"}
          />
          {/* <WizardStep
            label="Equal Employment Opportunity"
            path="eeo"
            element={<UserEqualOpportunityForm />}
            description={"user federal tax information"}
          /> */}
        </Wizard>
      </div>
    </OnboardingContentWrapper>
  );
};

export default OnboardEmployee;
