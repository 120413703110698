import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import productReducer from './features/products.slice';
import authReducer from './features/auth.slice';
import userReducer from './features/user.slice';
import messageReducer from './features/message.slice';
import onboardCompanyReducer from './features/onboardCompany.slice';
import onboardEmployeeReducer from './features/onboardEmployee.slice';
import companyEmployeesReducer from './features/companyEmployees.slice';
import secureSliceReducer from './features/secure.slice';
import companyDataReducer from './features/companyData.slice';
import payrollReducer from './features/payroll.slice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    message: messageReducer,
    onboardCompany: onboardCompanyReducer,
    companyData: companyDataReducer,
    companyEmployees: companyEmployeesReducer,
    onboardEmployee: onboardEmployeeReducer,
    products: productReducer,
    secure: secureSliceReducer,
    payroll: payrollReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export default store;
