import React from "react";
import { useField } from "formik";
import StyledTextField from "../MuiStyled/StyledTextField";

const FieldTextField = ({ onFieldValueChanged, helperText, ...props }) => {
  const [field, meta] = useField(props.field);
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (event) => {
    field.onChange(event);
    if (typeof onFieldValueChanged === "function")
      onFieldValueChanged(field.name, event.target.value);
  };
  return (
    <StyledTextField
      {...field}
      {...props}
      error={hasError}
      helperText={hasError ? error : helperText}
      onBlur={function (e) {
        field.onBlur(e ?? field.name);
      }}
      onChange={handleChange}
    />
  );
};

export default FieldTextField;
