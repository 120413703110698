import { useNavigate, useSearchParams } from "react-router-dom";
import { PaymentTypes } from "./paymentConstants";
import { BsFillCheckCircleFill } from "react-icons/bs";
const PaymentResponse = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const paymentType = searchParams.get("type");
  const clientSecret = searchParams.get("payment_intent_client_secret");

  const btnClickHandler = (e) => {
    let url = ``;
    // const paymentType = params.get("type");
    setSearchParams((searchParams) => {
      searchParams.set("client_secret", clientSecret);
      return searchParams;
    });
    switch (paymentType) {
      case PaymentTypes.SUBSCRIPTION:
        url = `/onboarding/company/completed`;
        break;

      default:
        url = `/onboarding/company/completed`;

        break;
    }
    navigate(url);
  };
  return (
    <div id="payment-message" className="flex align-middle justify-around ">
      <div className="flex-col w-full">
        <div className="flex items-start  mb-4 px-5 ">
          <BsFillCheckCircleFill color="green" size="48px" className="" />
          <div className="flex-col ml-3">
            <span className="text-3xl mb-4">
              Congratulations!
              <br />
            </span>
            <span className="text-xl ">
              Your payment has been successfully processed
            </span>
          </div>
        </div>
        <div className="flex justify-end">
          <button
            className="rounded-lg bg-secondary text-white hover:bg-primary py-4 px-16 font-bold ml-auto"
            onClick={btnClickHandler}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentResponse;
