import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectBankAccStatus,
  selectOnboardError,
  updateActiveStep,
  selectBankAccountStep,
  selectCompanyPlaidLink,
  getCompanyPlaidLinkToken,
  exchangePlaidToken,
  selectOnboardingLoading,
} from '../../../redux/features/onboardCompany.slice';
import OnboardingContentWrapper from '../components/OnboardingContentWrapper';
import OnboardingStepper from '../components/OnboardingStepper';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import PlaidLink from '../../../commonComponents/Plaid/PlaidLink';

const CompanyConnectBankAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bankAccountStep = useSelector(selectBankAccountStep);
  const bankAccStatus = useSelector(selectBankAccStatus);
  const onboardError = useSelector(selectOnboardError);
  const isLoading = useSelector(selectOnboardingLoading);
  const plaidLinkToken = useSelector(selectCompanyPlaidLink);

  const handlePlaidLink = (e) => {
    e.preventDefault();
    dispatch(getCompanyPlaidLinkToken());
  };

  const handlePlaidLinkOnSuccess = (metadata) => {
    dispatch(
      exchangePlaidToken({
        plaidPublicToken: metadata.public_token,
        institution: metadata.institution,
        accounts: metadata.accounts,
      })
    );
  };

  useEffect(() => {
    dispatch(updateActiveStep(bankAccountStep));
  }, []);

  useEffect(() => {
    if (bankAccStatus === 'succeeded') {
      // alert(bankAccStatus);
      // navigate('/onboarding/subscription');

      setTimeout(() => {
        navigate('/onboarding/company/completed');
      }, '2000');
    }
  }, [bankAccStatus, navigate, onboardError]);

  return (
    <OnboardingContentWrapper>
      <div className="flex-1 text-2xl text-center font-bold px-2">
        <OnboardingStepper />
      </div>
      <div className="flex flex-col p-4 relative">
        {isLoading && (
          <div className="absolute inset-0 bg-slate-500 bg-opacity-10 rounded-lg w-full h-full z-20 flex flex-col justify-center  items-center">
            <CircularProgress />
            {/* <span className="bg-slate-500 bg-opacity-50 mt-6 font-bold p-2">
              LOADING...
            </span> */}
          </div>
        )}
        <h1 className="text-xl">Bank Account</h1>
        <p className="font-semibold">
          We'll use your checking account info to debit for wages, taxes, and
          your monthly Beyond Fee.
        </p>
        <div className="flex flex-col justify-center h-full ">
          <div className="border-2 border-[#c7c7c7] rounded-md p-4 mt-6">
            <h1 className="text-xl mb-4 font-sans">
              Connect your Bank Account to Beyond
            </h1>
            <p className="my-6">
              We use Plaid (a financial technology service) to securely connect
              your bank account to your Beyond account - it only takes a couple
              minutes.
            </p>
            <div className="mt-3 flex justify-end">
              <button
                type="button"
                className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
                onClick={handlePlaidLink}
              >
                Plaid Link
              </button>
            </div>
          </div>
          <PlaidLink
            linkToken={plaidLinkToken}
            onSuccessHandler={handlePlaidLinkOnSuccess}
          />
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default CompanyConnectBankAccount;
