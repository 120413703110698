import React from "react";

import { useNavigate } from "react-router-dom";
import OnboardingContentWrapper from "../components/OnboardingContentWrapper";
import { useSelector } from "react-redux";
import {
  selectCompany,
  selectUserInfo,
} from "../../../redux/features/auth.slice";

const CompanyOnboardCompleted = () => {
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const user = useSelector(selectUserInfo);
  return (
    <OnboardingContentWrapper>
      <div className="flex flex-col p-4 w-full mx-auto">
        <div className="flex flex-col flex-grow items-center justify-center p-4">
          <p className="text-xl text-center">
            Nice job, <span className="font-semibold">{user.firstName}!</span>
            <h1 className="font-bold text-3xl mt-2">
              You've completed your company setup
            </h1>
          </p>
          <p className="p-10 text-lg">
            Company:{" "}
            <span className="font-semibold">
              {company.name} is now added to your Beyond account
            </span>
          </p>
        </div>

        <div className="flex justify-center my-6 ">
          <button
            className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
            // onClick={() => dispatch(clearCredentials())}
            onClick={() => navigate("/")}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default CompanyOnboardCompleted;
