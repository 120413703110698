import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OnboardingContentWrapper from './components/OnboardingContentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { verifyAccount } from '../../redux/features/user.slice';

const ConfirmUserEmail = () => {
  const [queryParameters] = useSearchParams();
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const email = queryParameters.get('email');
  const code = queryParameters.get('code');
  const hasCorrectParams = !!code && !!email;

  useEffect(() => {
    if (hasCorrectParams && userInfo?.isVerified !== true) {
      dispatch(verifyAccount({ email, code }));
    }
    if (userInfo?.isVerified === true) {
      navigate('/onboarding/company');
    }
  }, [hasCorrectParams, userInfo, userToken]);

  return (
    <OnboardingContentWrapper>
      {!hasCorrectParams && (
        <div className="flex-1 p-10 ">
          <div className="flex-1 text-3xl font-bold text-left">
            Check Your Email
          </div>
          <p className="my-5 text-lg font-semibold">
            Almost there! We sent you an email to confirm.{' '}
          </p>
          <p className="my-5 text-lg font-semibold">
            Click the link in your inbox to verify your email. <br />
            One that's done, we can get you all set up in the platform.{' '}
          </p>
        </div>
      )}
      {/* <div className="flex-1 p-1 flex flex-row ">Verify You Email</div> */}
    </OnboardingContentWrapper>
  );
};

export default ConfirmUserEmail;
