import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { useSearchParams } from "react-router-dom";
import PaymentResponse from "./PaymentResponse";
import Invoice from "./Invoice";

const Payment = ({ _clientSecret, _paymentType, _invoiceId }) => {
  const [searchParams] = useSearchParams();

  const clientSecret =
    _clientSecret ||
    searchParams.get("client_secret") ||
    searchParams.get("payment_intent_client_secret");
  const paymentType = _paymentType || searchParams.get("type");
  const isCheckout = window.location.href.toString().includes("checkout");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

  const options = {
    clientSecret: clientSecret,
    loader: "always",
    appearance: {
      theme: "stripe",
      variables: {
        // colorPrimary: "#0570de",
        colorBackground: "#f7f7f7",
        // colorText: "#30313d",
        // colorDanger: "#df1b41",
        // fontFamily: "Ideal Sans, system-ui, sans-serif",
        // spacingUnit: "2px",
        // borderRadius: "4px",

        // fontFamily: ' "Gill Sans", sans-serif',
        // fontLineHeight: "1.5",
        // borderRadius: "10px",
        // colorBackground: "#F6F8FA",
        // colorPrimaryText: "#262626",
      },
    },
  };
  return (
    <>
      {/* <div className="registration-form md:w-3/4 bg-white md:m-20 py-16 px-16 shadow-md rounded-xl"> */}
      {isCheckout && <Invoice />}

      <div className="">
        {clientSecret && isCheckout ? (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm paymentType={paymentType} />
          </Elements>
        ) : (
          <Elements options={options} stripe={stripePromise}>
            <PaymentResponse />
          </Elements>
        )}
      </div>
    </>
  );
};

export default Payment;
