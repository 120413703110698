import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

function QuickBookConnectedComponent(qbUserInfo) {
  const { userInfo, userToken, company } = useSelector((state) => state.auth);

  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [qbAuthCodeError, setQbAuthCodeError] = useState(null);
  const [qbCompanyInfo, setQbCompanyInfo] = useState();

  const getCompanyInfoHandler = async () => {
    try {
      setIsLoading(true);
      const companyInfo = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/qb/company/${company._id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userToken}`,
          },

        }
      ).then((res) => res.json());
      setIsLoading(false);
      if (companyInfo.success) {
        setQbCompanyInfo(companyInfo.data);
      } else {
        setQbAuthCodeError(companyInfo.error);
        console.log({ qbAuthCodeError });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex justify-between mt-2 px-2 py-2 rounded-md border border-borderColor">
      <div>
        {location.state && (
          <div className="">
            <h3 className="">QB User Information:</h3>
            <pre>{JSON.stringify(location.state, null, 2)}</pre>
          </div>
        )}
        <button
          className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md"
          onClick={getCompanyInfoHandler}
        >
          Retrieve Company Information
        </button>
        <div>
          {isLoading ? (
            <div>loading</div>
          ) : (
            <div>
              <h3 className="">QB Company Information:</h3>
              <pre>{JSON.stringify(qbCompanyInfo, null, 2)}</pre>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default QuickBookConnectedComponent;
