import axios from 'axios';

/*
  this instance used to handle one API calls to initiate session
  in the future will check how to consolidate all instances into one if possible
*/
export const APIClientNoEnc = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  validateStatus: false, //to show the server response even on errors, otherwise will have to wrap each call with try catch and return response.data
});
