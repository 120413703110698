import Button from '@mui/material/Button';
import React from 'react';

const CustomButton = ({ children, variant, ...rest }) => {
  let buttonStyles = {};

  switch (variant) {
    case 'text':
      buttonStyles = {
        color: '#7849FF',
      };
      break;
    case 'outlined':
      buttonStyles = {
        borderColor: '#7849FF',
        color: '#7849FF',
      };
      break;
    case 'contained':
      buttonStyles = {
        borderRadius: '5px',
        backgroundColor: '#7849FF',
        color: 'white',
      };
      break;
    default:
      break;
  }

  return (
    <Button
      variant={variant}
      className='font-circular'
      sx={{
        fontSize: { xs: '14px', xl: '20px' },
        fontWeight: 450,
        height: '30px',
        textTransform: 'none',
        ...buttonStyles,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
