import React, { useState } from 'react';
import Header from './Header';
import Navbar from './Navbar';
import './Layout.css';
import { Icon } from '@iconify/react';
import newLogo from '../images/new_logo.svg';

import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
  const location = useLocation();
  const isHomePage =
    location.pathname === '/' || location.pathname === '/dashboard';
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <main className='flex flex-wrap relative h-full'>
        <aside className={`menu-sidebar ${toggle ? 'collapsed' : ''}`}>
          <div className='logo hidden md:block text-center'>
            <img className='h-7 mx-auto mt-7' src={newLogo} alt='Logo' />
          </div>
          <Navbar />
          <div className='menu-toggle' onClick={() => setToggle(!toggle)}>
            <Icon
              icon='material-symbols:arrow-forward-ios-rounded'
              color='white'
            />
          </div>
        </aside>

        <div className='flex-1'>
          <Header className='sticky top-0'></Header>
          <section className={`section-body ${isHomePage ? 'bg-primary' : ''}`}>
            {isHomePage}
            {children}
          </section>
        </div>
      </main>
    </>
  );
};

export default Layout;
