import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  selectCompany,
  selectToken,
  selectUserInfo,
} from "../../redux/features/auth.slice";
import Layout from "../../commonComponents/Layout";
import { toast } from "react-toastify";

const XeroConnectionCallback = () => {
  const navigate = useNavigate();
  const company = useSelector(selectCompany);
  const userToken = useSelector(selectToken);
  const [isLoading, setIsLoading] = useState(false);
  const [xeroConnectionCodeError, setXeroConnectionCodeError] = useState(null);
  const [xeroConnectedAccount, setXeroConnectedAccount] = useState(null);
  const [isCodeUsed, setIsCodeUsed] = useState(false);
  const [searchParams] = useSearchParams();

  const handleXeroConnectionCode = async () => {
    try {
      setIsLoading(true);
      setIsCodeUsed(true);
      setXeroConnectionCodeError(null);
      const xeroError = searchParams.get("error");
      if (xeroError) {
        setXeroConnectionCodeError(xeroError);
        setIsLoading(false);
        return;
      }

      const xeroCode = searchParams.get("code");
      const data = { companyId: company._id, xeroCode };
      const xeroConnection = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/xero/connections/`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json());
      if (xeroConnection.success === true) {
        setXeroConnectedAccount(xeroConnection.data?.xeroConnection);
        // toast.success("Xero account connected successfully");
      } else {
        setXeroConnectionCodeError(xeroConnection.error);
        // toast.error("Xero account connection failed, please try again later");
      }
      navigate("/accounting");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isCodeUsed) {
      handleXeroConnectionCode();
    }
  }, []);

  return (
    <Layout>
      <div className="box-wrapper flex justify-between align-middle">
        <div className="title box">
          <h3 className="text-5xl">Connect to Xero</h3>
        </div>
      </div>

      <pre>{JSON.stringify(xeroConnectionCodeError)}</pre>
      <div className=" rounded-lg shadow-lg p-6 mt-6">
        {isLoading ? (
          <p>Loading.........</p>
        ) : (
          <>
            {/* {xeroConnectionCodeError ? (
              <h3>Xero connection error: </h3>
            ) : (
              <div className="flex flex-col">
                <div>connected</div>
                <div className="">
                  <pre>{JSON.stringify(xeroConnectedAccount, null, 2)}</pre>
                </div>
              </div>
            )}
            <div className="flex justify-end">
              <button
                className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md"
                onClick={() => navigate("/accounting/")}
              >
                Continue
              </button>
            </div> */}
          </>
        )}
      </div>
    </Layout>
  );
};

export default XeroConnectionCallback;
