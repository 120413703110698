import React from "react";
import OnboardingContentWrapper from "./components/OnboardingContentWrapper";
import { Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCompany, selectUserInfo } from "../../redux/features/auth.slice";
import { verifyAccount } from "../../redux/features/user.slice";

const EmployeeInvitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector(selectUserInfo);
  const company = useSelector(selectCompany);
  const email = searchParams.get("email");
  const code = searchParams.get("code");
  const letsStartHandler = () => {
    dispatch(verifyAccount({ email, code }));
    navigate("/onboarding/employee");
  };

  const companyName = company?.name || searchParams.get("company");
  const userName = user?.firstName || searchParams.get("user");
  return (
    <OnboardingContentWrapper>
      <article className="flex flex-col justify-center align-middle items-center p-16 w-full min-h-[400px] gap-6">
        <div className="text-2xl">
          You`ve been invited to join{" "}
          {companyName ? (
            <>
              <span className="font-bold">{companyName}</span> on{" "}
            </>
          ) : (
            ""
          )}
          Hire Beyond
        </div>
        <div className=" text-left w-[70%]">
          <h3 className="text-lg font-semibold w-[100%]">
            Hi <span className="font-bold">{userName}</span>,
            <p>Let's set up your account so you can get paid. We'll need:</p>
          </h3>
          <ol type="1" className="list-decimal w-[50%] ml-6">
            <li>
              <p className="font-semibold mt-2">Your personal information</p>
              <span>(home address, SSN)</span>
            </li>
            <li>
              <p className="font-semibold mt-2">Tax withholding information</p>
              <span>(we'll help you figure this one out)</span>
            </li>
            <li>
              <p className="font-semibold mt-2">Your bank information</p>
              <span>(account and routing numbers)</span>
            </li>
          </ol>
        </div>
        <button className="button_primary mt-6" onClick={letsStartHandler}>
          Let`s get started!
        </button>
      </article>
    </OnboardingContentWrapper>
  );
};

export default EmployeeInvitation;
