import React, { useEffect } from 'react';
import Layout from '../../commonComponents/Layout';
import { Drawer, LinearProgress } from '@mui/material';
import AddNewTeamMemberPanel from './AddNewTeamMemberPanel';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCompanyEmployees,
  openAddEmployeeDrawer,
  selectAddEmployeeDrawerOpen,
  selectCompanyEmployees,
  selectCompanyEmployeesIsLoading,
} from '../../redux/features/companyEmployees.slice';
import moment from 'moment';
import {
  fetchCompanyDepartments,
  fetchCompanyJobTitles,
} from '../../redux/features/companyData.slice';

const TeamMembersPage = () => {
  const dispatch = useDispatch();
  const employees = useSelector(selectCompanyEmployees);
  const isLoading = useSelector(selectCompanyEmployeesIsLoading);
  const openDrawer = useSelector(selectAddEmployeeDrawerOpen);

  const handleAddNewTemMember = () => {
    // setOpenDrawer(true);
    dispatch(openAddEmployeeDrawer());
  };

  useEffect(() => {
    dispatch(fetchCompanyDepartments());
    dispatch(fetchCompanyJobTitles());
    dispatch(fetchCompanyEmployees());
  }, [dispatch]);

  return (
    <Layout>
      <div className='box-wrapper flex justify-between align-middle'>
        <div className='title box'>
          <h3 className='text-5xl'>Team Members</h3>{' '}
        </div>
        <div className='button-box'>
          <button
            onClick={handleAddNewTemMember}
            className='text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md'
          >
            Add New Team Member
          </button>
        </div>
      </div>

      <div className=' border border-borderColor rounded-md mt-5 overflow-hidden'>
        <div className='flex justify-evenly text-left font-bold bg-gray-200  p-4 items-center shadow-sm '>
          <div className='sm:basis-4/12 flex-1'>Employee</div>
          <div className='sm:flex hidden basis-3/12'>Phone</div>
          <div className='sm:flex hidden basis-3/12'>Access</div>
          <div className='sm:basis-2/12'>Added</div>
        </div>
        {isLoading ? (
          <LinearProgress />
        ) : employees.length === 0 ? (
          <div className='flex col-span-4 text-center text-xl font-semibold'>
            No Employees Found, Please add your first employee
          </div>
        ) : (
          employees.map((employee) => (
            <div
              key={employee._id}
              className='flex text-left justify-evenly p-2  items-center border-b border-borderColor bg-white'
            >
              <div className='flex-col sm:basis-4/12 flex-1'>
                <p className='font-bold'>
                  {employee?.firstName + ' ' + employee?.lastName}
                </p>
                <span className='text-sm font-normal'>{employee?.email}</span>
              </div>
              <div className='sm:flex flex-col hidden basis-3/12 text-ellipsis overflow-hidden'>
                <p className='text-sm font-normal'>{employee?.phone}</p>
              </div>
              <div className='sm:flex flex-col hidden basis-3/12 text-ellipsis overflow-hidden'>
                {employee?.type}
                <span className='text-sm font-normal'>
                  {/* {employee?.job?.manager?.email} */}
                </span>
              </div>
              <div className='sm:flex hidden basis-2/12'>
                {moment(employee.createdAt).format('MM/DD/YYYY')}
              </div>
            </div>
          ))
        )}
      </div>
      <Drawer anchor='right' open={openDrawer} className='min-w-[50%]'>
        <AddNewTeamMemberPanel />
      </Drawer>
    </Layout>
  );
};

export default TeamMembersPage;
