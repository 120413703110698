import { styled } from "@mui/material/styles";
import MuiSelect from "@mui/material/Select";

/*
<div
  tabindex="0"
  role="button"
  aria-expanded="true"
  aria-haspopup="listbox"
  aria-labelledby="mui-component-select-payingWhom"
  id="mui-component-select-payingWhom"
  class="MuiSelect-select MuiSelect-outlined MuiSelect-multiple MuiInputBase-input MuiOutlinedInput-input css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"
>
  My Self, Employees
</div>;
<input aria-invalid="false" name="payingWhom" aria-hidden="true" tabindex="-1" class="MuiSelect-nativeInput css-yf8vq0-MuiSelect-nativeInput" value="myself,employees"></input>
<fieldset aria-hidden="true" class="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline"><legend class="css-14lo706"><span>Who are you planning to pay?</span></legend></fieldset>

<li class="MuiButtonBase-root MuiMenuItem-root MuiMenuItem-gutters Mui-selected MuiMenuItem-root MuiMenuItem-gutters Mui-selected css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root" tabindex="0" role="option" aria-selected="true" data-value="employees"><span class="MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium Mui-checked MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root"><input class="PrivateSwitchBase-input css-1m9pwf3" type="checkbox" data-indeterminate="false" checked=""><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxIcon"><path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"></path></svg><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></span><div class="MuiListItemText-root css-tlelie-MuiListItemText-root"><span class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary css-10hburv-MuiTypography-root">Employees</span></div><span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></li>

<div tabindex="0" role="button" aria-expanded="false" aria-haspopup="listbox" aria-labelledby="mui-component-select-payingWhom" 
id="mui-component-select-payingWhom" 
class="MuiSelect-select MuiSelect-outlined MuiSelect-multiple MuiInputBase-input MuiOutlinedInput-input css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input"><span class="notranslate">​</span></div>
*/
const StyledSelect = styled(MuiSelect)(({ theme }) => ({
  // "label + &": {
  //   marginTop: theme.spacing(3),
  //   color: "red",
  // },

  "&.MuiSelect-multiple": {
    overflow: "hidden",
    // borderRadius: 0,
    // backgroundColor: "#781515",
    fontSize: "12px",
    fontWeight: "600",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      borderRadius: 6,
      borderColor: "#003848",
      backgroundColor: "#f7f7f7",
    },
  },
  "& .MuiSelect-icon": {
    // color: "#003848",
  },
  "&:hover": {
    borderColor: "#0598cd",
    backgroundColor: "#f7f7f7",
    "&.Mui-disabled": {
      backgroundColor: "inherit",
    },
  },
  "&.Mui-disabled": {
    backgroundColor: "inherit",
  },
  // "& .MuiOutlinedInput-input": {
  //   fontSize: "12px",
  //   fontWeight: "600",
  //   // color: "#00ddaa",
  // },
  // "& .MuiOutlinedInput-input": {
  "& .MuiInputBase-input": {
    overflow: "hidden",
    // borderRadius: 6,
    backgroundColor: "#f7f7f7",
    fontSize: "12px",
    fontWeight: "600",
    // border: "1px solid",
    // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "& fieldset": {
      // borderColor: "#003848",
    },
    "&:hover fieldset": {
      borderColor: "#00AEEF",
    },
    "&.Mui-focused fieldset": {
      // backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#003848",
    },
    "& .Mui-error": {
      color: "red",
    },
    "&:hover": {
      // borderColor: "#00AEEF",
    },
    "&.Mui-focused": {
      // backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      // borderColor: theme.palette.primary.main,
    },
  },
}));

export default StyledSelect;
