import React, { useState, useEffect } from "react";
import { loadConnect } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useSelector } from "react-redux";
import {
  selectCompany,
  selectToken,
  selectUserInfo,
} from "../../redux/features/auth.slice";

const StripeConnection = ({ StripeConnectionStatus }) => {
  const user = useSelector(selectUserInfo);
  const userToken = useSelector(selectToken);
  const company = useSelector(selectCompany);
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [stripeConnectAccountURL, setStripeConnectAccountURL] = useState();
  const [stripeConnectErrors, setStripeConnectErrors] = useState();
  const [stripeConnectInstance, setStripeConnectInstance] = useState(null);

  useEffect(() => {
    let stripeConnect;
    (async () => {
      try {
        stripeConnect = await loadConnect();
      } catch (error) {
        return;
      }
      const clientSecret = await fetchAccountSecret();
      if (clientSecret) {
        const instance = stripeConnect.initialize({
          publishableKey:
            "pk_test_51NRcO3DFcDcM3nA1XPrdBQfQR8GdH3EP2z4wrpLM6gPPS84taRNliCpJ9W0HRt0de04L84S8VmD3ZBpjmnKcXq800027D2pdkD",
          clientSecret,
          appearance: {
            colorPrimary: "#625afa",
          },
          uiConfig: {
            overlay: "dialog",
          },
          refreshClientSecret: async () => {
            return await fetchAccountSecret();
          },
        });
        setStripeConnectInstance(instance);
      }
    })();
  }, []);

  const stripeConnectAccountHandler = async () => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/stripe/account/authorize`;
      const stripeConnectAccountURL = await fetch(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          companyId: company._id,
        }),
      }).then((res) => res.json());
      console.info(stripeConnectAccountURL);
      if (stripeConnectAccountURL.success) {
        setStripeConnectAccountURL(stripeConnectAccountURL.data.url.url);
        setIsRedirecting(true);
        window.open(stripeConnectAccountURL.data.url.url, "_self");
      } else {
        setStripeConnectErrors(stripeConnectAccountURL.error);
      }
    } catch (error) {
      setStripeConnectErrors(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };

  const fetchAccountSecret = async () => {
    let cs;
    let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/stripe/stripe_account_session`;
    const stripeConnectAccountURL = await fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        companyId: company._id,
      }),
    }).then(async (response) => {
      if (!response.ok) {
        // Handle errors on the client side here
        const { error } = await response.json();
        // setHasError(true);
        return undefined;
      } else {
        const { client_secret: clientSecret } = await response.json();
        // setHasError(false);
        cs = clientSecret;
        return clientSecret;
      }
    });
    return cs;
  };

  const stripeConnectAccountRevokeHandler = async () => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/stripe/account/authorize`;
      const stripeConnectAccountRevoke = await fetch(url, {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({
          companyId: company._id,
        }),
      }).then((res) => res.json());
      console.info(stripeConnectAccountRevoke);
      if (stripeConnectAccountRevoke.success) {
        // setStripeConnectAccountURL(stripeConnectAccountURL.data.url.url);
      } else {
        setStripeConnectErrors(stripeConnectAccountRevoke.error);
      }
    } catch (error) {
      setStripeConnectErrors(error.message);
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  };
  return (
    <div className=" bg-white md:m-5 py-10 px-10 shadow-md rounded-xl">
      <div>StripeConnection: {JSON.stringify(StripeConnectionStatus)}</div>{" "}
      <div className="pt-5 flex flex-row-reverse">
        <div>{isRedirecting}</div>
        {isLoading ? (
          <p>
            {isRedirecting
              ? `Redirecting to Stripe ......`
              : `Retrieving Redirect URL ....`}
          </p>
        ) : StripeConnectionStatus === "CONNECTED" ? (
          <>
            <div className="container">
              <a
                className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md h-[60px]"
                href="https://dashboard.stripe.com"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                Go to Stripe{" "}
              </a>
              {stripeConnectInstance && (
                <ConnectComponentsProvider
                  connectInstance={stripeConnectInstance}
                >
                  <ConnectPayments />
                </ConnectComponentsProvider>
              )}
            </div>
            {
              //   <button
              //   onClick={stripeConnectAccountHandler}
              //   className="text-xl bg-red hover:bg-secondary text-white py-2 px-8 rounded-md h-[60px]"
              // >
              //   Revoke Access
              // </button>
            }
          </>
        ) : (
          <button
            onClick={stripeConnectAccountHandler}
            className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md"
            disabled={isLoading || isRedirecting}
          >
            Connect Stripe
          </button>
        )}
      </div>
    </div>
  );
};

export default StripeConnection;
