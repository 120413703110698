import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginForm from './components/LoginForm';
import { useSelector } from 'react-redux';
import { selectToken } from '../../redux/features/auth.slice';
import { SessionAPI } from '../../services/session.api';
import LoginPageLayout from './components/LoginPageLayout';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userToken = useSelector(selectToken);

  useEffect(() => {
    if (userToken) {
      if (location?.state?.from) {
        navigate(location.state.from);
      } else {
        navigate('/');
      }
    }
  }, [navigate, userToken]);

  useEffect(() => {
    // if (!securePublicKey && !userToken) {
    //   dispatch(initSession());
    // }
    const startSession = async () => {
      await SessionAPI.initiateSession();
    };
    startSession();
  });

  return (
    <LoginPageLayout
      title='Login to your account'
      formComponent={<LoginForm />}
    />
  );
};

export default Login;
