import { useField } from "formik";
import StyledTextField from "../MuiStyled/StyledTextField";
import { Autocomplete } from "@mui/material";

const FieldAutoComplete = ({
  onFieldValueChanged,
  helperText,
  textFieldProps,
  form: { setFieldValue },
  label,
  required,
  ...props
}) => {
  const [field, meta] = useField(props.field);
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (e, newValue) => {
    const event = {
      ...e,
      target: { ...e.target, name: field.name, value: newValue },
    };
    field.onChange(event);

    setFieldValue(field.name, newValue);
    onFieldValueChanged(field.name, newValue);
  };

  return (
    <>
      <Autocomplete
        {...field}
        {...props}
        renderInput={(props) => (
          <StyledTextField
            {...props}
            {...textFieldProps}
            label={label}
            required={required}
            helperText={helperText}
            error={error}
          />
        )}
        onChange={handleChange}
        getOptionLabel={(option) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.label}
          </li>
        )}
      />
    </>
  );
};

export default FieldAutoComplete;
