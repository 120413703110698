import { FormControl, MenuItem } from "@mui/material";
import { Field } from "formik";
import React from "react";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";
import * as Yup from "yup";
import FieldSelect from "../../../commonComponents/FormikMuiFields/FieldSelect";
import {
  requiredPositiveInteger,
  requiredPositiveOrZeroNumber,
  requiredString,
} from "../../../helpers/yupValidationRoles";
import { useDispatch } from "react-redux";
import { setOnboardUserFormDataField } from "../../../redux/features/onboardEmployee.slice";

export const validationSchema = Yup.object({
  FederalTax: Yup.object({
    filingStatus: requiredString,
    multipleJobs: requiredString,
    dependents: requiredPositiveInteger,
    otherIncome: requiredPositiveOrZeroNumber,
    deductions: requiredPositiveOrZeroNumber,
    extraWithholdings: requiredPositiveOrZeroNumber,
  }),
});
const EmployeeFederalTaxForm = () => {
  const dispatch = useDispatch();
  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(
      setOnboardUserFormDataField({
        parent: fieldName.split(".")[0],
        fieldName: fieldName.split(".")[1],
        fieldValue,
      })
    );
  };
  return (
    <>
      <h1 className="text-xl">Federal Tax Information</h1>
      <p className="font-semibold">
        A portion of each paycheck is withheld to pay employee income taxes. If
        you're unsure what to enter for the fields below, refer to Form W4 to
        calculate the value or use the IRS calculator to calculate the value.
      </p>
      <ol className="list-decimal my-3 px-4">
        What to have ready: <li>Total household income </li>
        <li>Most recent pay stub (if any)</li>
        <li> Most recent tax return (if any)</li>
      </ol>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          fullWidth
          required
          name="FederalTax.filingStatus"
          label="What is your Federal Filing Status (1C)"
          component={FieldSelect}
          onFieldValueChanged={handleFieldValueChange}
        >
          <MenuItem value={"Single or married filing separately"}>
            Single or married filing separately
          </MenuItem>
          <MenuItem value={"Married filing jointly"}>
            Married filing jointly
          </MenuItem>
          <MenuItem value={"Head of household"}>Head of household</MenuItem>
          <MenuItem value={"Exempt from withholding"}>
            Exempt from withholding
          </MenuItem>
        </Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="FederalTax.multipleJobs"
          label="Multiple Jobs"
          helperText="Includes spouse (if applicable). Answering 2c results in higher withholding, but to preserve privacy, this can be left unchecked. To learn more, read the IRS's instructions."
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="FederalTax.dependents"
          label="How many dependents do you have?"
          helperText="Enter the results for line 3 on form form W-4. If unsure, use the IRS calculator to calculate the value and follow the steps under “How to Adjust Your Withholding”."
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="FederalTax.otherIncome"
          label="Do you have other income? (4A)"
          helperText="Includes spouse (if applicable). Answering 2c results in higher withholding, but to preserve privacy, this can be left unchecked. To learn more, read the IRS's instructions."
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="FederalTax.deductions"
          label="Any Deductions? (4b)"
          helperText="Enter the results for line 4b on form form W-4. If unsure, use the IRS calculator to calculate the value and follow the steps under “How to Adjust Your Withholding”."
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="FederalTax.extraWithholdings"
          label="Any extra withholdings?"
          helperText="Enter the results for line 4c on form form W-4. If unsure, use the IRS calculator to calculate the value and follow the steps under “How to Adjust Your Withholding”."
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default EmployeeFederalTaxForm;
