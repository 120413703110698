import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setError, setMessage } from './message.slice';
import { CompanyAPI } from '../../services/company.api';
import moment from 'moment';

export const fetchCompanyEmployees = createAsyncThunk(
  'companyEmployees/fetchAll',
  async (_, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;

      const result = await CompanyAPI.getCompanyEmployees(activeCompany.id);

      if (result?.ok === true) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCompanyEmployee = createAsyncThunk(
  'companyEmployees/AddEmployee',
  async (_, thunkAPI) => {
    try {
      const employee = thunkAPI.getState().companyEmployees.addEmployeeForm;
      const { department, manager, jobTitle, ...restOfJob } = employee.job;
      const newEmployee = {
        ...employee,
        birthdate: moment(employee.birthdate).toISOString(),
        job: {
          ...restOfJob,
          managerId: manager?.value,
          departmentId: department?.value,
          jobTitleId: jobTitle?.value,
          startDate: moment(restOfJob.startDate).toISOString(),
          wage: Number(restOfJob.wage),
        },
      };
      const activeCompany = thunkAPI.getState().auth.company;
      const result = await CompanyAPI.createCompanyEmployee(
        activeCompany.id,
        newEmployee
      );
      if (result?.ok === true) {
        thunkAPI.dispatch(setMessage({ message: result.message }));

        return result;
      } else {
        thunkAPI.dispatch(setError({ message: result.message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);
const initialValues = {
  addEmployeeDrawerOpen: false,
  employees: [],
  managers: [],
  addEmployeeForm: {
    firstName: '',
    middleInitial: '',
    lastName: '',
    email: '',
    phone: '',
    birthdate: '',
    workAddress: {
      street1: '',
      street2: '',
      state: '',
      zipCode: '',
      city: '',
      phone: '',
    },
    inviteEmployee: false,
    job: {
      startDate: '',
      departmentId: '',
      managerId: null,
      jobTitleId: '',
      jobType: '',
      wage: 0.0,
      wageDuration: '',
      receivesCommission: false,
    },
    isTwoPercentShareHolder: false,
    specialTaxExemptions: '',
    companyWelcomeMessage: '',
  },
  error: null,
  isLoading: false,
};

const companyEmployeesSlice = createSlice({
  name: 'companyEmployees',
  initialState: initialValues,
  reducers: {
    clearCompanyEmployees: (state) => {
      state = initialValues;
    },
    openAddEmployeeDrawer: (state) => {
      state.addEmployeeDrawerOpen = true;
    },
    closeAddEmployeeDrawer: (state) => {
      state.addEmployeeDrawerOpen = false;
    },
    resetAddEmployeeForm: (state) => {
      //TODO: IMPORTANT: uncomment this
      // state.addEmployeeForm = initialValues.addEmployeeForm;
    },
    setAddEmployeeFormField: (state, action) => {
      // console.log(
      //   `📥 setAddEmployeeFormField: ${action.payload?.parent} . ${action.payload?.fieldName} : ${action.payload?.fieldValue}`
      // );
      const parts = action.payload.fieldName.split('.');
      if (parts.length > 1) {
        state.addEmployeeForm[parts[0]][parts[1]] = action.payload.fieldValue;
      } else {
        state.addEmployeeForm[action.payload.fieldName] =
          action.payload.fieldValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyEmployees.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyEmployees.fulfilled, (state, action) => {
        state.error = null;
        state.employees = action.payload.employees;
        // state.departments = [
        //   ...new Set(
        //     state.employees.map((employee) => employee?.job?.department)
        //   ),
        // ];
        // state.jobTitles = [
        //   ...new Set(
        //     state.employees.map((employee) => employee?.job?.jobTitle ?? null)
        //   ),
        // ];
        state.managers = [
          ...new Set(
            state.employees.map((employee) => {
              return {
                label: employee?.firstName + ' ' + employee?.lastName,
                value: employee.id,
              };
            })
          ),
        ];
        state.isLoading = false;
      })
      .addCase(fetchCompanyEmployees.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.employees = [];
      })
      .addCase(addCompanyEmployee.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCompanyEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addCompanyEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

//selectors
export const selectAddEmployeeDrawerOpen = (state) =>
  state.companyEmployees.addEmployeeDrawerOpen;

export const selectCompanyEmployeesIsLoading = (state) =>
  state.companyEmployees.isLoading;
export const selectCompanyEmployees = (state) =>
  state.companyEmployees.employees;
export const selectAddEmployeeForm = (state) =>
  state.companyEmployees.addEmployeeForm;
// export const selectCompanyDepartments = (state) =>
//   state.companyEmployees.departments;
// export const selectCompanyJobTitles = (state) =>
//   state.companyEmployees.jobTitles;
export const selectCompanyManagers = (state) => state.companyEmployees.managers;
//actions
export const {
  openAddEmployeeDrawer,
  closeAddEmployeeDrawer,
  setAddEmployeeFormField,
  resetAddEmployeeForm,
  clearCompanyEmployees,
} = companyEmployeesSlice.actions;
export default companyEmployeesSlice.reducer;
