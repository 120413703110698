import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utility/formatter";
import { subscribe } from "../../redux/features/onboardCompany.slice";

const ProductCard = ({ product, isYearly }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.onboardCompany.isLoading);

  return (
    <div className="flex flex-col min-w-[200px] mx-4">
      <div className="bg-[#f7f7f7] shadow-md rounded-xl px-2 py-2">
        <h2 className="text-xl py-8 px-4 text-center text-primary w-[100%]">
          {product.name}
        </h2>
        {/* <div className="flex-col justify-center items-center align-middle"> */}
        <div className="flex justify-center items-center text-primary font-bold mt-5 mb-8 text-center text-xl">
          <div className="text-3xl">
            {`${formatPrice((isYearly ? product.prices[1]?.amount : product.prices[0]?.amount))} `}
          </div>
          <p className="whitespace-normal  text-base font-light break-words text-left mx-2">
            per <br /> {isYearly ? "Year" : "Month"}
          </p>
        </div>
        <div className="flex my-4 justify-center align-middle">
          <button
            className="rounded-lg bg-primary text-white hover:bg-primary py-4 px-16 font-bold"
            disabled={isLoading}
            onClick={() =>
              navigate(
                '/'
              )
              }
          >
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
