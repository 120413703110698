import React from "react";
import { Appointment } from "./Calendar";
import { Icon } from "@iconify/react";
import ComponentContainer from "../../commonComponents/layout/ComponentContainer";
export const ListItem = ({ title, description, iconName }) => {
  return (
    <div className='flex items-center'>
      <div className='flex justify-center items-center p-3  mr-3 bg-primary bg-opacity-10 rounded-full'>
        <Icon icon={iconName} className='text-primary text-2xl' />
      </div>
      <div className='flex flex-col text-xl font-semibold'>
        {title}
        <span className='text-base font-normal'>{description}</span>
      </div>
    </div>
  );
};

export const Report = ({ reportName, category }) => {
  return (
    <div className='flex items-center'>
      <div className='flex justify-center items-center p-3  mr-3 '>
        <Icon icon='carbon:star' className='text-black text-3xl font-normal' />
      </div>
      <div className='flex flex-1 items-center justify-between text-lg font-semibold '>
        <h2 className='flex-1 text-primary'>{reportName}</h2>
        {category && (
          <span className='mx-3 px-5 py-1 text-xs text-primary bg-primary bg-opacity-40 rounded-lg'>
            {category}
          </span>
        )}
      </div>
    </div>
  );
};
const Reports = () => {
  return (
    <ComponentContainer title={"Reports"}>
      <div className='flex flex-col gap-10'>
        <ListItem
          title='Year-end reports are here!'
          description='Year-end reports and tax forms in one place'
          iconName='lucide:newspaper'
        />
        <ListItem
          title='Tax forms (940, 941, W3)'
          description='Federal and state tax forms by quarter'
          iconName='lucide:newspaper'
        />
        <ListItem
          title={`Workers' comp audit reports`}
          description='Audit reports for your insurance carrier'
          iconName='icon-park-solid:protection'
        />
      </div>
      <div className='mt-4'>
        <h2 className='text-xl'>Top Reports</h2>
        <div>
          <Report reportName={"Payroll Summary"} category={"Fiscal"} />
          <Report reportName={"Payroll Details"} category={"Fiscal"} />
          <Report reportName={"W-2 and 1099"} />
          <Report reportName={"Earnings Records"} category={"Fiscal"} />
        </div>
      </div>
    </ComponentContainer>
  );
};

export default Reports;
