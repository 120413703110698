import React, { useState } from "react";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers";
import { useField, useFormikContext } from "formik";
import StyledTextField from "../MuiStyled/StyledTextField";
import moment from "moment";

const FieldDatePicker = ({
  textField: { helperText, onBlur, ...textField } = {},
  children,
  onFieldValueChanged,
  ...props
}) => {
  const [field, meta] = useField(props.field);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [open, setOpen] = useState(false);
  const { label, required, id } = props;
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (value) => {
    setFieldTouched(field.name, value, false);
    setFieldValue(field.name, value, true);
    onFieldValueChanged(field.name, Date.parse(value));
  };

  return (
    <MuiDatePicker
      {...field}
      {...props}
      open={open}
      error={hasError}
      formError={error}
      value={moment(field.value)}
      slots={{ textField: StyledTextField }}
      slotProps={{
        textField: {
          ...props,
          name: field.name,
          helperText: hasError ? error : helperText,
          error: hasError,
          variant: "outlined",
          label: label,
          required: required,
          readOnly: true,
          onBlur: function (e) {
            // console.log("...........................", e.target);
            field.onBlur(e);
          },
          onClick: function (e) {
            setOpen(true);
          },
          // onChange: function (e) {
          //   console.log("...........................", e.target);
          // },
        },
      }}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onChange={handleChange}
    />
  );
};

export default FieldDatePicker;
