import { Form, Formik } from "formik";
import { Children, isValidElement, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FormikStatus from "../FormikMuiFields/FormikStatus.Debug";
import { toast } from "react-toastify";

const extractWizardChildrenProps = (children) => {
  return Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      const { label, description, path, element } = child.props;
      return {
        index: index,
        label: label,
        path: path,
        element,
        description: description,
      };
    }
    return null;
  }).filter(Boolean);
};

const Wizard = ({
  wizardRoutePath,
  initialValues,
  validationSchema,
  onSubmit,
  children,
  onFormStepsChange,
  onActiveStepChange,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const wizardPath = wizardRoutePath.replace(/\/+$/, "");
  const [activeStep, setActiveStep] = useState(0);
  const [wizardSteps, setWizardSteps] = useState(
    extractWizardChildrenProps(children)
  );
  const [wizardStepsPaths, setWizardStepsPaths] = useState(
    wizardSteps.map((childObj) => childObj.path)
  );

  useEffect(() => {
    if (onFormStepsChange)
      onFormStepsChange(wizardSteps.map(({ element, ...rest }) => rest));
  }, [wizardSteps]);

  useEffect(() => {
    const lastSegment = location.pathname.split("/").pop();
    const currentStep = wizardStepsPaths.indexOf(lastSegment);
    if (currentStep === -1) {
      navigate(`${wizardPath}/${wizardStepsPaths[0]}`);
    } else {
      setActiveStep((prev) => {
        onActiveStepChange(currentStep);
        return currentStep;
      });
    }
  }, []);

  const next = () => {
    setActiveStep(activeStep + 1);
    onActiveStepChange(activeStep + 1);

    navigate(`${wizardPath}/${wizardStepsPaths[activeStep + 1]}`);
  };

  const previous = () => {
    setActiveStep(activeStep - 1);
    onActiveStepChange(activeStep - 1);
    navigate(`${wizardPath}/${wizardStepsPaths[activeStep - 1]}`);
  };

  const handleSubmit = async (values, actions) => {
    if (activeStep + 1 === wizardStepsPaths.length) {
      onSubmit(values);
    } else {
      actions.setTouched({});
      next();
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Form noValidate>
          <div className="bg-white rounded-md  block w-[50vw] min-w-fit p-6 ">
            <FormikStatus />
            <div className="flex flex-col align-middle min-h-[10vh] ">
              {children}
            </div>
          </div>
          <div className="mt-1 flex justify-end align-end  px-6">
            {activeStep > 0 && (
              <div className=" m-3">
                <button
                  className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase"
                  type="button"
                  onClick={() => previous()}
                >
                  ≪ previous
                </button>
              </div>
            )}
            <div className=" my-3 ml-3">
              <button
                className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
                type="submit"
                disabled={formik.isSubmitting}
              >
                {activeStep + 1 === wizardStepsPaths.length
                  ? "next ≫" //'submit
                  : "next ≫"}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Wizard;
