import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const fetchProducts = createAsyncThunk(
  'products/fetchAll',
  async (thunkApi) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/products`,
        {
          headers: {
            'content-type': 'application/json',
          },
        }
      );
      const result = await response.json();
      console.log(result.data);
      return result.data;
    } catch (error) {
      console.error(error);
      return error.message;
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    isLoading: false,
    status: 'idle',
    error: null,
    entities: [],
  },

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.isLoading = true;
        state.status = 'loading';
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = 'succeeded';
        state.entities = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default productsSlice.reducer;
