export const PaymentResults = {
  PENDING: "pending",
  PROCESSING: "processing",
  SUCCESS: "succeeded",
  FAILED: "failed",
  ERROR: "unknown_error",
};

export const PaymentTypes = {
  SUBSCRIPTION: "subscription",
};
