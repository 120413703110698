import { APIEnigma } from '../utility/APIEnigmaClient';

const createCompany = async (company) => {
  const response = await APIEnigma.post('/company', company);
  return response.data;
};

const getCompanyEmployees = async (companyId) => {
  const response = await APIEnigma.get(`/company/${companyId}/employees`);
  return response.data;
};

const getCompanyDepartments = async (companyId) => {
  const response = await APIEnigma.get(`/company/${companyId}/departments`);
  return response.data;
};

const getCompanyJobTitles = async (companyId) => {
  const response = await APIEnigma.get(`/company/${companyId}/jobs`);
  return response.data;
};

const createCompanyEmployee = async (companyId, employee) => {
  const response = await APIEnigma.post(
    `/company/${companyId}/employees`,
    employee
  );
  return response.data;
};

const addCompanyDepartment = async (companyId, departmentName) => {
  const response = await APIEnigma.post(`/company/${companyId}/departments`, {
    departmentName,
  });
  return response.data;
};

const addCompanyJobTitle = async (companyId, jobTitle) => {
  const response = await APIEnigma.post(`/company/${companyId}/jobs`, {
    jobTitle,
  });
  return response.data;
};
const CompanyAPI = {
  createCompany,
  getCompanyEmployees,
  getCompanyDepartments,
  addCompanyDepartment,
  getCompanyJobTitles,
  addCompanyJobTitle,
  createCompanyEmployee,
};

export { CompanyAPI };
