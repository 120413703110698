import { Icon } from '@iconify/react';
import { DateCalendar } from '@mui/x-date-pickers';
import moment from 'moment';
import React from 'react';

export const Appointment = ({
  date,
  description,
  iconName = 'raphael:dollar',
}) => {
  return (
    <div className='flex items-center'>
      <div className='mr-3'>
        <Icon icon={iconName} className='text-primary text-5xl' />
      </div>
      <div className='flex flex-col text-2xl font-semibold'>
        {date}
        <span className='text-base font-normal'>{description}</span>
      </div>
    </div>
  );
};

const Calendar = () => {
  return (
    <div className='component_primary h-full max-h-'>
      <div className='component_header'>
        <h2 className='px-5'>Calendar</h2>
        <hr className='h-[2px] mt-4 bg-zinc-300 border-0'></hr>
      </div>
      <div className='text-3xl font-bold'>
        <DateCalendar views={['day']} />
      </div>
      <hr className='h-[2px] mt-4 bg-zinc-300 border-0'></hr>

      <div className='px-10 my-5 flex flex-col gap-4 max-h-40 overflow-y-auto'>
        <Appointment
          date={moment().format('MMM DD')}
          description='Last day of pay period'
        />
        <Appointment
          date={moment().format('MMM DD')}
          description='Last day of pay period'
        />{' '}
        <Appointment
          date={moment().format('MMM DD')}
          description='Last day of pay period'
        />{' '}
      </div>
    </div>
  );
};

// <DateCalendar
//   loading={true}
//   renderLoading={() => (
//     <DayCalendarSkeleton showDaysOutsideCurrentMonth fixedWeekNumber={6} />
//   )}
//   sx={{
//     "& .MuiBadge-badge": {
//       // Adjustment for recordMade badge
//       fontSize: "0.7em",
//       paddingTop: "4px",
//     },
//     // '& .MuiPickersBasePicker-pickerView': {
//     //     maxHeight: '800px',
//     //   },

//     "& .MuiDayCalendar-header": {
//       // Needed for weekday (ie S M T W T F S )adjustments (and padding if wanted)
//       // Adjusts spacing between
//       justifyContent: "center",
//       width: "100%",
//       overflow: "hidden",
//       margin: "2px, 0",
//       // paddingTop: '1em',
//       // paddingBottom: "1em",
//     },
//     "& .MuiDayCalendar-weekContainer": {
//       // Adjusts spacing between days (ie 1, 2, 3.. 27, 28)
//       justifyContent: "center",
//       overflow: "hidden",
//       width: "100%",
//       margin: 0,
//     },
//     "& .MuiPickersDay-dayWithMargin": {
//       // Grows width/height of day buttons
//       width: "calc(100% - 4px)",
//       height: "calc(100% - 4px)",
//       aspectRatio: "1",
//       // height: 'auto',

//       fontSize: "1.0em",
//     },
//     "& .MuiBadge-root": {
//       // Parent of button management
//       aspectRatio: 1,
//       width: "10%",
//       display: "flex",
//       alignContent: "center",
//       justifyContent: "center",
//     },
//     "& .MuiDayCalendar-weekDayLabel": {
//       // Manages size of weekday labels
//       aspectRatio: 1,
//       width: "calc(10% - 4px)", // deals with margin
//       fontSize: "1.0em",
//     },
//     "& .MuiPickersCalendarHeader-label": {
//       // Manages month/year size
//       fontSize: "1.3em",
//     },
//     "& .MuiDayCalendar-monthContainer": {
//       // Not sure if needed, currently works tho
//       width: "100%",
//     },
//     "& .MuiPickersFadeTransitionGroup-root-MuiDateCalendar-viewTransitionContainer":
//       {
//         // Handles size of week row parent, 1.6 aspect is good for now
//         aspectRatio: "1.6",
//         overflow: "hidden",
//       },
//     "& .MuiDayCalendar-slideTransition": {
//       // Handles size of week row parent, 1.6 aspect is good for now
//       aspectRatio: 1.6,
//       width: "100%",
//       overflow: "hidden",
//     },
//     "& .MuiDayCalendar-loadingContainer": {
//       width: "100%",
//       aspectRatio: 1.6,
//     },
//     "& .MuiDayCalendarSkeleton-root": {
//       width: "100%",
//     },
//     "& .MuiDayCalendarSkeleton-week": {
//       width: "100%",
//     },
//     "& .MuiDayCalendarSkeleton-daySkeleton": {
//       width: "calc(10% - 4px) !important", // Deals with the margin calcs
//       aspectRatio: "1 !important",
//       height: "auto !important",
//     },

//     width: "100%",
//     maxHeight: "100%",
//   }}
//   showDaysOutsideCurrentMonth
//   fixedWeekNumber={6}
//   views={["day"]}
//   onChange={(e) => {
//     console.log(monthRecords);
//     setRecordDate(e);
//     setRecordOpen(true);
//     // handleOpenEditForm(e);
//   }}
//   onMonthChange={(e) => {
//     handleNewCalendarDates(e);
//   }}
//   slots={{ day: RecordedDays }}
//   slotProps={{
//     day: {
//       highlightedDays,
//     },
//   }}
// />;
export default Calendar;
