import React from 'react';
import ComponentContainer from '../../commonComponents/layout/ComponentContainer';
import moment from 'moment';

const NotificationItem = ({ category, date, note }) => {
  return (
    <div>
      <span>{category}</span>
      {moment(date).format('MM/DD/yyyy ')} {note}
    </div>
  );
};
const Notifications = () => {
  return (
    <ComponentContainer title={'Top Things To Do'}>
      <section className='flex flex-col flex-grow flex-1 h-max'>
        <NotificationItem
          category={'1 mon / Payroll'}
          date={new moment()}
          note='payroll is past due'
        />
      </section>
      <section className='flex flex-col justify-center'>
        <hr className='h-[2px] mt-4 bg-zinc-300 border-0 mb-2'></hr>
        <button className='button_link'>View all notifications</button>
      </section>
    </ComponentContainer>
  );
};

export default Notifications;
