import React, { useEffect, useState } from "react";
import OnboardingContentWrapper from "../components/OnboardingContentWrapper";
import OnboardingStepper from "../components/OnboardingStepper";
import { Avatar } from "@mui/material";
import StyledTextField from "../../../commonComponents/MuiStyled/StyledTextField";
import { useNavigate } from "react-router-dom";

const EmployeeIntroduceYourself = () => {
  const navigate = useNavigate();
  //TODO: replace useState with redux suseSelector
  const [isDocumentsSigned, setIsDocumentsSigned] = useState(false);

  useEffect(() => {
    //TODO: Set UserDocumentStep
    //dispatch(updateActiveStep(bankAccountStep));
  }, []);
  return (
    <OnboardingContentWrapper>
      <div className="flex-1 text-2xl text-center font-bold px-2 min-w-fit">
        <OnboardingStepper isUserOnboard={true} />
      </div>
      <div className="flex flex-col p-4 min-w-[80%]">
        <h1 className="text-xl">
          Last, but not least... introduce yourself to the team!
        </h1>
        <p className="font-semibold">
          Give your profile some personality by adding a photo and fun facts.
          It’s also totally optional, so if you’d prefer you can leave it
          blank..
        </p>
        <div className="flex flex-col flex-grow items-center p-10">
          <div>
            <Avatar sx={{ width: 100, height: 100 }} />
            <button className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase mt-4">
              Upload
            </button>
          </div>
        </div>
        <div className="p-10">
          <StyledTextField
            label="Introduce yourself to the team"
            fullWidth
            multiline
            rows={5}
          />
        </div>
        <div className="flex justify-end align-end mt-6 ">
          <button
            className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
            // type="submit"
            onClick={() => navigate("/onboarding/employee/completed")}
            // disabled={!isDocumentsSigned}
          >
            Complete Onboarding
          </button>
        </div>
        {/* </Form>
          )}
        </Formik> */}
      </div>
    </OnboardingContentWrapper>
  );
};

export default EmployeeIntroduceYourself;
