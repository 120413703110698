import { Icon } from '@iconify/react';
import React from 'react';

const SearchBar = () => {
  return (
    <form className='w-full'>
      <label
        htmlFor='default-search'
        className='mb-2 text-sm font-medium text-gray-900 sr-only '
      >
        Search
      </label>
      <div className='relative'>
        <div className='absolute inset-y-0 start-0 px-4 flex items-center ps-3 pointer-events-none'>
          <Icon
            icon='lets-icons:search-alt-duotone-line'
            className='text-primary text-2xl'
          />
        </div>
        <input
          type='search'
          id='default-search'
          className='block w-full p-3 px-10 text-lg text-gray-900 border border-secondary rounded-full bg-white '
          placeholder='How can we help you today?'
          required
        />
      </div>
    </form>
  );
};

export default SearchBar;
