import { FormControl } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updateLegalAddressField } from "../../../redux/features/onboardCompany.slice";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";

export const validationSchema = Yup.object({
  legalAddress: Yup.object().shape({
    street1: Yup.string().required("Required Field"),
    street2: Yup.string(),
    city: Yup.string().required("Required Field"),
    state: Yup.string().required("Required Field"),
    zipCode: Yup.string().required("Required Field"),
    phone: Yup.string()
      .matches("^\\d{8,14}$", "Invalid Phone number, must be 8 to 14 digits")
      .required("Required Field"),
  }),
});

const CompanyLegalAddressesForm = () => {
  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(
      updateLegalAddressField({
        field: fieldName.split(".")[1],
        value: fieldValue,
      })
    );
  };
  return (
    <>
      <h1 className="text-xl">Company Addresses</h1>
      <p className="font-semibold">Please enter your legal filing address:</p>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="legalAddress.street1"
          label="Street 1"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          name="legalAddress.street2"
          label="Street 2"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="legalAddress.city"
          label="City"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="legalAddress.state"
          label="State"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        ></Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="legalAddress.zipCode"
          label="Zip Code"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="legalAddress.phone"
          label="Phone Number"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default CompanyLegalAddressesForm;
