import { createSlice } from '@reduxjs/toolkit';

const storedToken = localStorage.getItem('userToken');
const storedUserInfo = localStorage.getItem('userInfo');
const storedCompany = localStorage.getItem('company');
const storedEmployeeInfo = localStorage.getItem('employeeInfo');
const initialState = {
  userInfo: storedUserInfo ? JSON.parse(storedUserInfo) : null,
  userToken: storedToken !== null ? JSON.parse(storedToken) : null,
  company: storedCompany !== null ? JSON.parse(storedCompany) : null,
  employeeInfo: storedEmployeeInfo ? JSON.parse(storedEmployeeInfo) : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      state.userInfo = action.payload?.userInfo ?? null;
      state.userToken = action.payload?.userToken ?? null;
      state.company = action.payload?.company ?? null;
      state.employeeInfo = action.payload?.employeeInfo ?? null;

      localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
      localStorage.setItem('userToken', JSON.stringify(state.userToken));
      localStorage.setItem('company', JSON.stringify(state.company));
      localStorage.setItem('employeeInfo', JSON.stringify(state.employeeInfo));
    },
    clearCredentials: (state) => {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userToken');
      localStorage.removeItem('company');
      localStorage.removeItem('employeeInfo');
      state.userInfo = null;
      state.userToken = null;
      state.company = null;
      state.employeeInfo = null;
    },
    updateUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    updateCompany: (state, action) => {
      state.company = action.payload;
    },
    updateEmployeeInfo: (state, action) => {
      state.employeeInfo = action.payload;
    },
  },
});

export const selectUserInfo = (state) => state.auth.userInfo;
export const selectToken = (state) => state.auth.userToken;
export const selectCompany = (state) => state.auth.company;
export const selectEmployeeInfo = (state) => state.auth.employeeInfo;

export const {
  setCredentials,
  clearCredentials,
  updateUserInfo,
  updateCompany,
  updateEmployeeInfo,
} = authSlice.actions;
export default authSlice.reducer;
