import { useDispatch, useSelector } from 'react-redux';
import OnboardingContentWrapper from '../components/OnboardingContentWrapper';
import OnboardingStepper from '../components/OnboardingStepper';
import { fetchProducts } from '../../../redux/features/products.slice';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import ProductCard from '../../../commonComponents/Payment/ProductCard';
import { Switch } from 'antd';
import {
  selectSubscriptionStep,
  updateActiveStep,
} from '../../../redux/features/onboardCompany.slice';

const CompanySubscriptionPlans = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.canvasProducts);
  const subscriptionStep = useSelector(selectSubscriptionStep);

  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    dispatch(updateActiveStep(subscriptionStep));
    dispatch(fetchProducts());
  }, []);

  return (
    <OnboardingContentWrapper>
      <div className="onboard_stepper_wrapper">
        <OnboardingStepper />
      </div>
      <div className="onboard_form_wrapper">
        <h1 className="text-4xl text-center">Choose a plan</h1>
        <div className="mt-10 flex justify-center">
          <Switch
            style={{
              backgroundColor: isYearly ? '#033747' : '#aaaaaa',

              fontWeight: 'bold',
            }}
            unCheckedChildren=" Monthly Subscription  "
            checkedChildren=" Yearly Subscription  "
            onChange={() => setIsYearly(!isYearly)}
          />
        </div>
        <div className="m-10 p-1 flex flex-row justify-evenly">
          {products.isLoading ? (
            <div>
              <CircularProgress />
            </div>
          ) : (
            products.entities.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                isYearly={isYearly}
              />
            ))
          )}
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default CompanySubscriptionPlans;
