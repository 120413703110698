import React from 'react';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import LoginPageLayout from './components/LoginPageLayout';

const ForgetPassword = () => {
  return (
    <LoginPageLayout
      title='Forgot Password'
      formComponent={<ForgotPasswordForm />}
    />
  );
};

export default ForgetPassword;
