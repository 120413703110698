import { APIClientNoEnc } from '../utility/APIWithCredentialsClient';

const initiateSession = async () => {
  const response = await APIClientNoEnc.get('/session');
  return response.data;
};

const SessionAPI = { initiateSession };

export { SessionAPI };
