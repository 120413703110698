export const rowsStep1 = [
  {
    id: 1,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 0,
    ot: 0,
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: '$',
    paycheckTips: '$',
    earnings: '+ADD',
    grossWages: {
      price: '--',
      lastpayprice: '--',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 2,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 25.97,
    ot: 0.91,
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: '$110',
    paycheckTips: '$75.00',
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 3,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 23.67,
    ot: 2.3,
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 4,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 19.28,
    ot: '--',
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 5,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 34.09,
    ot: '--',
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 6,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 22.46,
    ot: '--',
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 7,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 30.48,
    ot: '0.90',
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 8,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 22.19,
    ot: '--',
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
  {
    id: 9,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    wageRate: '$7.00/hr',
    regular: 34.39,
    ot: '--',
    ptoUsed: 0,
    sickLeave: 0,
    paidHoliday: 0,
    cashTips: 0,
    paycheckTips: 0,
    earnings: '+ADD',
    grossWages: {
      price: '$557.69',
      lastpayprice: '$305.13',
    },
    paymentMethod: 'Paper check',
  },
];

export const rowsStep2 = [
  {
    id: 1,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 2,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 3,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 4,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 5,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 6,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 7,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 8,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
  {
    id: 9,
    memberName: {
      name: 'Demo Account',
      account: 'Account Owner',
    },
    grossWages: '$56.00',
    employeeBenefits: '--',
    employeeTaxes: '$4.78',
    postTax: '--',
    hetPay: '$51.22',
    companyTax: '$6.58',
    companyBenefits: '--',
    tetaiCost: '$62.58',
  },
];
