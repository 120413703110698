import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setError, setMessage } from './message.slice';
import { clearCredentials, setCredentials } from './auth.slice';
import {
  resetOnboardingCompany,
  resetOnboardingCompanyActiveStep,
} from './onboardCompany.slice';
import { resetOnboardUserActiveStep } from './onboardEmployee.slice';
import { UserAPI } from '../../services/user.api';
import { AuthAPI } from '../../services/auth.api';
import { generateSessionKeys } from '../../utility/crypto.utils';
import { clearCompanyData } from './companyData.slice';
import { clearCompanyEmployees } from './companyEmployees.slice';
import { clearPayroll } from './payroll.slice';

// export const capitalRegister = createAsyncThunk(user/capitalRegister, async (user, thunkAPI) => {
//   try {
//   } catch (error) {
//     console.error(error);
//   }
// )''

export const register = createAsyncThunk(
  'user/register',
  async (user, thunkAPI) => {
    try {
      delete user.confirmPassword;
      const result = await UserAPI.register(user);
      thunkAPI.dispatch(setMessage(result?.message, result?.success));
      if (result?.ok === true) {
        const session = generateSessionKeys();
        thunkAPI.dispatch(
          setCredentials({
            userInfo: { ...result.data.userInfo, session },
            userToken: result.data?.token || null,
            company: null,
          })
        );
        thunkAPI.dispatch(resetOnboardingCompany());
      } else {
        const message =
          result?.error.message || result?.error.message.toString();
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(message);
      }
      return result.data;
    } catch (error) {
      if (error.response) {
        console.log(error?.response);
        const result = error.response?.data;
        const message = result?.error?.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    }
  }
);

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, thunkAPI) => {
    try {
      const result = await AuthAPI.login(email, password);
      if (result?.ok === true) {
        const session = generateSessionKeys();
        thunkAPI.dispatch(
          setCredentials({
            userInfo: { ...result.data.userInfo, session },
            // userInfo: result.data.userInfo,
            userToken: result.data?.token,
            company: result.data?.company,
            employeeInfo: result.data?.employee,
          })
        );
        thunkAPI.dispatch(resetOnboardingCompanyActiveStep());
        thunkAPI.dispatch(resetOnboardUserActiveStep());
        return result.data;
      } else {
        const message = result?.error.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      if (error.response) {
        console.log(error?.response);
        const result = error.response?.data;
        const message = result?.error?.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    }
  }
);

export const verifyAccount = createAsyncThunk(
  'user/verifyEmail',
  async ({ email, code }, thunkAPI) => {
    try {
      const result = await UserAPI.verifyUser(email, code);

      if (result?.ok === true) {
        thunkAPI.dispatch(setMessage({ message: result.message }));
        thunkAPI.dispatch(
          setCredentials({
            userInfo: result.data.userInfo,
          })
        );
        return result;
      } else {
        thunkAPI.dispatch(
          setError({ message: result.message || result.error })
        );
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
    }
  }
);

export const logout = createAsyncThunk('user/logout', async (_, thunkAPI) => {
  try {
    // Dispatch the `clearCredentials` action
    await thunkAPI.dispatch(clearCredentials());
    await thunkAPI.dispatch(clearUser());
    await thunkAPI.dispatch(clearPayroll());
    await thunkAPI.dispatch(clearCompanyData());
    await thunkAPI.dispatch(clearCompanyEmployees());
    return Promise.resolve('user logged out');
  } catch (error) {
    throw error; // Re-throw the error so it can be caught elsewhere if needed
  }
});
const initialState = { isLoading: false, error: null };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearUser: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(login.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(verifyAccount.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyAccount.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(verifyAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;
