import { StyledEngineProvider } from '@mui/material';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppRouter from './routes/AppRouter';
import { ToastContainer } from 'react-toastify';
import ReactGA from "react-ga4";
import 'react-toastify/dist/ReactToastify.css';
function initializeReactGA() {
  ReactGA.initialize('G-8MLBF3G3BH');
  // ReactGA.pageview(window.location.pathname + window.location.search);
}
function App() {
  initializeReactGA();

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <AppRouter />
        <ToastContainer />
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
