import React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";

const ActiveLink = ({ children, to, sub, ...props }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  let style = !match ? "" : sub ? "active-sub-link" : "active-link";
  return (
    <Link className={style} to={to} {...props}>
      {children}
    </Link>
  );
};

export default ActiveLink;
