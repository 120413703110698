import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompany, selectUserInfo } from '../redux/features/auth.slice';

import { logout } from '../redux/features/user.slice';

const UserCompaniesList = () => {
  const user = useSelector(selectUserInfo);
  //TODO: Handles switch company
  if (Array.isArray(user.companies)) {
    return (
      <>
        {user.companies.map((com) => (
          <li key={com._id}>
            <h3 className='py-3 px-5 text-xl'>{com.name}</h3>
          </li>
        ))}
      </>
    );
  }
  return <></>;
};

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const company = useSelector(selectCompany);
  return (
    <header className='header-top flex items-center justify-between  border-b-2 border-solid'>
      <div className='left-wrapper'>
        <span className='block'>Currently Viewing:</span>
        <div className='company-info'>
          <div className='dropdown'>
            <h2 className='text-3xl inline'>{company?.name} </h2>
            {
              //     <label tabIndex={2} className="">
              //       <div className="w-30 inline-block">
              //         <Icon icon="line-md:chevron-down" />
              //       </div>
              //     </label>
              //     <ul
              //       tabIndex={2}
              //       className="mt-5 shadow menu menu-compact dropdown-content bg-darkLight rounded-md w-72"
              //     >
              //       {<UserCompaniesList/>}
              //       <a
              //         className="px-8 py-5 hover:text-borderColor border-b border-t border-borderColor"
              //         href="/"
              //       >
              //         Edit Company Details
              //       </a>
              //       <Link
              //         to="/register/create"
              //         className="my-5 mx-8 py-4 px-11 bg-primary hover:bg-secondary text-white rounded-md text-center"
              //       >
              //         Add Company
              //       </Link>
              //     </ul>
            }
          </div>
        </div>
      </div>
      <div className='wright-wrapper'>
        <div className='dropdown dropdown-end'>
          <label tabIndex={0} className=''>
            <div className=' rounded'>
              <span className='bg-primary text-white py-3 px-4  rounded-md font-bold'>
                {user ? user.firstName.toUpperCase() : 'Menu'}
              </span>
            </div>
          </label>
          <ul
            tabIndex={0}
            className='mt-5 shadow menu menu-compact dropdown-content bg-darkLight rounded-md w-72'
          >
            {/* <div className="px-8 pt-5"> */}
            {/* <Link
                className="block mb-5 hover:text-borderColor"
                to={"/settings/my-profile"}
              >
                {" "}
                Login & Security{" "}
              </Link>
              <Link
                className="block mb-5 hover:text-borderColor"
                to="/company/billing"
              >
                Billing
              </Link> */}
            {
              // <Link
              //   className="block mb-5 hover:text-borderColor"
              //   to={"/resources/financial-calculators"}
              // >
              //   Tools & Resources
              // </Link>
              //   <Link
              //   className="block mb-5 hover:text-borderColor"
              //   to={"/refer-a-friend"}
              // >
              //   Refer Link Friend
              // </Link>
            }
            {/* </div> */}
            <div className='block border-t border-borderColor m-2'></div>
            <button
              className=' px-8 py-5 hover:bg-secondary hover:text-gray-300 hover:font-semibold'
              onClick={() => dispatch(logout())}
            >
              Logout
            </button>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
