import { FormControl } from "@mui/material";
import React from "react";
import { useField } from "formik";
import {
  Checkbox,
  FormHelperText,
  ListItemText,
  MenuItem,
} from "@mui/material";
import StyledSelect from "../MuiStyled/StyledSelect";
import StyledInputLabel from "../MuiStyled/StyledInputLabel";

const FieldSelectCheckers = ({
  formControl,
  inputLabel,
  formHelperText,
  onFieldValueChanged,
  ...props
}) => {
  const [field, meta] = useField(props.field);
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (event) => {
    field.onChange(event);
    onFieldValueChanged(field.name, event.target.value);
  };
  const { disabled, required, label, options } = props;
  const { children: formHelperTextChildren, ...formHelperTextProps } =
    formHelperText || {};
  const shouldDisplayFormHelperText = error || formHelperTextChildren;

  return (
    <FormControl disabled={disabled} error={hasError} {...formControl}>
      <StyledInputLabel
        error={hasError}
        {...inputLabel}
        required={required}
        sx={{
          fontWeight: 600,
          fontSize: "12px",
          padding: 0,
          borderColor: "red",
        }}
      >
        {label}
      </StyledInputLabel>
      <StyledSelect
        error={hasError}
        {...field}
        sx={{ borderRadius: "6px", lineHeight: "17.5px" }}
        {...props}
        onChange={handleChange}
      >
        {options.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            <Checkbox checked={field.value.indexOf(option?.value) > -1} />
            <ListItemText primary={option?.label} />
          </MenuItem>
        ))}
      </StyledSelect>
      {shouldDisplayFormHelperText && (
        <FormHelperText
          className={`${hasError ? "text-error" : ""}`}
          {...formHelperTextProps}
        >
          {hasError ? error : formHelperTextChildren}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FieldSelectCheckers;
