import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../commonComponents/Layout';
import './Home.css';
import Testimonials from './Testimonials';
import WelcomeSection from './WelcomeSection';
// import { useSelector } from 'react-redux';
// import { selectCompany } from '../../redux/features/auth.slice';
import UpcomingPayroll from './UpcomingPayroll';
import Notifications from './Notifications';
import TopToDo from './TopToDo';
import LastPayroll from './LastPayroll';
import Calendar from './Calendar';
import Reports from './Reports';

const HomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes('/dashboard')) {
      navigate('/funding');
    }
  }, [navigate]);

  if (!location.pathname.includes('/dashboard')) {
    return <Layout></Layout>;
  } else {
    return (
      <Layout>
        <WelcomeSection />
        <div className='grid grid-cols-5 gap-4'>
          <div className=' col-span-4'>
            <UpcomingPayroll />
          </div>
          <div className=''>
            <Notifications />
          </div>
          <div className=' col-span-2'>
            <TopToDo />
          </div>
          <div className=' col-span-2'>
            <LastPayroll />
          </div>
          <div className=''></div>
          <div className=' col-span-2'>
            <Calendar />
          </div>
          <div className=' col-span-2'>
            <Reports />
          </div>
          <div className=''></div>
        </div>
        <Testimonials />
      </Layout>
    );
  }
};

export default HomePage;
