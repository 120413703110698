import { Step, StepLabel, Stepper } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  selectOnboardUserActiveStep,
  selectOnboardUserSteps,
} from "../../../redux/features/onboardEmployee.slice";

import {
  selectOnboardingSteps,
  selectActiveStep,
} from "../../../redux/features/onboardCompany.slice";

const OnboardingStepper = ({
  steps: propSteps,
  activeStep: propActiveStep,
  isUserOnboard,
  ...props
}) => {
  const steps = useSelector(
    isUserOnboard ? selectOnboardUserSteps : selectOnboardingSteps
  );
  const activeStep = useSelector(
    isUserOnboard ? selectOnboardUserActiveStep : selectActiveStep
  );
  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((s) => (
          <Step key={s?.index}>
            <StepLabel>{s?.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

export default OnboardingStepper;
