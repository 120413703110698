import { APIClientNoEnc } from '../utility/APIWithCredentialsClient';
const getLatestPayroll = async (companyId) => {
  const response = await APIClientNoEnc.get(`/payroll/company/${companyId}`);
  return response.data;
};

const retrievePayrollData = async (payrollId) => {
  const response = await APIClientNoEnc.get(`/payroll/${payrollId}/detail`);
  return response.data;
};

const createPayroll = async (companyId, payroll) => {
  const response = await APIClientNoEnc.post('/payroll/', {
    companyId,
    payroll,
  });
  return response.data;
};

const updatePayrollDetailRecord = async (payrollDetail) => {
  const response = await APIClientNoEnc.put('/payroll/detail', {
    payrollDetail,
  });
  return response.data;
};

const PayrollAPI = {
  getLatestPayroll,
  retrievePayrollData,
  createPayroll,
  updatePayrollDetailRecord,
};

export { PayrollAPI };
