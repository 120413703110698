import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  // "& .MuiOutlinedInput-root": {},
  // "& .MuiOutlinedInput-input": {
  //   background: "#f7f7f7",
  // },
  // "& .MuiInputAdornment-positionStart": {},
  // "& .MuiInputAdornment-positionEnd": {},
  // "& .MuiInputLabel-root": {},
  // "& .MuiInputLabel-root.Mui-focused": {
  //   background: "transparent",
  // },
  // "& .MuiInputLabel-shrink": {},
  "& .MuiOutlinedInput-root": {
    overflow: "hidden",
    borderRadius: 6,
    backgroundColor: "#f7f7f7",
    fontSize: "12px",
    fontWeight: "600",
    // border: "1px solid",
    // borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "& fieldset": {
      // borderColor: "#003848",
    },
    "&:hover fieldset": {
      borderColor: "#0598cd",
    },
    "&.Mui-focused fieldset": {
      // backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#003848",
    },
    "&:hover": {
      // borderColor: "#00AEEF",
    },
    "&.Mui-focused": {
      // backgroundColor: "transparent",
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      // borderColor: theme.palette.primary.main,
    },
  },
  "& label": {
    fontSize: "12px",
    fontWeight: "600",
  },
  "& label.Mui-focused": {
    color: "#003848",
  },
  "& .MuiInput-underline:after": {
    // borderBottomColor: "green",
  },
  "& .MuiFormLabel-asterisk": {
    color: "red",
    // fontSize: "16px",
    fontWeight: "600",
  },
  // "& .MuiOutlinedInput-root": {
  //   "& fieldset": {
  //     borderColor: "red",
  //   },
  //   "&:hover fieldset": {
  //     borderColor: "yellow",
  //   },
  //   "&.Mui-focused fieldset": {
  //     borderColor: "green",
  //   },
  // },
}));

export default StyledTextField;
