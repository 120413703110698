import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectCompany } from '../../redux/features/auth.slice';
import {
  createPayroll,
  getLatestPayroll,
  selectPayroll,
} from '../../redux/features/payroll.slice';
import { Format } from '../../helpers/format.helper';
import moment from 'moment';

const UpcomingPayroll = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const payroll = useSelector(selectPayroll);
  console.log(`🚀 ~ UpcomingPayroll ~ payroll:`, payroll);
  // const company = useSelector(selectCompany);

  useEffect(() => {
    const getPayroll = () => {
      dispatch(getLatestPayroll())
        .then((value) => {
          console.log(`🚀 ~ dispatchEvent ~ value:`, value);
        })
        .catch((error) => {
          console.log(`🚀 ~ useEffect ~ error:`, error);
        });
    };
    getPayroll();
  }, [dispatch]);

  return (
    <div className='component_primary'>
      <div className='component_header'>
        <h2 className='px-5'>Upcoming Payroll</h2>
        <hr className='h-[2px] mt-4 bg-zinc-300 border-0'></hr>
      </div>
      <div className='component_body'>
        <div className='flex flex-col basis-3/5 '>
          {payroll && (
            <>
              <div className='flex'>
                <div className='text-3xl font-bold'>{payroll.period}</div>
                {/* <h1 className='text-3xl font-bold mr-3'>Biweekly</h1> */}
                <div className='ml-3 mt-4'>
                  <div className='flex items-center text-xs bg-primary bg-opacity-30 max-h-fit rounded-full px-3 max-w-fit'>
                    Due: about 1 month ago
                    <Icon icon='mdi:calendar-outline' className='ml-1' />
                  </div>
                </div>
              </div>
              <div className='flex justify-between gap-5 my-8'>
                <div className='flex flex-col flex-1 bg-zinc-300 h-24 rounded-lg p-5'>
                  Check date
                  <span className='text-xl font-bold'>
                    {Format.shortDate(payroll.checkDate)}
                  </span>
                </div>
                <div className='flex flex-col flex-1 bg-zinc-300 h-24 rounded-lg p-5'>
                  Pay Period
                  <span className='text-xl font-bold'>
                    {`${Format.shortDate(
                      payroll.payPeriodBegin
                    )} - ${Format.shortDate(payroll.payPeriodEnd)}`}
                  </span>
                </div>
              </div>
              <button
                className='button_primary h-16 text-2xl'
                onClick={() => navigate('/payroll/payroll-run')}
              >
                Run Payroll
              </button>
            </>
          )}
        </div>
        <div className='inline-block h-[200px] min-h-[1em] w-0.5 self-stretch bg-zinc-200 mx-16'></div>
        <div className='flex flex-col basis-2/5'>
          <span className='text-2xl font-semibold my-9'>Payroll Actions</span>
          <div className=''>
            <button
              type='none'
              className='button_outlined my-4 w-full text-xl'
              onClick={() => dispatch(createPayroll())}
            >
              Off-cycle payroll
            </button>
            <button type='none' className='button_outlined w-full text-xl'>
              Off-cycle payroll
            </button>
          </div>
        </div>
      </div>
      <div className='flex items-center bg-primary bg-opacity-10 text-lg p-5 m-5 rounded-xl'>
        <Icon icon='octicon:bell-16' className='text-primary mr-3' />
        <span className='text-black flex-1'>
          Get ready for Tax day on April 15
        </span>
        <button className='button_outlined'>Lets go</button>
      </div>
    </div>
  );
};

export default UpcomingPayroll;
