import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../../redux/features/user.slice';
import { Field, Form, Formik } from 'formik';
import { FormControl } from '@mui/material';
import FieldTextField from '../../../commonComponents/FormikMuiFields/FieldTextField';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Required Field'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required Field'),
});

const LoginForm = () => {
  const dispatch = useDispatch();

  const { error: authErr, isLoading: isAuthLoading } = useSelector(
    (state) => state.user
  );

  const LoginHandler = ({ email, password }) => {
    dispatch(login({ email, password }));
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        LoginHandler(values);
      }}
    >
      <Form noValidate>
        <FormControl fullWidth margin='normal' className='drop-shadow-md'>
          <Field
            component={FieldTextField}
            name='email'
            label='Email'
            placeholder='example@company.com'
            required
          />
        </FormControl>{' '}
        <FormControl fullWidth margin='normal' className='drop-shadow-md'>
          <Field
            component={FieldTextField}
            name='password'
            label='Password'
            type='password'
            required
          />
        </FormControl>
        <div className='button-wrapper flex flex-col justify-between md:flex-row mt-6'>
          {isAuthLoading ? (
            <p>Checking....</p>
          ) : (
            <button
              type='submit'
              className='button-primary login-btn py-4 bg-secondary hover:bg-primary text-white font-bold'
            >
              Login
            </button>
          )}

          <Link to='/forgot-password' className='font-bold text-secondary py-4'>
            Forgot Password
          </Link>
        </div>
      </Form>
    </Formik>
  );
};

export default LoginForm;
