import { FormControl } from "@mui/base";
import React from "react";
import { useField } from "formik";
import {
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const FieldRadioGroup = ({
  formControl,
  inputLabel,
  formHelperText,
  onFieldValueChanged,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(props.field);
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (event) => {
    field.onChange(event);
    onFieldValueChanged(field.name, event.target.value);
  };
  const { disabled, label } = props;
  const { children: formHelperTextChildren, ...formHelperTextProps } =
    formHelperText || {};
  const shouldDisplayFormHelperText = error || formHelperTextChildren;
  const radioOptions = props?.options || [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];
  return (
    <FormControl disabled={disabled} error={hasError} {...formControl}>
      <FormLabel
        className="font-semibold text-sm"
        classes={{
          "& .MuiFormControlLabel-asterisk": {
            color: "red",
          },
        }}
      >
        {label}
      </FormLabel>
      <RadioGroup {...field} {...props} onChange={handleChange}>
        {radioOptions.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            disableTypography
            sx={{
              fontWeight: 600,
              fontSize: "0.875rem",
            }}
            control={<Radio size="small" className="drop-shadow-md" />}
            label={option.label}
          />
        ))}

        {shouldDisplayFormHelperText && (
          <FormHelperText
            className={`${hasError ? "text-error" : ""}`}
            {...formHelperTextProps}
          >
            {hasError ? error : formHelperTextChildren}
          </FormHelperText>
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default FieldRadioGroup;
