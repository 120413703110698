import axios from 'axios';
import store from '../redux/store';
import {
  encryptData,
  encryptSession,
  decryptData,
  generateSessionKeys,
} from '../utility/crypto.utils';
import { SessionAPI } from '../services/session.api';
import { setSession } from '../redux/features/secure.slice';

/*
  this instance used to handle public (unprotected) API calls
  in the future will check how to consolidate all instances into one if possible
*/
export const APISecured = axios.create({
  baseURL: process.env.REACT_APP_API_ROOT,
  withCredentials: true,
  Credential: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  validateStatus: false,
});

APISecured.interceptors.request.use(
  async (config) => {
    try {
      const sessionData = await SessionAPI.initiateSession();
      const publicKey = sessionData.data.publicKey;
      const session = generateSessionKeys();
      const xEnigma = encryptSession(publicKey, session).toString('base64');
      store.dispatch(setSession(session));
      if (session && publicKey) {
        config.data = {
          data: encryptData(JSON.stringify(config.data), session),
        };
        config.headers = {
          ...config.headers,
          'X-Enigma': xEnigma,
        };
      }
    } catch (error) {
      console.error('Error in request interceptor:', error);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

APISecured.interceptors.response.use(
  async (response) => {
    const state = await store.getState();

    if (state?.secure?.session && response?.data) {
      // Decrypt response data if session exists
      response.data = JSON.parse(
        decryptData(response.data, state.secure.session)
      );
    }

    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default APISecured;
