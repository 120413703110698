import { FormControl, MenuItem } from '@mui/material';
import { Field } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateField } from '../../../redux/features/onboardCompany.slice';

import FieldSelect from '../../../commonComponents/FormikMuiFields/FieldSelect';
import FieldRadioGroup from '../../../commonComponents/FormikMuiFields/FieldRadioGroup';
import FieldTextField from '../../../commonComponents/FormikMuiFields/FieldTextField';

export const validationSchema = Yup.object({
  isRegistered: Yup.boolean(),
  EIN: Yup.string()
    .matches('^\\d{9}$', 'Invalid EIN number, must be 9 digits')
    .when('isRegistered', {
      is: true,
      then: (schema) => schema.required('Required Field'),
    }),
  businessType: Yup.string().when('isRegistered', {
    is: true,
    then: (schema) => schema.required('Required Field'),
  }),
  federalFilingForm: Yup.string().when('isRegistered', {
    is: true,
    then: (schema) => schema.required('Required Field'),
  }),
  industry: Yup.string().when('isRegistered', {
    is: true,
    then: (schema) => schema.required('Required Field'),
  }),
});

const CompanyFederalTaxForm = ({ formik }) => {
  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(updateField({ field: fieldName, value: fieldValue }));
  };
  return (
    <>
      <h1 className="text-xl">Federal Tax Setup</h1>
      <p className="font-semibold">
        We'll use this info to pay and file your company taxes.
      </p>
      <FormControl margin="normal" className="drop-shadow-md">
        <Field
          component={FieldRadioGroup}
          name="isRegistered"
          label="If you're registered, we'll collect your details so we can pay and file your company taxes. "
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="EIN"
          label="What's your Federal EIN?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Field
          fullWidth
          required
          className="drop-shadow-md"
          component={FieldSelect}
          label="What's your business entity type?"
          name="businessType"
          multiple={false}
          onFieldValueChanged={handleFieldValueChange}
        >
          <MenuItem value="sole_proprietor">Sole Proprietor</MenuItem>
          <MenuItem value="single_member_llc">Single Member LLC</MenuItem>
          <MenuItem value="partnership_or_multi-member_llc">
            Partnership or Multi Member LLC
          </MenuItem>
          <MenuItem value="s_corporation">S Corporation</MenuItem>
          <MenuItem value="c_corporation">C Corporation</MenuItem>
        </Field>
      </FormControl>
      <FormControl margin="normal" className="drop-shadow-md">
        <Field
          component={FieldRadioGroup}
          name="federalFilingForm"
          label="What federal filing form did the IRS assign to your company"
          onFieldValueChanged={handleFieldValueChange}
          options={[
            {
              label: 'Form 941 (most common)',
              value: 'form-941',
            },
            {
              label: 'Form 944',
              value: 'form-944',
            },
          ]}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="industry"
          label="What industry are you in?"
          component={FieldSelect}
          multiple={false}
          fullWidth
          onFieldValueChanged={handleFieldValueChange}
        >
          {sector_2_naics_codes.map((value) => {
            return (
              <MenuItem key={value.value} value={value.value}>
                {value.label}
              </MenuItem>
            );
          })}
        </Field>
      </FormControl>
    </>
  );
};

var sector_2_naics_codes = [
  {
    label: 'Agriculture, Forestry, Fishing, and Hunting',
    value: 'Agriculture, Forestry, Fishing, and Hunting',
  },
  {
    label: 'Mining, Quarrying, and Oil and Gas Extraction',
    value: 'Mining, Quarrying, and Oil and Gas Extraction',
  },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Construction', value: 'Construction' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Wholesale Trade', value: 'Wholesale Trade' },
  { label: 'Retail Trade', value: 'Retail Trade' },
  {
    label: 'Transportation and Warehousing',
    value: 'Transportation and Warehousing',
  },
  { label: 'Information', value: 'Information' },
  { label: 'Finance and Insurance', value: 'Finance and Insurance' },
  {
    label: 'Real Estate and Rental and Leasing',
    value: 'Real Estate and Rental and Leasing',
  },
  {
    label: 'Professional, Scientific, and Technical Services',
    value: 'Professional, Scientific, and Technical Services',
  },
  {
    label: 'Management of Companies and Enterprises',
    value: 'Management of Companies and Enterprises',
  },
  {
    label:
      'Administrative and Support and Waste Management and Remediation Services',
    value:
      'Administrative and Support and Waste Management and Remediation Services',
  },
  { label: 'Educational Services', value: 'Educational Services' },
  {
    label: 'Health Care and Social Assistance',
    value: 'Health Care and Social Assistance',
  },
  {
    label: 'Arts, Entertainment, and Recreation',
    value: 'Arts, Entertainment, and Recreation',
  },
  {
    label: 'Accommodation and Food Services',
    value: 'Accommodation and Food Services',
  },
  {
    label: 'Other Services (except Public Administration)',
    value: 'Other Services (except Public Administration)',
  },
  { label: 'Public Administration', value: 'Public Administration' },
  { label: 'Unclassified', value: 'Unclassified' },
];

export default CompanyFederalTaxForm;
