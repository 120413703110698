import { useEffect, useState } from 'react';
import './payrollRun.css';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { rowsStep1 } from './dummydata';
import {
  colStaticTwoRows,
  memberNameColumn,
} from './components/DataGridColumns';
import {
  // GridRowsProp,
  // GridRowModesModel,
  GridRowModes,
  DataGrid,
  // GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  // GridEventListener,
  // GridRowId,
  // GridRowModel,
  GridRowEditStopReasons,
  GridToolbar,
  // GridSlots,
} from '@mui/x-data-grid';
import { Icon } from '@iconify/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPayrollData,
  selectError,
  selectLoading,
  selectRowModesModel,
  retrievePayrollData,
  updatePayrollDetail,
  setRowModesModel,
  updateRowMode,
  selectPayroll,
} from '../../../redux/features/payroll.slice.js';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import { Format } from '../../../helpers/format.helper';

const editCellTextField = (params) => {
  return (
    <TextField
      value={params.value}
      className='cursor-text my-auto mx-2 '
      variant={'outlined'}
      type={params.colDef?.type || 'string'}
      id='standard-basic'
      size='small'
      InputProps={{
        className: 'txt_bold_base pr-2 py-1',
      }}
      onChange={(event) =>
        params.api.setEditCellValue({
          id: params.id,
          field: params.field,
          value: event.target.value,
        })
      }
      // fullWidth
    />
  );
};

const valueFormatter = (value) => Format.number(value);

const PayrollRunStep1 = () => {
  const dispatch = useDispatch();
  const payrollData = useSelector(selectPayrollData);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const rowModesModel = useSelector(selectRowModesModel);
  const payroll = useSelector(selectPayroll);
  const [first, setFirst] = useState(false);

  useEffect(() => {
    // Fetch payroll from API when component mounts
    if (payroll) {
      dispatch(retrievePayrollData());
    }
    setFirst(!first);
  }, [dispatch, payroll]);

  const handleEditClick = (id) => () => {
    dispatch(updateRowMode({ id, mode: GridRowModes.Edit }));
  };

  const handleSaveClick = (id) => () => {
    console.log(`🚀 ~ handleSaveClick ~ id:`, id);
    dispatch(updateRowMode({ id, mode: GridRowModes.View }));
  };

  const handleCancelClick = (id) => () => {
    dispatch(
      updateRowMode({ id, mode: GridRowModes.View, ignoreModifications: true })
    );

    const editedRow = payrollData.payrollDetail.find((row) => row.id === id);
    if (editedRow && editedRow.isNew) {
      // handle row removal if necessary
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    dispatch(updatePayrollDetail(updatedRow));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    dispatch(setRowModesModel(newRowModesModel));
  };

  const handleRowEditCommit = (updatedRow) => {
    dispatch(updatePayrollDetail(updatedRow))
      .then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          // handleSaveClick(result.payload.updatedPayrollDetail.id);
          // dispatch(retrievePayrollData('99ef27e5-e14e-4b91-81ab-efb3b066c4fc'));
          toast.success('Payroll detail updated successfully!');
        } else {
          // handleCancelClick();
          toast.error('Failed to update payroll detail. Please try again.');
        }
      })
      .catch((error) => {
        toast.error('Failed to update payroll detail. Please try again.');
      });
  };

  const handleRowEditStop = (params, event) => {
    console.log(`🚀 ~ handleRowEditStop ~ params:`, params.reason);
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const defaultColDef = {
    headerAlign: 'center',
    headerClassName: 'grid_column_header',
    sortable: false,
  };
  const columns = [
    memberNameColumn,
    {
      ...defaultColDef,
      field: 'combinedWage', // This can be any unique identifier
      headerName: 'Wage',
      sortable: false,
      width: 150,
      align: 'center',
      renderCell: (params) => {
        // Access wage and wagePeriod from the row data
        const { wage, wageDuration } = params.row;
        return `$${wage.toFixed(2)} / ${wageDuration.toLowerCase()}`;
      },
    },
    {
      ...defaultColDef,
      field: 'regularHrs',
      sortable: false,
      headerName: 'Regular (hr)',
      type: 'number',
      width: 120,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'overTimeHrs',
      headerName: 'OT (hr)',
      type: 'number',
      width: 110,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'paidTimeOffHrs',
      headerName: 'PTO Used (hr)',
      type: 'number',
      width: 130,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'sickLeavesHrs',
      headerName: 'Sick Leave (hr)',
      type: 'number',
      width: 130,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'paidHolidaysHrs',
      headerName: 'Paid Holiday (hr)',
      type: 'number',
      width: 140,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'cashTips',
      headerName: 'Cash Tips (hr)',
      type: 'number',
      width: 130,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'paycheckTips',
      headerName: 'Paycheck Tips (hr)',
      type: 'number',
      width: 160,
      flex: 0.5,
      editable: true,
      valueFormatter,
      renderEditCell: editCellTextField,
    },
    {
      ...defaultColDef,
      field: 'otherEarnings',
      headerName: 'Other Earnings',
      sortable: false,
      width: 150,
      renderCell: (params) => {
        const handleButtonClick = () => {
          // Implement logic to open the modal here
          // setOpenModal(true);
        };

        return (
          <button onClick={handleButtonClick} className='button_link'>
            + Add
          </button>
        );
      },
    },
    {
      ...defaultColDef,
      field: 'grossWages',
      headerName: (
        <div className='text-center'>
          <div className='text-lg font-semibold'>Gross Wages</div>
          <div className='text-sm font-medium'>vs. Last Pay Period</div>
        </div>
      ),
      width: 200,
      sortable: false,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const {
          wage,
          regularHrs,
          overTimeHrs,
          paidTimeOffHrs,
          sickLeavesHrs,
          paidHolidaysHrs,
          cashTips,
        } = params.row;

        const grossWages =
          wage *
          (wage +
            regularHrs +
            overTimeHrs +
            paidTimeOffHrs +
            sickLeavesHrs +
            paidHolidaysHrs +
            cashTips);

        // Render the calculated value and lastPay in the cell
        return (
          <Box className='font-circular h-full flex flex-col items-center justify-center align-middle leading-4 pr-5'>
            <Box className='text-lg font-semibold  ml-auto'>
              {Format.currency(grossWages) || (0).toFixed(2)}
            </Box>
            <Box className='text-xs font-normal ml-auto'>{'--'}</Box>
          </Box>
        );
      },
    },

    {
      ...defaultColDef,
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      minWidth: 100,

      cellClassName: 'actions',
      //  getActions: actionsButton,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={
                <Icon
                  icon='mdi:content-save'
                  className='text-xl text-primary'
                />
              }
              label='Save'
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={
                <Icon icon='mdi:cancel-box' className='text-xl text-primary' />
              }
              label='Cancel'
              className='textPrimary'
              onClick={handleCancelClick(id)}
              color='inherit'
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Icon icon='mdi:edit' className='text-xl text-primary' />}
            label='Edit'
            onClick={handleEditClick(id)}
            color='inherit'
          />,
          // <GridActionsCellItem
          //   icon={<Icon icon='mdi:delete' className='text-xl text-primary' />}
          //   label='Delete'
          //   onClick={handleDeleteClick(id)}
          // />,
        ];
      },
    },
  ];

  if (loading || !payrollData) {
    return (
      <div className='relative h-96 w-full'>
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <CircularProgress />
        </div>
      </div>
    );
  }
  return (
    <div className='relative h-[100%] w-full'>
      <DataGrid
        rows={payrollData?.payrollDetail}
        columns={columns}
        // components={{
        //   Toolbar: GridToolbar,
        // }}
        componentsProps={{
          cell: {
            className: 'font-circular ',
          },
          columnHeader: {
            className: 'font-circular text-primary bg-red !important',
          },
        }}
        editMode='row'
        loading={loading}
        autoHeight
        // onRowEditCommit={handleRowEditCommit}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={handleRowEditCommit}
        rowHeight={70}
        getRowClassName={(params) =>
          `row-${params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}`
        }
        getCellClassName={(params) => 'txt_bold_base'}
        disableSelectionOnClick={loading} // Disable selection while loading
        disableColumnFilter={loading} // Disable column filter while loading
        disableColumnMenu={loading} // Disable column menu while loading
        disableColumnSelector={loading} // Disable column selector while loading
        onProcessRowUpdateError={() => {
          handleCancelClick();
        }}
      />
    </div>
  );
};

export default PayrollRunStep1;
