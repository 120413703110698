import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = { message: "", isError: false };

const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action) => {
      // console.log("🚀 ~ file: message.slice.js:11 ~ action:", action);
      //return { message: action.payload?.message };

      toast.success(action.payload?.message);
    },
    setError: (state, action) => {
      toast.error(action.payload?.message);
    },
    clearMessage: () => {
      return { message: "" };
    },
  },
});

const { reducer, actions } = messageSlice;

export const { setMessage, setError, clearMessage } = actions;
export default reducer;
