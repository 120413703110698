import { FormControl } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  copyLegalAddressIntoMailingAddress,
  updateMailingAddressField,
} from "../../../redux/features/onboardCompany.slice";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";
import FieldCheckbox from "../../../commonComponents/FormikMuiFields/FieldCheckboxWithLabel";
import { useEffect } from "react";

export const validationSchema = Yup.object({
  mailingAddress: Yup.object().shape({
    sameAsLegalAddress: Yup.boolean(),
    street1: Yup.string().when("sameAsLegalAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    street2: Yup.string(),
    city: Yup.string().when("sameAsLegalAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    state: Yup.string().when("sameAsLegalAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    zipCode: Yup.string().when("sameAsLegalAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    phone: Yup.string()
      .matches("^\\d{8,14}$", "Invalid Phone number, must be 8 to 14 digits")
      .when("sameAsLegalAddress", {
        is: false,
        then: (schema) => schema.required("Required Field"),
      }),
  }),
});

const CompanyMailingAddressForm = () => {
  const sameAsLegalAddress = useSelector(
    (state) => state.onboardCompany.formData.mailingAddress.sameAsLegalAddress
  );

  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(
      updateMailingAddressField({
        field: fieldName.split(".")[1],
        value: fieldValue,
      })
    );
  };
  useEffect(() => {
    if (sameAsLegalAddress) {
      dispatch(copyLegalAddressIntoMailingAddress());
    }
  }, [dispatch, sameAsLegalAddress]);
  return (
    <>
      <h1 className="text-xl">Company Addresses</h1>
      <p className="font-semibold">Please enter your Mailing address:</p>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          name="mailingAddress.sameAsLegalAddress"
          label="Same as legal address?"
          component={FieldCheckbox}
          onFieldValueChanged={handleFieldValueChange}
          onClick={() => {
            if (sameAsLegalAddress) {
              dispatch(copyLegalAddressIntoMailingAddress);
            }
          }}
        >
          Same as Filing Address
        </Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          disabled={sameAsLegalAddress}
          name="mailingAddress.street1"
          label="Street 1"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsLegalAddress}
          name="mailingAddress.street2"
          label="Street 2"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsLegalAddress}
          required
          name="mailingAddress.city"
          label="City"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsLegalAddress}
          required
          name="mailingAddress.state"
          label="State"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        ></Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsLegalAddress}
          required
          name="mailingAddress.zipCode"
          label="Zip Code"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsLegalAddress}
          required
          name="mailingAddress.phone"
          label="Phone Number"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default CompanyMailingAddressForm;
