import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  // LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useSearchParams } from "react-router-dom";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import { selectCompany, selectUserInfo } from "../../redux/features/auth.slice";

//TODO:
const CheckoutForm = ({ paymentType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUserInfo);
  const company = useSelector(selectCompany);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    setIsLoading(true);
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_BASE_URL}/onboarding/company/payment?type=${paymentType}&user_id=${user._id}&company_id=${company._id}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <LinkAuthenticationElement
        id="link-authentication-element"
        onChange={(e) => setEmail(e.target.value)}
      /> */}
      <PaymentElement
        id="payment-element"
        options={paymentElementOptions}
        onReady={() => setIsLoading(false)}
        onFocus={() => setIsLoading(false)}
      />
      <div className=" my-4">
        <button
          className="w-[100%] rounded-lg bg-secondary text-white hover:bg-primary m-y 4 py-4 px-16 font-bold"
          disabled={isLoading || !stripe || !elements}
          id="submit"
        >
          <span id="button-text">{isLoading ? <Spin /> : "Pay now"}</span>
        </button>
      </div>
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="text-error font-semibold">
          {message}
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
