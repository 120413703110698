import React from "react";
import { Link } from "react-router-dom";
import Layout from "../../commonComponents/Layout";
import isolven_logo from "../../images/isolved_people_cloud.png";
import logo_hi from "../../images/logo_hi.jpg";
import QuickBooksComponent from "../Accounting/QuickBooksComponent";

const AppOffer = () => {
  return (
    <Layout>
      <div className="title box mt-5">
        <h3 className="text-5xl">Marketplace</h3>{" "}
      </div>
      <div className="box-wrapper flex flex-wrap justify-start text-center align-middle mt-11">
        <div className="offer-box flex flex-col flex-wrap">
          <QuickBooksComponent />
        </div>
        <div className="offer-box flex flex-wrap">
          <div className="offer-box flex flex-col justify-between h-96 w-80 py-11 rounded-md border border-borderColor mr-8 mb-5">
            <div className="flex-1 flex justify-center items-center">
              <div className="image-wrapper ">
                <img
                  className="w-48 mx-auto"
                  src={isolven_logo}
                  alt="isolven logo"
                />
                <h3 className="text-xl mt-8">Payroll Platform </h3>
              </div>
            </div>
            <div className="button-wrapper">
              <Link
                to="https://beyondpayroll.myisolved.com/UserLogin.aspx?ReturnUrl=%2f"
                className="block mx-5 py-3 bg-secondary hover:bg-primary rounded-md text-white"
              >
                {" "}
                Connect isolved
              </Link>
            </div>
          </div>
        </div>
        <div className="offer-box flex flex-wrap">
          <div className="offer-box flex flex-col justify-between h-96 w-80 py-11 rounded-md border border-borderColor mr-8 mb-5">
            <div className="flex-1 flex justify-center items-center">
              <div className="image-wrapper ">
                <img
                  className="w-48 mx-auto"
                  src={logo_hi}
                  alt="Human Interest"
                />
                <h3 className="text-xl mt-8">Retirement</h3>
              </div>
            </div>
            <div className="button-wrapper">
              <Link
                to="https://app.humaninterest.com/login/"
                className="block mx-5 py-3 bg-secondary hover:bg-primary rounded-md text-white"
              >
                {" "}
                Connect human interest
              </Link>
            </div>
          </div>
        </div>
        <div className="offer-box flex flex-wrap">
          <div className="offer-box flex flex-col justify-between h-96 w-80 py-11 rounded-md border border-borderColor mr-8 mb-5">
            <div className="flex-1 flex justify-center items-center">
              <div className="image-wrapper ">
                <img
                  className="w-28 mx-auto"
                  src="https://client.xendoo.com/images/xero-logo.svg"
                  alt=""
                />
                <h3 className="text-xl mt-8">Accounting Platform </h3>
              </div>
            </div>
            <div className="button-wrapper">
              <Link
                to="https://login.xero.com/identity/user/login"
                className="block mx-5 py-3 bg-secondary hover:bg-primary rounded-md text-white"
              >
                {" "}
                Connect xero
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AppOffer;
