import React from 'react';
import ResetPasswordForm from './components/ResetPasswordForm';
import LoginPageLayout from './components/LoginPageLayout';

const ResetPasswordPage = () => {
  return (
    <LoginPageLayout
      title='Reset Password'
      formComponent={<ResetPasswordForm />}
    />
  );
};

export default ResetPasswordPage;
