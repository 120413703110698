import { Link } from 'react-router-dom';
import logo from '../../../images/logo_bayond-w.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCredentials,
  selectToken,
} from '../../../redux/features/auth.slice';
import { MdLogin, MdLogout } from 'react-icons/md';
import { clearSecureSession } from '../../../redux/features/secure.slice';

const OnboardingLayout = ({ children }) => {
  const token = useSelector(selectToken);

  const dispatch = useDispatch();

  return (
    <>
      <main className='flex flex-wrap h-full'>
        <header className='sticky h-20 w-full top-0 bg-primary bg-opacity-90 z-10'>
          <div className='py-5 px-20 flex justify-between border-none items-center'>
            <div className='logo-wrapper'>
              <img className='h-10' src={logo} alt='' />
            </div>
            <div className='button-wrapper'>
              {token ? (
                <>
                  <Link
                    onClick={() => {
                      dispatch(clearCredentials());
                      dispatch(clearSecureSession());
                    }}
                    className='text-white font-bold'
                    to='/'
                  >
                    Logout{' '}
                    <MdLogout
                      className='inline-block'
                      icon='material-symbols:arrow-forward-sharp'
                    />
                  </Link>
                </>
              ) : (
                <>
                  <span className='text-white mr-3 hidden md:inline-block'>
                    Already have an account?{' '}
                  </span>
                  <Link to='/login' className='text-white font-bold'>
                    Login{' '}
                    <MdLogin
                      className='inline-block'
                      icon='material-symbols:arrow-forward-sharp'
                    />
                  </Link>
                </>
              )}
            </div>
          </div>
        </header>
        {/* <div className="flex-1 h-[calc(100vh-80px)] flex justify-center bg-darkLight"> */}
        {/* <section className="flex-1 h-[calc(100vh-5rem)] flex justify-center bg-darkLight"> */}
        <section className="flex-1 min-h-[calc(100vh-theme('spacing.20'))] flex justify-center bg-darkLight">
          {children}
        </section>
        {/* </div> */}
      </main>
    </>
  );
};

export default OnboardingLayout;
