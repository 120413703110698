import { Box, Grid, Stack, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const PayrollRunStep3 = () => {
  return (
    <>
      <Grid container sx={{ bgcolor: '#FFFFFF' }}>
        <Grid item xs={12} sx={{ bgcolor: '#FBFBFB' }}>
          <Grid
            item
            xs={9}
            sx={{
              mx: 'auto',
              mb: '50px',
              bgcolor: '#FFFFFF',
              boxShadow: 'inherit',
            }}
          >
            <Typography
              className='font-circular'
              sx={{
                textAlign: 'center',
                pt: { xs: '40px', md: '95px' },
                fontSize: '40px',
                fontWeight: '500',
                pb: '60px',
              }}
            >
              Review and Submit Payroll
            </Typography>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={5.5}
              className='font-circular'
              sx={{ mx: 'auto', pb: '18px' }}
            >
              <Box>
                <Box
                  sx={{
                    fontSize: '30px',
                    fontWeight: '700',
                  }}
                >
                  $850.09
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    fontWeight: '450',
                  }}
                >
                  Automatically Withdrawn
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    fontSize: '30px',
                    fontWeight: '700',
                  }}
                >
                  $3,600.54
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    fontWeight: '450',
                  }}
                >
                  Manual Payments Due
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    fontSize: '30px',
                    fontWeight: '700',
                  }}
                >
                  $175.00
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    fontWeight: '450',
                  }}
                >
                  Payments Already Received
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    fontSize: '30px',
                    fontWeight: '700',
                  }}
                >
                  $4,625.63
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    fontWeight: '450',
                  }}
                >
                  Total Payroll Cost
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    fontSize: '30px',
                    fontWeight: '700',
                  }}
                >
                  Tue, Nov 28
                </Box>
                <Box
                  sx={{
                    fontSize: '10px',
                    fontWeight: '450',
                  }}
                >
                  Payday
                </Box>
              </Box>
            </Stack>
            <hr />
            <Typography
              className='font-circular'
              sx={{
                mx: { xs: '30px', md: '130px' },
                fontSize: '18px',
                fontWeight: '300',
                pt: '30px',
                pb: { xs: '30px', md: '45px' },
              }}
            >
              After you submit, $850.09 will be debited from your bank on
              Wednesday, Nov 22 at 4pm (PT) Employees being paid by direct
              deposit will receive payment on Tue Nov 28.
            </Typography>
            <Box
              sx={{
                mx: { xs: '30px', md: '160px' },
                pt: '30px',
                pb: '20px',
              }}
            >
              <Typography
                className='font-circular'
                sx={{
                  fontSize: '18px',
                  fontWeight: '300',
                }}
              >
                Beyond needs additional state tax information to pay and file
                taxes on your behalf. If this information is not received by
                12/31/2023, Beyond will not be able to pay your state payroll
                taxes and you may be subject to penalties and late filing fees.
                In addition,
                <Typography
                  component='span'
                  variant='body1'
                  className='font-circular'
                  sx={{
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                >
                  Beyond will charge $200 if you provide this information after
                  the deadline and want us to re-file your tax return.
                </Typography>
                <Typography
                  className='font-circular'
                  sx={{
                    fontSize: '20px',
                    fontWeight: '600',
                  }}
                >
                  Learn More
                </Typography>
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Checkbox {...label} sx={{ color: '#141414' }} />
                <Typography
                  className='font-circular'
                  sx={{
                    fontSize: '16px',
                    fontWeight: '300',
                    color: '#141414',
                    mt: '5px',
                  }}
                >
                  I understand
                </Typography>
              </Box>
            </Box>
            <Box
              className='font-circular'
              sx={{
                bgcolor: '#EAEDFE',
                border: '1px solid #7849FF',
                borderLeft: '10px solid #7849FF ',
                py: '10px',
                px: { xs: '20px', md: '55px' },
                my: '10px',
                mx: { xs: '15px', md: '90px' },
                borderRadius: '10px',
              }}
            >
              <Typography sx={{ fontWeight: '600', fontSize: '18px' }}>
                Keep in mind that:
                <Typography
                  sx={{
                    fontWeight: '300',
                    fontSize: '18px',
                  }}
                >
                  You will still need to make payments for deductions and
                  employees being paid by check ($3,600.54). Instructions will
                  be provided for these manual payments after you submit.
                </Typography>
              </Typography>
            </Box>
            <Box
              className='font-circular'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: '35px',
              }}
            >
              <button
                style={{
                  backgroundColor: '#7849FF',
                  padding: '7px 15px',
                  borderRadius: '10px',
                  color: 'white',
                  fontSize: '21px',
                  fontWeight: '700',
                }}
              >
                Submit Payroll
              </button>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                py: '30px',
              }}
            >
              <Typography
                className='font-circular'
                sx={{
                  fontSize: '18px',
                  fontWeight: '300',
                }}
              >
                For details by employee, download the{' '}
                <Typography
                  component='span'
                  variant='body1'
                  className='font-circular'
                  sx={{
                    fontSize: '18px',
                    fontWeight: '450',
                    color: '#7849FF',
                  }}
                >
                  payroll preview report.
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PayrollRunStep3;
