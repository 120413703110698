import { CircularProgress, FormControl, MenuItem } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import FieldTextField from '../../commonComponents/FormikMuiFields/FieldTextField';
import FieldDatePicker from '../../commonComponents/FormikMuiFields/FieldDatePicker';
import FieldSelect from '../../commonComponents/FormikMuiFields/FieldSelect';
import FieldCheckbox from '../../commonComponents/FormikMuiFields/FieldCheckboxWithLabel';
import FieldFreeSoloDialog from '../../commonComponents/FormikMuiFields/FieldFreeSoloDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCompanyEmployee,
  closeAddEmployeeDrawer,
  fetchCompanyEmployees,
  resetAddEmployeeForm,
  selectAddEmployeeForm,
  selectCompanyManagers,
  setAddEmployeeFormField,
} from '../../redux/features/companyEmployees.slice';
import * as Yup from 'yup';
import {
  PhoneNumber,
  positiveNumber,
  requiredBoolean,
  requiredDropdown,
  requiredEmail,
  requiredName,
  requiredPhone,
  requiredPositiveNumber,
  requiredString,
} from '../../helpers/yupValidationRoles';
import FormikStatus from '../../commonComponents/FormikMuiFields/FormikStatus.Debug';
import FieldAutoComplete from '../../commonComponents/FormikMuiFields/FieldAutoComplete';
import {
  fetchCompanyDepartments,
  fetchCompanyJobTitles,
  selectCompanyDepartments,
  selectCompanyJobTitles,
} from '../../redux/features/companyData.slice';
import { CompanyAPI } from '../../services/company.api';
import { selectCompany } from '../../redux/features/auth.slice';

const validationSchema = Yup.object({
  firstName: requiredName,
  middleInitial: requiredString.max(4, '4 characters max'),
  lastName: requiredName,
  email: requiredEmail,
  phone: requiredPhone,
  birthdate: requiredString,

  workAddress: Yup.object({
    street1: requiredString,
    state: requiredString,
    zipCode: requiredString,
    street2: Yup.string(),
    city: requiredString,
    phone: PhoneNumber,
  }),
  inviteEmployee: Yup.boolean(),

  job: Yup.object({
    startDate: requiredString,
    department: requiredDropdown,
    // manager: requiredString,
    wage: requiredPositiveNumber,
    wageDuration: requiredString,
    jobTitle: requiredDropdown,
    jobType: requiredString,
    receivesCommission: requiredBoolean,
    hoursPerPeriod: positiveNumber,
  }),
  isTwoPercentShareHolder: requiredBoolean,
  specialTaxExemptions: requiredString,
  companyWelcomeMessage: Yup.string(),
});
const TeamMemberForm = () => {
  const initialValues = useSelector(selectAddEmployeeForm);
  const dispatch = useDispatch();

  const [departments, setDepartments] = useState(
    useSelector(selectCompanyDepartments)
  );
  const [jobTitles, setJobTitles] = useState(
    useSelector(selectCompanyJobTitles)
  );
  const company = useSelector(selectCompany);
  // const departments = useSelector(selectCompanyDepartments);
  // const jobTitles = useSelector(selectCompanyJobTitles);
  const managers = useSelector(selectCompanyManagers);

  const handleAddEmployeeSubmit = (values, formik) => {
    // alert(JSON.stringify(values, null, 2));
    dispatch(addCompanyEmployee())
      .unwrap()
      .then((result) => {
        dispatch(resetAddEmployeeForm());
        dispatch(fetchCompanyEmployees());
        dispatch(closeAddEmployeeDrawer());
      })
      .catch((err) => {})
      .finally(() => {
        formik.setSubmitting(false);
      });
  };
  const handleFieldValueChange = (fieldName, fieldValue) => {
    //Create & Dispatch UserOnboardUpdateField action

    dispatch(
      setAddEmployeeFormField({
        fieldName,
        fieldValue,
      })
    );
  };

  const handleDBAddDepartment = async (department) => {
    const newDepartment = await CompanyAPI.addCompanyDepartment(
      company.id,
      department
    );
    await dispatch(fetchCompanyDepartments());

    setDepartments((prevDepartments) => [
      ...prevDepartments,
      newDepartment.data.newDepartment,
    ]);
    return {
      ok: newDepartment.ok,
      data: newDepartment.data.newDepartment,
      message: newDepartment.message,
      error: newDepartment.error,
    };
  };

  const handleDBAddJobTitle = async (JobTitle) => {
    const newJobTitle = await CompanyAPI.addCompanyJobTitle(
      company.id,
      JobTitle
    );
    await dispatch(fetchCompanyJobTitles());

    setJobTitles((prevTitles) => [...prevTitles, newJobTitle.data.newJobTitle]);
    return {
      ok: newJobTitle.ok,
      data: newJobTitle.data.newJobTitle,
      message: newJobTitle.message,
      error: newJobTitle.error,
    };
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => {
        handleAddEmployeeSubmit(values, formik);
      }}
    >
      {(formik) => (
        <Form noValidate>
          <FormikStatus />
          {formik.isSubmitting && (
            <div className='bg-[#000000] bg-opacity-70 top-0 left-0 w-full h-[100vh]'>
              {<CircularProgress />}
            </div>
          )}
          <div className='flex gap-2'>
            <FormControl fullWidth margin='normal' className='drop-shadow-md '>
              <Field
                required
                name='firstName'
                label='First Name'
                helperText='Their Official Legal Name'
                component={FieldTextField}
                onFieldValueChanged={handleFieldValueChange}
              />
            </FormControl>
            <FormControl
              fullWidth
              margin='normal'
              className='drop-shadow-md basis-1/3'
            >
              <Field
                required
                name='middleInitial'
                label='Middle Initial'
                helperText='Initial Only'
                component={FieldTextField}
                onFieldValueChanged={handleFieldValueChange}
              />
            </FormControl>
          </div>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              required
              name='lastName'
              label='Last Name'
              helperText='Their Official Legal Name'
              component={FieldTextField}
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              required
              name='email'
              label='Personal Email'
              helperText='Please be extra careful typing the address. We use it to grant access to the employees’ sensitive information.'
              component={FieldTextField}
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              required
              name='phone'
              label='Phone Number'
              // helperText='Their Official Legal Name'
              component={FieldTextField}
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              required
              component={FieldDatePicker}
              name='birthdate'
              label='Birth Date'
              // helperText='Their Birth Date'
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>{' '}
          <FormControl margin='normal' className='drop-shadow-md' fullWidth>
            <Field
              required
              component={FieldDatePicker}
              name='job.startDate'
              label='Start Date'
              helperText='Your Employee’s first day of work at your company'
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Field
              required
              fullWidth
              name='job.department'
              label='Department'
              component={FieldFreeSoloDialog}
              helperText='Select Department or Create a new Department'
              onFieldValueChanged={handleFieldValueChange}
              handleDBAdd={handleDBAddDepartment}
              dbTitle='department'
              dbValue='id'
              options={departments.map((d) => {
                return { value: d.id, title: d.department };
              })}
            ></Field>
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <Field
              fullWidth
              name='job.manager'
              label='Manager'
              component={FieldAutoComplete}
              helperText='Managers can approve hours and time off. Based on your settings, they may also be able to access or edit information about their reports.'
              onFieldValueChanged={handleFieldValueChange}
              options={managers}
            />
          </FormControl>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              name='inviteEmployee'
              label='Invite this employee to enter their own details online. (This is optional, as owner/admin/manager can complete the onboarding process on behalf of the employee.)'
              component={FieldCheckbox}
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>
          <div className='mt-2 border border-[#c7c7c7] p-2 rounded-md'>
            <h2 className='mb-1 font-sans'>Work Address</h2>
            <p className='text-sm'>
              If your employee will work remotely, check the remote guide for
              help on which location to select and learn about other potential
              impacts on your business.
            </p>
            <div className='flex gap-2'>
              <div className='flex flex-col basis-1/2'>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    required
                    name='workAddress.street1'
                    label='Street 1'
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    required
                    name='workAddress.state'
                    label='State'
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    required
                    name='workAddress.zipCode'
                    label='Zip Code'
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
              </div>
              <div className='flex flex-col basis-1/2'>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    name='workAddress.street2'
                    label='Street 2'
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    required
                    name='workAddress.city'
                    label='City'
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
                {/* <FormControl fullWidth margin="normal" className="drop-shadow-md">
                <Field
                  required
                  name="workAddress.phone"
                  label="Phone Number"
                  component={FieldTextField}
                  onFieldValueChanged={handleFieldValueChange}
                />
              </FormControl> */}
              </div>
            </div>
          </div>
          <div className='mt-2 border border-[#c7c7c7] p-2 rounded-md'>
            <h2 className='mb-1 font-sans'>Compensation</h2>
            <FormControl fullWidth margin='normal' className='drop-shadow-md'>
              <Field
                required
                fullWidth
                name='job.jobTitle'
                label='Job Title'
                helperText='Choose from your existing set of jobs or enter a new one.'
                component={FieldFreeSoloDialog}
                onFieldValueChanged={handleFieldValueChange}
                handleDBAdd={handleDBAddJobTitle}
                dbTitle='title'
                dbValue='id'
                options={jobTitles.map((jt) => {
                  return { value: jt.id, title: jt.title };
                })}
              />
            </FormControl>
            <FormControl fullWidth margin='normal' className='drop-shadow-md'>
              <Field
                required
                fullWidth
                name='job.jobType'
                label='Employee Type'
                component={FieldSelect}
                onFieldValueChanged={handleFieldValueChange}
              >
                <MenuItem value='SALARY_NO_OVERTIME'>
                  Salary/No Overtime
                </MenuItem>
                <MenuItem value='SALARY_ELIGIBLE_FOR_OVERTIME'>
                  Salary/Eligible for overtime
                </MenuItem>
                <MenuItem value='Paid by the hour'>Paid by the hour</MenuItem>
                <MenuItem value='Owners Draw'>Owners Draw</MenuItem>
                <MenuItem value='COMMISSION_NO_OVERTIME'>
                  Commission/No overtime,
                </MenuItem>
                <MenuItem value='COMMISSION_ELIGIBLE_FOR_OVERTIME'>
                  Commission/Eligible for Overtime
                </MenuItem>
              </Field>
            </FormControl>
            <div className='flex gap-2'>
              <FormControl fullWidth margin='normal' className='drop-shadow-md'>
                <Field
                  required
                  name='job.wage'
                  label='Wage'
                  // helperText="US$"
                  //TODO: try to add $ as prefix
                  component={FieldTextField}
                  onFieldValueChanged={handleFieldValueChange}
                />
              </FormControl>
              <FormControl fullWidth margin='normal' className='drop-shadow-md'>
                <Field
                  required
                  fullWidth
                  name='job.wageDuration'
                  label='Wage Duration'
                  component={FieldSelect}
                  onFieldValueChanged={handleFieldValueChange}
                >
                  <MenuItem value='HOUR'>Hour</MenuItem>
                  <MenuItem value='WEEK'>Week</MenuItem>
                  {/* <MenuItem value='BI_WEEK'>Bi-Week</MenuItem> */}
                  <MenuItem value='MONTH'>Month</MenuItem>
                  {/* <MenuItem value='BI_MONTH'>Bi-Month</MenuItem>
                  <MenuItem value='YEAR'>Year</MenuItem> */}
                </Field>
              </FormControl>
            </div>
            <FormControl fullWidth margin='normal' className='drop-shadow-md'>
              <Field
                name='job.receivesCommission'
                label='This employee will receive commissions or other types of additional job.'
                component={FieldCheckbox}
                onFieldValueChanged={handleFieldValueChange}
              />
            </FormControl>
            <FormControl fullWidth margin='normal' className='drop-shadow-md'>
              <Field
                required
                fullWidth
                name='isTwoPercentShareHolder'
                label='2% Shareholder'
                formHelperText={{
                  children:
                    'Choose “Yes” if they owned at least 2% of the company on any day of the year.',
                }}
                component={FieldSelect}
                onFieldValueChanged={handleFieldValueChange}
              >
                <MenuItem value={true}>
                  Yes, this employee is a 2% shareholder
                </MenuItem>
                <MenuItem value={false}>
                  No, they are not a shareholder or own less than 2% of the
                  company
                </MenuItem>
              </Field>
            </FormControl>

            {/* <FormControl fullWidth margin='normal' className='drop-shadow-md'>
              <Field
                name='job.hoursPerPeriod'
                label='Default Hours per pay period (optional)'
                helperText='We’ll automatically suggest this amount when you run payroll, and you can change it as needed. If this is blank, we’ll assume this employee works 40 hours per week.'
                component={FieldTextField}
                onFieldValueChanged={handleFieldValueChange}
              />
            </FormControl> */}
            <FormControl fullWidth margin='normal' className='drop-shadow-md'>
              <Field
                required
                fullWidth
                name='specialTaxExemptions'
                label='Does this employee have a special tax exemption status? (This is not common.)'
                formHelperText={{
                  children:
                    'Certain types of employees are exempt from taxes, such as non-resident aliens (visa-holders), members of clergy or religious orders, newspaper vendors, family employees, owners and corporate officers.',
                }}
                component={FieldSelect}
                onFieldValueChanged={handleFieldValueChange}
              >
                <MenuItem value='NO_THIS_EMPLOYEE_IS_NOT_TAX_EXEMPT'>
                  No, this employee is not tax exempt
                </MenuItem>
                <MenuItem value='YES_AS_A_MEMBER_OF_THE_CLERGY_OR_A_RELIGIOUS_ORDER'>
                  Yes, as a member of the clergy or a religious order
                </MenuItem>
                <MenuItem value='YES_AS_A_NONE_RESIDENT_ALIEN'>
                  Yes, as a none-resident alien
                </MenuItem>
                <MenuItem value='YES_AS_FAMILY_EMPLOYEE'>
                  Yes, as family employee
                </MenuItem>
                <MenuItem value='YES_AS_NEWSPAPER_VENDOR'>
                  Yes, as newspaper vendor
                </MenuItem>
                <MenuItem value='YES_AS_AN_OWNER_CORPORATE_OFFICER'>
                  Yes, as an owner/corporate officer
                </MenuItem>
                <MenuItem value='YES_FOR_ANOTHER_REASON'>
                  Yes, for another reason
                </MenuItem>
              </Field>
            </FormControl>
          </div>
          <div className='mt-2 border border-[#c7c7c7] p-2 rounded-md'>
            <h2 className='mb-1 font-sans'>Required Documents</h2>
            <p className=''>
              During onboarding, Rich will sign these documents and upload any
              documents they need to share with you. We'll securely store the
              signed versions for both of you to access at any time.
            </p>
            <p className='font-semibold mt-1 ml-2'>
              Beyond automatically includes:
            </p>
            <ul className='ml-6 list-disc'>
              <li>
                Tax Withholding(Form -4)
                <ul className='list-disc list-inside'>
                  <li>Records their federal withholding allowance</li>
                </ul>
              </li>
              <li>
                Direct deposit authorization
                <ul className='list-disc list-inside'>
                  <li>Let’s you pay them via direct deposit</li>
                </ul>
              </li>
              <li>
                Employment Eligibility
                <ul className='list-disc list-inside'>
                  <li>
                    Verifies Rich’s identity and work eligibility (must be
                    completed onsite)
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <FormControl fullWidth margin='normal' className='drop-shadow-md'>
            <Field
              multiline
              rows={5}
              name='companyWelcomeMessage'
              label='Welcome Message'
              helperText='Give them a warm welcome. Make a great first impression with a personal message from you and your team.'
              component={FieldTextField}
              onFieldValueChanged={handleFieldValueChange}
            />
          </FormControl>
          <div className='flex justify-center'>
            <button className='button_primary' type='submit'>
              Complete Employee Onboarding
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TeamMemberForm;
