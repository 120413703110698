import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateCompany } from "./auth.slice";
import { setError, setMessage } from "./message.slice";
import { CompanyAPI } from "../../services/company.api";
import moment from "moment";
import { IntegrationsAPI } from "../../services/Integrations.api";

export const subscribe = createAsyncThunk(
  "onboardCompany/subscribe",
  async ({ productId, priceId }, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;
      const userToken = thunkAPI.getState().auth.userToken;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/subscription`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            companyId: activeCompany._id,
            productId,
            priceId,
          }),
        }
      );
      const result = await response.json();
      thunkAPI.dispatch(resetOnboardingCompany());
      return result.data.currentClientSecret;
    } catch (error) {
      console.error(error);
      return error.message;
    }
  }
);

export const addCompany = createAsyncThunk(
  "onboardCompany/addCompany",
  async (company, thunkAPI) => {
    try {
      const newCompany = {
        ...company,
        name: company.companyName,
        email: company.companyEmail,
        payrollDesireStartDate: moment(
          company.payrollDesireStartDate
        ).toISOString(),
        payrollPayPeriodEnd: moment(company.payrollPayPeriodEnd).toISOString(),
        isFirstPayroll: company.isFirstPayroll === "true", //? true : false,
        ownership: {
          firstName: company.ownerFirstName,
          lastName: company.ownerLastName,
          email: company.ownerEmail,
          title: company.ownerTitle,
          phone: company.ownerPhone,
        },
      };

      const result = await CompanyAPI.createCompany(newCompany);

      if (result?.ok === true) {
        //TODO: check to update retrieved users to include companies ids
        // thunkAPI.dispatch(updateUserInfo(result.data.userInfo));
        thunkAPI.dispatch(updateCompany(result.data.company));
        thunkAPI.dispatch(resetOnboardingCompanyForm());
        thunkAPI.dispatch(setMessage("Progress Saved"));
        return result;
      } else {
        const message = result?.error.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error?.response);
        const result = error.response?.data;
        const message = result?.error?.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCompanyPlaidLinkToken = createAsyncThunk(
  "onboardCompany/getCompanyPlaidLinkToken",
  async (_, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth?.company;

      const result = await IntegrationsAPI.Plaid.retrieveLinkToken(
        activeCompany.id
      );
      if (result?.ok === true) {
        // thunkAPI.dispatch(updateCompany(result.data.company));
        // thunkAPI.dispatch(setMessage({ message: result.message }));
        return result;
      } else {
        const message = result?.error.message || result?.message;
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);

      let message = "";
      if (error.data) {
        message = error.data?.message || error.data?.error;
        thunkAPI.dispatch(setError({ message }));
      }
      return thunkAPI.rejectWithValue(message || error.message);
    }
  }
);

export const exchangePlaidToken = createAsyncThunk(
  "onboardCompany/exchangePlaidToken",
  async ({ plaidPublicToken, institution, accounts }, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth?.company;
      // const userToken = thunkAPI.getState().auth?.userToken;

      const result = await IntegrationsAPI.Plaid.exchangeToken(
        plaidPublicToken,
        institution,
        accounts,
        activeCompany.id
      );
      if (result?.ok === true) {
        thunkAPI.dispatch(updateCompany(result.data.company));
        thunkAPI.dispatch(setMessage({ message: result.message }));
        return result.data;
      } else {
        thunkAPI.dispatch(setError({ message: result.message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(
        error?.response?.message || error.message
      );
    }
  }
);

const initialValues = {
  companyName: "",
  activeStep: 0,
  formStepsLabels: [],
  formSteps: [],
  isLoading: false,
  plaidLinkToken: "",
  plaidPublicToken: "",
  //#region Payroll Form Data
  formData: {
    //#region Payroll Form Data
    payrollDesireStartDate: null,
    payrollPayPeriodEnd: null,
    isFirstPayroll: false,
    exPayrollCompany: "",
    payFrequency: "",
    payingWhom: [],
    //#endregion Payroll Form Data
    //#region OwnerShip Form Data
    companyName: "",
    companyEmail: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerEmail: "",
    ownerTitle: "",
    ownerPhone: "",
    //#region OwnerShip Form Data
    //#region legalAddress Form Data
    legalAddress: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
    },
    //#endregion legalAddress Form Data
    //#region Mailing Address Form Data
    mailingAddress: {
      sameAsLegalAddress: false,
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
    },
    //#endregion Mailing Address Form Data
    //#region Federal Tax Form Data
    isRegistered: true,
    EIN: "", //EIN
    businessType: "",
    federalFilingForm: "form-941",
    industry: "",
    //#endregion Federal Tax Form Data
    //#region BankAccount Form Data
    hasBankAccount: true,
    routingNumber: "",
    accountNumber: "",
    //Handle separate form steps ..
  },

  status: "pending", // 'failed' or 'succeeded'
  bankStatus: "pending", // 'failed' or 'succeeded'
  bankAccountStep: 5,
  subscriptionStep: 6,
  error: null,
};
const onboardCompanySlice = createSlice({
  name: "onboardCompany",
  initialState: initialValues,

  reducers: {
    updateField: (state, action) => {
      state.formData[action.payload.field] = action.payload.value;
    },
    updateLegalAddressField: (state, action) => {
      state.formData.legalAddress[action.payload.field] = action.payload.value;
    },
    updateMailingAddressField: (state, action) => {
      state.formData.mailingAddress[action.payload.field] =
        action.payload.value;
    },
    updateFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload.data };
      state.activeStep = action.payload.activeStep;
    },
    resetFormData: (state, action) => {
      state.formData = [];
      state.activeStep = 0;
    },
    updateActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setFormSteps: (state, action) => {
      state.formSteps = action.payload;
      state.bankAccountStep = state.formSteps.length;
      state.subscriptionStep = state.formSteps.length + 1;
      state.formSteps.push(
        {
          index: state.formSteps.length,
          label: "Bank Account",
          path: "bankAccount",
          description: "Add your Company bank account",
        },
        {
          index: state.formSteps.length + 1,
          label: "Choose a Plan",
          path: "subscription",
          description: "Choose your plan",
        }
      );
    },
    resetOnboardingCompanyForm: (state) => {
      state.formData = initialValues.formData;
      state.status = initialValues.status;
    },

    resetOnboardingCompanyActiveStep: (state) => {
      state.activeStep = 0;
      state.status = "pending";
      state.bankStatus = "pending";
    },

    resetOnboardingCompany: (state) => {
      state = initialValues;
    },

    copyLegalAddressIntoMailingAddress: (state, action) => {
      state.isLoading = true;
      state.formData.mailingAddress = Object.assign(
        {},
        state.formData.legalAddress,
        { sameAsLegalAddress: state.formData.mailingAddress.sameAsLegalAddress }
      );
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscribe.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(subscribe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.status = "succeeded";
      })
      .addCase(subscribe.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.clientSecret = "";
      })
      .addCase(addCompany.pending, (state, action) => {
        state.isLoading = true;
        state.status = "pending";
      })
      .addCase(addCompany.fulfilled, (state, action) => {
        state.isLoading = false;

        state.status = "succeeded";
      })
      .addCase(addCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.status = "failed";
      })
      .addCase(getCompanyPlaidLinkToken.pending, (state, action) => {
        state.isLoading = true;
        state.bankStatus = "pending";
        state.plaidLinkToken = "";
        state.plaidPublicToken = "";
      })
      .addCase(getCompanyPlaidLinkToken.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.bankStatus = "succeeded";
        state.plaidLinkToken = action.payload?.data?.plaidLink?.link_token;
      })
      .addCase(getCompanyPlaidLinkToken.rejected, (state, action) => {
        state.isLoading = false;
        state.bankStatus = "failed";
      })
      .addCase(exchangePlaidToken.pending, (state, action) => {
        state.isLoading = true;
        state.bankStatus = "pending";
      })
      .addCase(exchangePlaidToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bankStatus = "succeeded";
        state.plaidLinkToken = "";
      })
      .addCase(exchangePlaidToken.rejected, (state, action) => {
        state.isLoading = false;
        state.bankStatus = "failed";
      });
  },
});

export const selectOnboardCompanyFormData = (state) =>
  state.onboardCompany.formData;

export const selectOnboardingLoading = (state) =>
  state.onboardCompany.isLoading;
export const selectOnboardingSteps = (state) => state.onboardCompany.formSteps;

export const selectActiveStep = (state) => state.onboardCompany.activeStep;
export const selectOnboardStatus = (state) => state.onboardCompany.status;
export const selectBankAccStatus = (state) => state.onboardCompany.bankStatus;
export const selectOnboardError = (state) => state.onboardCompany.error;

export const selectHasBankAccount = (state) =>
  state.onboardCompany.formData.hasBankAccount;
export const selectBankAccountStep = (state) =>
  state.onboardCompany.bankAccountStep;
export const selectSubscriptionStep = (state) =>
  state.onboardCompany.subscriptionStep;
export const selectCompanyPlaidLink = (state) =>
  state.onboardCompany.plaidLinkToken;

export const {
  updateFormData,
  updateField,
  updateMailingAddressField,
  updateLegalAddressField,
  resetFormData,
  updateActiveStep,
  setFormSteps,
  resetOnboardingCompanyForm,
  resetOnboardingCompanyActiveStep,
  resetOnboardingCompany,
  copyLegalAddressIntoMailingAddress,
} = onboardCompanySlice.actions;
export default onboardCompanySlice.reducer;
