import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateEmployeeInfo } from "./auth.slice";
import { setError } from "./message.slice";

const initialValues = {
  activeStep: 0,
  formStepsLabels: [],
  formSteps: [],
  isLoading: false,
  formData: {
    firstName: "",
    preferredFirstName: "",
    middleInitial: "",
    lastName: "",
    birthday: null,
    socialSecurityNumber: "",
    recoveryPhone: "",
    recoveryEmail: "",

    residentAddress: {
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
    },
    workAddress: {
      sameAsResidentAddress: false,
      street1: "",
      street2: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
    },

    emergencyContact: {
      contactFullName: "",
      relationship: "",
      contactPhone: "",
      contactEmail: "",
    },

    employmentEligibility: {
      citizenshipImmigrationStatus: "",
    },

    FederalTax: {
      filingStatus: "",
      multipleJobs: "",
      dependents: 0,
      otherIncome: 0,
      deductions: 0,
      extraWithholdings: 0,
    },

    // documents: [
    //   // {
    //   //   name: "",
    //   //   description: "",
    //   //   status: "",
    //   //   file: "",
    //   // },
    // ],
    selfIntroduction: "",
    photo: "",
  },
  onboardUserStatus: "pending",

  paymentForm: {
    paymentMethod: "Direct deposit",
    bankAccount: {
      country: "US",
      currency: "usd",
      routing_number: "",
      account_number: "",
      account_holder_type: "individual",
      account_type: "",
    },
  },
  paymentMethodStep: 5,
  paymentMethodStatus: "pending",
  paymentMethodError: null,
};

export const updateCompanyEmployee = createAsyncThunk(
  "onboardEmployee/AddCompanyEmployee",
  async (employee, thunkAPI) => {
    try {
      const newEmployee = {
        ...employee,
        recovery: {
          email: employee.recoveryEmail,
          phone: employee.recoveryPhone,
        },
      };
      const userToken = thunkAPI.getState().auth.userToken;
      const activeCompany = thunkAPI.getState().auth.company;
      const employeeInfo = thunkAPI.getState().auth.employeeInfo;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/company/${activeCompany._id}/employees/${employeeInfo._id}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(newEmployee),
        }
      );
      const result = await response.json();
      if (result?.success === true) {
        thunkAPI.dispatch(updateEmployeeInfo(result.data.employee));
        thunkAPI.dispatch(resetOnboardUserFormData());
        return result;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      return error.message;
    }
  }
);

export const updateEmployeePaymentMethod = createAsyncThunk(
  "onboardEmployee/updateEmployeePaymentMethod",
  async (paymentMethod, thunkAPI) => {
    try {
      const userToken = thunkAPI.getState().auth.userToken;
      const activeCompany = thunkAPI.getState().auth.company;
      const employee = thunkAPI.getState().auth.employeeInfo;

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/company/${activeCompany._id}/employees/${employee._id}/payment`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify({
            ...paymentMethod,
            bankAccount: {
              ...paymentMethod.bankAccount,
              account_holder_name: `${
                employee.firstName + " " + employee.lastName
              }`,
            },
          }),
        }
      );
      const result = await response.json();
      if (result?.success === true) {
        thunkAPI.dispatch(updateEmployeeInfo(result.data.employee));
        thunkAPI.dispatch(resetOnboardUserPaymentFormData());
        return result;
      } else {
        const message =
          result?.error.message || result?.error.message.toString();
        thunkAPI.dispatch(setError({ message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      return error.message;
    }
  }
);

const onboardEmployeeSlice = createSlice({
  name: "onboardEmployee",
  initialState: initialValues,

  reducers: {
    setOnboardUserSteps: (state, action) => {
      state.formSteps = action.payload;
    },

    updateOnboardUserActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },

    resetOnboardUserFormData: (state) => {
      state.formData = initialValues.formData;
    },
    resetOnboardUserPaymentFormData: (state) => {

      state.paymentForm = initialValues.paymentForm;
    },
    resetOnboardUserActiveStep: (state) => {
      state.activeStep = 0;
      state.onboardUserStatus = "pending";
      state.paymentMethodStatus = "pending";
    },

    resetOnboardUser: (state) => {
      state = initialValues;
    },
    setOnboardUserField: (state, action) => {
      state.formData[action.payload.fieldName] = action.payload.fieldValue;
    },
    setOnboardUserPaymentFormField: (state, action) => {
      if (action.payload.parent) {
        state.paymentForm[action.payload.parent][action.payload.fieldName] =
          action.payload.fieldValue;
      } else {
        state.paymentForm[action.payload.fieldName] = action.payload.fieldValue;
      }
    },
    setOnboardUserFormDataField: (state, action) => {
      if (action.payload.parent) {
        state.formData[action.payload.parent][action.payload.fieldName] =
          action.payload.fieldValue;
      } else {
        state.formData[action.payload.fieldName] = action.payload.fieldValue;
      }
    },

    copyResidentAddressIntoWorkAddress: (state, action) => {
      state.isLoading = true;
      state.formData.workAddress = Object.assign(
        {},
        state.formData.residentAddress,
        {
          sameAsResidentAddress:
            state.formData.workAddress.sameAsResidentAddress,
        }
      );
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCompanyEmployee.pending, (state, payload) => {
        state.isLoading = true;
        state.onboardUserStatus = "pending";
      })
      .addCase(updateCompanyEmployee.fulfilled, (state, payload) => {
        state.isLoading = false;
        state.onboardUserStatus = "succeeded";
      })
      .addCase(updateCompanyEmployee.rejected, (state, payload) => {
        state.isLoading = false;
        state.onboardUserStatus = "failed";
      })
      .addCase(updateEmployeePaymentMethod.pending, (state, payload) => {
        state.isLoading = true;
        state.paymentMethodStatus = "pending";
      })
      .addCase(updateEmployeePaymentMethod.fulfilled, (state, payload) => {
        state.isLoading = false;
        state.paymentMethodStatus = "succeeded";
      })
      .addCase(updateEmployeePaymentMethod.rejected, (state, payload) => {
        state.isLoading = false;
        state.paymentMethodStatus = "failed";
      });
  },
});

//Export selectors
export const selectOnboardUserLoading = (state) =>
  state.onboardEmployee.isLoading;
export const selectOnboardUserSteps = (state) =>
  state.onboardEmployee.formSteps;
export const selectOnboardUserActiveStep = (state) =>
  state.onboardEmployee.activeStep;
export const selectOnboardUserFormData = (state) =>
  state.onboardEmployee.formData;
export const selectSameAsResidentAddress = (state) =>
  state.onboardEmployee.formData.workAddress.sameAsResidentAddress;
export const selectOnboardEmployeeStatus = (state) =>
  state.onboardEmployee.onboardUserStatus;
export const selectPaymentFormData = (state) =>
  state.onboardEmployee.paymentForm;
export const selectPaymentMethod = (state) =>
  state.onboardEmployee.paymentForm.paymentMethod;
export const selectPaymentMethodStep = (state) =>
  state.onboardEmployee.paymentMethodStep;
export const selectPaymentMethodStatus = (state) =>
  state.onboardEmployee.paymentMethodStatus;
export const selectPaymentMethodError = (state) =>
  state.onboardEmployee.paymentMethodError;

//Export Actions
export const {
  setOnboardUserSteps,
  updateOnboardUserActiveStep,
  resetOnboardUserFormData,
  resetOnboardUserPaymentFormData,
  resetOnboardUserActiveStep,
  resetOnboardUser,
  setOnboardUserField,
  setOnboardUserFormDataField,
  copyResidentAddressIntoWorkAddress,
  setOnboardUserPaymentFormField,
} = onboardEmployeeSlice.actions;

// export reducer
export default onboardEmployeeSlice.reducer;
