import { FormControl } from "@mui/base";
import React from "react";

import StyledSelect from "../MuiStyled/StyledSelect";
import { useField } from "formik";
import { FormHelperText } from "@mui/material";
import StyledTextField from "../MuiStyled/StyledTextField";
import StyledInputLabel from "../MuiStyled/StyledInputLabel";

const FieldSelect = ({
  formControl,
  inputLabel,
  formHelperText,
  onFieldValueChanged,
  helperText,
  ...props
}) => {
  const [field, meta] = useField(props.field);
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (event) => {
    field.onChange(event);
    onFieldValueChanged(field.name, event.target.value);
  };
  const { disabled, required, label } = props;
  const { children: formHelperTextChildren, ...formHelperTextProps } =
    formHelperText || {};
  const shouldDisplayFormHelperText = error || formHelperTextChildren;

  return (
    <FormControl disabled={disabled} error={hasError} {...formControl}>
      <StyledInputLabel
        {...inputLabel}
        required={required}
        error={hasError}
        sx={{
          fontWeight: 600,
          fontSize: "12px",
          padding: 0,
          borderColor: "red",
        }}
      >
        {label}
      </StyledInputLabel>
      <StyledSelect
        slots={{ textField: StyledTextField }}
        {...field}
        sx={{ borderRadius: "6px", lineHeight: "17.5px" }}
        {...props}
        onChange={handleChange}
      />
      {shouldDisplayFormHelperText && (
        <FormHelperText
          className={`${hasError ? "text-error" : ""}`}
          {...formHelperTextProps}
        >
          {hasError ? error : formHelperTextChildren}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FieldSelect;
