// PrivateRoute.js
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import {
  clearCredentials,
  selectCompany,
  selectEmployeeInfo,
  selectToken,
  selectUserInfo,
} from '../redux/features/auth.slice';

import jwt_decode from 'jwt-decode';
import {
  initSession,
  selectSecurePublicKey,
} from '../redux/features/secure.slice';

const PrivateRoute = ({ requiredRole, children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);

  const company = useSelector(selectCompany);
  const userToken = useSelector(selectToken);
  const publicKey = useSelector(selectSecurePublicKey);
  const employee = useSelector(selectEmployeeInfo);

  useEffect(() => {
    if (userToken) {
      var decoded = jwt_decode(userToken);
      if (new Date(decoded.exp * 1000) <= Date.now()) {
        dispatch(clearCredentials());
      }
    }
  }, [dispatch, userToken]);

  if (!userToken || !user) {
    //Initiate Session for encryption
    // if (!publicKey) {
    //   dispatch(initSession());
    // }
    return <Navigate to='/login' replace state={{ from: location }} />;
  }
  if (!location.pathname.includes('/onboarding/')) {
    //TODO: [Temporarily] disable forced navigation to check for BankAccount
    // if (!company || company.plaid?.length === 0) {
    if (!company) {
      return <Navigate to='/onboarding/company' />;
    }
    //TODO: Fix forced navigation when finish invite employee part
    // if (!employee || !employee?.paymentMethod) {
    //   return <Navigate to="/onboarding/employee" />;
    // }
  }

  if (requiredRole && company?.user?.role !== requiredRole) {
    return <Navigate to='/unauthorized' />;
  }

  return <React.Fragment>{children ? children : <Outlet />}</React.Fragment>;
};

export default PrivateRoute;
