import React, { useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";

const PlaidLink = ({ linkToken, onSuccessHandler, onExitHandler }) => {
  const logEvent = (eventName, metadata, error) => {
  };
  // define onSuccess, onExit and onEvent functions as configs for Plaid Link creation
  const onSuccess = async (publicToken, metadata) => {
    // log and save metatdata
    logEvent("onSuccess", metadata);
    if (typeof onSuccessHandler === "function") {
      onSuccessHandler(metadata);
    }
  };

  const onExit = async (error, metadata) => {
    logEvent("onExit", metadata, error);
    if (typeof onExitHandler === "function") {
      onExitHandler(metadata, error);
    }
  };

  const onEvent = async (eventName, metadata) => {
    logEvent(eventName, metadata);
  };

  const config = {
    onSuccess,
    onExit,
    onEvent,
    token: linkToken,
  };
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready) open();
  }, [open, ready]);
};

export default PlaidLink;
