import React from "react";
import Layout from "../../commonComponents/Layout";

const PeoplePage = () => {
  return (
    <Layout>
      <div className="box-wrapper flex justify-between align-middle">
        <div className="title box">
          <h3 className="text-5xl">People</h3>
        </div>
      </div>
    </Layout>
  );
};

export default PeoplePage;
