import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError, setMessage } from './message.slice';
import { CompanyAPI } from '../../services/company.api';

export const fetchCompanyDepartments = createAsyncThunk(
  'companyData/fetchDepartments',
  async (_, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;

      const result = await CompanyAPI.getCompanyDepartments(activeCompany.id);

      if (result?.ok === true) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCompanyDepartment = createAsyncThunk(
  'companyData/AddDepartment',
  async (departmentName, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;

      const result = await CompanyAPI.addCompanyDepartment(
        activeCompany.id,
        departmentName
      );
      if (result?.ok === true) {
        thunkAPI.dispatch(setMessage({ message: result.message }));

        return result;
      } else {
        thunkAPI.dispatch(setError({ message: result.message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const fetchCompanyJobTitles = createAsyncThunk(
  'companyData/fetchJobTitles',
  async (_, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;

      const result = await CompanyAPI.getCompanyJobTitles(activeCompany.id);

      if (result?.ok === true) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const addCompanyJobTitle = createAsyncThunk(
  'companyData/addJobTitle',
  async (jobTitle, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;

      const result = await CompanyAPI.addCompanyJobTitle(
        activeCompany.id,
        jobTitle
      );
      if (result?.ok === true) {
        thunkAPI.dispatch(setMessage({ message: result.message }));

        return result;
      } else {
        thunkAPI.dispatch(setError({ message: result.message }));
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialValues = {
  company: null,
  departments: [],
  jobTitles: [],
  isLoading: false,
  error: false,
};

const companyDataSlice = createSlice({
  name: 'companyData',
  initialState: initialValues,
  reducers: {
    clearCompanyData: (state) => {
      state = initialValues;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchCompanyDepartments.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.departments = action.payload.departments;
        state.error = null;
      })
      .addCase(fetchCompanyDepartments.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addCompanyDepartment.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCompanyDepartment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addCompanyDepartment.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCompanyJobTitles.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanyJobTitles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobTitles = action.payload.jobTitles;
        state.error = null;
      })
      .addCase(fetchCompanyJobTitles.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addCompanyJobTitle.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addCompanyJobTitle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addCompanyJobTitle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const selectCompanyDepartments = (state) =>
  state.companyData.departments;
export const selectCompanyJobTitles = (state) => state.companyData.jobTitles;
export const { clearCompanyData } = companyDataSlice.actions;
export default companyDataSlice.reducer;
