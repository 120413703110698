import { FormControl, MenuItem } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";
import OnboardingContentWrapper from "../components/OnboardingContentWrapper";
import OnboardingStepper from "../components/OnboardingStepper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import FieldSelect from "../../../commonComponents/FormikMuiFields/FieldSelect";
import {
  selectPaymentFormData,
  selectPaymentMethod,
  selectPaymentMethodError,
  selectPaymentMethodStatus,
  selectPaymentMethodStep,
  setOnboardUserPaymentFormField,
  updateEmployeePaymentMethod,
  updateOnboardUserActiveStep,
} from "../../../redux/features/onboardEmployee.slice";
import FormikStatus from "../../../commonComponents/FormikMuiFields/FormikStatus.Debug";

export const validationSchema = Yup.object({
  paymentMethod: Yup.string().required(),
  bankAccount: Yup.object().when("paymentMethod", {
    is: "Direct deposit",
    then: (schema) =>
      schema.shape({
        account_number: Yup.string().required("Required, Field"),
        routing_number: Yup.string().required("Required, Field"),
        account_type: Yup.string().required("Required, Field"),
      }),
    otherwise: (schema) => schema.shape({}),
  }),
});

const EmployeePaymentMethodForm = ({ formik }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [hasBankAccount, setHasBankAccount] = useState(true);

  const initialValues = useSelector(selectPaymentFormData);
  const paymentMethod = useSelector(selectPaymentMethod);
  const paymentMethodStep = useSelector(selectPaymentMethodStep);
  const paymentMethodStatus = useSelector(selectPaymentMethodStatus);
  const paymentMethodError = useSelector(selectPaymentMethodError);

  const handleFieldValueChange = (fieldName, fieldValue) => {
    const fields = fieldName.split(".");
    let payload = { fieldName, fieldValue };
    if (fields.length > 1) {
      payload = { ...payload, parent: fields[0], fieldName: fields[1] };
    }
    dispatch(setOnboardUserPaymentFormField(payload));
  };
  useEffect(() => {
    dispatch(updateOnboardUserActiveStep(paymentMethodStep));
  }, []);

  useEffect(() => {
    if (paymentMethod === "Direct deposit") {
      setHasBankAccount(true);
    } else {
      setHasBankAccount(false);
    }
  }, [paymentMethod]);

  useEffect(() => {
    if (paymentMethodStatus === "succeeded") {
      navigate("/onboarding/employee/documents");
      toast.success("Payment Method Updated successfully");
    } else if (paymentMethodStatus === "failed") {
      toast.error(
        paymentMethodError ||
          "Payment Method was not updated. Please try again later. Contact admin@hirebeyond.com for additional support."
      );
    }
  }, [paymentMethodStatus, navigate, paymentMethodError]);
  return (
    <OnboardingContentWrapper>
      <div className="flex-1 text-2xl text-center font-bold px-2 min-w-fit">
        <OnboardingStepper isUserOnboard={true} />
      </div>
      <div className="flex flex-col p-4 min-w-[80%]">
        <h1 className="text-xl">Payment Method</h1>
        <p className="font-semibold">
          We recommend direct deposit - We can deposit your paycheck directly
          into your bank account.
        </p>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, formik) => {
            // if (hasBankAccount === true) {
            dispatch(updateEmployeePaymentMethod(values))
              .unwrap()
              .finally(() => {
                // formik.setSubmitting(false);
              });
            // } else {
            // navigate("/onboarding/employee/documents");
            // }
          }}
        >
          {(formik) => (
            <Form noValidate>
              {/* <FormControl margin="normal" className="drop-shadow-md">
                <Field
                  row
                  name="hasBankAccount"
                  label="Do you have a bank account?"
                  component={FieldRadioGroup}
                  onFieldValueChanged={handleFieldValueChange}
                />
              </FormControl> */}
              <FormikStatus />
              <FormControl fullWidth margin="normal" className="drop-shadow-md">
                <Field
                  fullWidth
                  required
                  // disabled={formik.isSubmitting}
                  name="paymentMethod"
                  label="How do you want to be paid?"
                  component={FieldSelect}
                  formHelperText={{ children: "" }}
                  onFieldValueChanged={handleFieldValueChange}
                >
                  <MenuItem value={"Direct deposit"}>Direct deposit</MenuItem>
                  <MenuItem value={"Check"}>Check</MenuItem>
                </Field>
              </FormControl>
              <div className={`${!hasBankAccount ? "hidden" : ""}`}>
                <FormControl
                  fullWidth
                  margin="normal"
                  className="drop-shadow-md"
                >
                  <Field
                    required={hasBankAccount}
                    // disabled={formik.isSubmitting}
                    name="bankAccount.account_number"
                    label="Account Number"
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  className="drop-shadow-md"
                >
                  <Field
                    required={hasBankAccount}
                    // disabled={formik.isSubmitting}
                    name="bankAccount.routing_number"
                    label="Routing Number"
                    component={FieldTextField}
                    onFieldValueChanged={handleFieldValueChange}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  className="drop-shadow-md"
                >
                  <Field
                    required={hasBankAccount}
                    // disabled={formik.isSubmitting}
                    fullWidth
                    name="bankAccount.account_type"
                    label="Account Type"
                    component={FieldSelect}
                    onFieldValueChanged={handleFieldValueChange}
                  >
                    <MenuItem value={"checking"}>Checking</MenuItem>
                    <MenuItem value={"savings"}>Savings</MenuItem>
                  </Field>
                </FormControl>
              </div>
              <div
                className={`${
                  hasBankAccount ? "hidden" : ""
                } p-4 mt-6 border-[#777777] border-2 rounded-md`}
              >
                {/* <h1 className="text-xl mb-2">
                  Verify your bank account instantly
                </h1> */}

                <p className=" text-xl font-semibold">
                  By selecting Check as the payment method, you will receive a
                  physical check from your employer every payday.
                </p>
              </div>
              <div className="flex justify-end align-end mt-6">
                <button
                  className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
                  type="submit"
                  // disabled={formik.isSubmitting}
                >
                  {hasBankAccount
                    ? "Verify Bank Account ≫"
                    : "Add Payment Method ≫"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </OnboardingContentWrapper>
  );
};

export default EmployeePaymentMethodForm;
