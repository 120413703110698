import React from "react";

import { useField } from "formik";
import { FormControlLabel } from "@mui/material";
import StyledCheckbox from "../MuiStyled/StyledCheckbox";

const FieldCheckbox = ({
  formControl,
  inputLabel,
  formHelperText,
  onFieldValueChanged,
  helperText,
  ...props
  // selectProps: { ...selectProps },
}) => {
  const [field, meta] = useField(props.field);
  // const hasError = meta.touched && Boolean(meta.error);
  // const error = meta.touched && meta.error;

  const handleChange = (event) => {
    field.onChange(event);

    onFieldValueChanged(field.name, event.target.checked);
  };
  const { disabled, required, label } = props;
  // const { children: formHelperTextChildren, ...formHelperTextProps } =
  //   formHelperText || {};
  // const shouldDisplayFormHelperText = error || formHelperTextChildren;

  return (
    <FormControlLabel
      label={label}
      disabled={disabled}
      required={required}
      control={
        <StyledCheckbox
          {...field}
          {...props}
          onChange={handleChange}
          checked={field.value}
          // onBlur={function (e) {
          //   field.onBlur(e ?? field.name);
          // }}
        />
      }
    />
  );
};

export default FieldCheckbox;
