import React from 'react';

const TopToDo = () => {
  return (
    <div className='component_primary h-full'>
      <div className='component_header'>
        <h2 className='px-5'>Top Things To Do</h2>
        <hr className='h-[2px] mt-4 bg-zinc-300 border-0'></hr>
      </div>
      <div className='component_body'></div>
      {/* <div className='flex items-center bg-primary bg-opacity-10 text-lg p-5 m-5 rounded-xl'>
        <Icon icon='octicon:bell-16' className='text-primary mr-3' />
        <span className='text-black flex-1'>
          Get ready for Tax day on April 15
        </span>
        <button className='button_outlined'>Lets go</button>
      </div> */}
    </div>
  );
};

export default TopToDo;
