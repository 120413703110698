import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { formatPrice } from "../../utility/formatter";

const Invoice = ({ _clientSecret }) => {
  const [searchParams] = useSearchParams();
  const [clientSecret] = useState(
    _clientSecret ||
      searchParams.get("client_secret") ||
      searchParams.get("payment_intent_client_secret")
  );
  const [invoice, setInvoice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/subscription/invoice/${clientSecret}`
    )
      .then((res) => res.json())
      .then((currentInvoice) => {
        setInvoice(currentInvoice.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, [clientSecret]);

  return (
    <>
      <h2>Order Details:</h2>
      <div className="rounded-md border-[#dedede] border  bg-[#f7f7f7] shadow-md mb-8 p-2 font-semibold">
        {isLoading ? (
          invoice && (
            <ul className="mt-1">
              {invoice.lines.data.map((line) => {
                return (
                  <li key={line.id}>
                    <div className="flex justify-between">
                      <span className="pl-4">{line.description}</span>{" "}
                      <span className="pr-4">{formatPrice(line.amount)}</span>
                    </div>
                  </li>
                );
              })}
              <li key={invoice.id}>
                <div className="font-bold flex justify-between border-t border-[#c5c5c5]  ">
                  <span className="w-[100%] pr-4 text-right">Total</span>{" "}
                  <span className="pr-4">
                    {formatPrice(invoice.amount_due)}
                  </span>
                </div>
              </li>
            </ul>
          )
        ) : (
          <div className="flex justify-center">
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};

export default Invoice;
