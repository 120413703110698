import { APIEnigma } from '../utility/APIEnigmaClient';

const Plaid = {
  retrieveLinkToken: async (entityId, entityType = 'company') => {
    const response = await APIEnigma.post('/integrations/plaid/', {
      entityId,
      entityType,
    });
    return response.data;
  },

  exchangeToken: async (
    plaidPublicToken,
    institution,
    accounts,
    entityId,
    entityType = 'company'
  ) => {
    const response = await APIEnigma.post(
      `/integrations/plaid/${entityType}/${entityId}`,
      {
        plaidPublicToken,
        institution,
        accounts,
      }
    );
    return response.data;
  },
};

const Jaris = {
  retrieveMerchant: async (entityId, entityType = 'company') => {
    const response = await APIEnigma.get(
      `/integrations/jaris/${entityType}/${entityId}`
    );
    return response.data;
  },

  createJarisMerchant: async (entityId, entityType = 'company') => {
    const response = await APIEnigma.post('/integrations/jaris/', {
      entityId,
      entityType,
    });
    return response.data;
  },
};

const IntegrationsAPI = {
  Plaid,
  Jaris,
};

export { IntegrationsAPI };
