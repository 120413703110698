import React from "react";
import { Route, Routes } from "react-router-dom";
import PeoplePage from "../pages/People/People.page";
import TeamMembersPage from "../pages/People/TeamMembers.page";

const PeopleRoutes = () => {
  return (
    <Routes>
      <Route index element={<PeoplePage />} />

      <Route path="/team-members" element={<TeamMembersPage />} />
    </Routes>
  );
};

export default PeopleRoutes;
