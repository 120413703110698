import React, { useState } from "react";
import qb_logo from "../../images/qb-logo-horizontal-preferred.svg";
import { useSelector } from "react-redux";

const QuickBooksComponent = ({ isConnected, isConnectedHandler }) => {

  const { userInfo, userToken, company } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [quickBooksConnectError, setQuickBooksConnectError] = useState();
  const [isQBConnected, setIsQBConnected] = useState(isConnected);

  const quickBooksConnectHandler = async () => {
    setIsLoading(true);
    setQuickBooksConnectError(null);
    const qbAuthUrl = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/qb/authorize`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    ).then((res) => res.json());
    setIsLoading(false);
    if (qbAuthUrl.success) {
      console.info(qbAuthUrl.data);
      window.open(qbAuthUrl.data.authUrl, "_self");
    } else {
      setQuickBooksConnectError(qbAuthUrl.error);
    }
  };

  const quickBooksRevokeAccessHandler = async () => {
    setIsLoading(true);
    setQuickBooksConnectError(null);
    const reqBody = { companyId: company._id };
    const revoked = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/qb/authorize`,
      {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(reqBody),
      }
    ).then((res) => res.json());
    setIsLoading(false);
    setIsQBConnected(false);
    isConnectedHandler(false);
    if (revoked.success) {
      console.info(revoked.data);
    } else {
      setQuickBooksConnectError(revoked.error);
    }
  };

  // useEffect(() => {
  //   if (token) quickBooksConnectStatus();
  // }, []);

  return (
    <div className="offer-box flex flex-col justify-between h-96 w-80 py-11 rounded-md border border-borderColor mr-8 mb-5">
      <div className="flex-1 flex justify-center items-center">
        <div className="image-wrapper">
          <img className="max-h-[100px]" src={qb_logo} alt="QuickBooks" />
        </div>
        {/* <h3 className="text-xl ml-2">Quick Books</h3> */}
      </div>
      {quickBooksConnectError && (
        <p className="text-red">{JSON.stringify(quickBooksConnectError)}</p>
      )}
      <div className="flex flex-col align-middle justify-center px-2">
        {isLoading ? (
          <p>loading ....</p>
        ) : isQBConnected ? (
          <button
            // to="https://accounts.intuit.com/app/sign-in"
            onClick={quickBooksRevokeAccessHandler}
            className="block mx-5 py-3 bg-secondary hover:bg-primary rounded-md text-white"
          >
            Revoke Access
          </button>
        ) : (
          <button
            // to="https://accounts.intuit.com/app/sign-in"
            onClick={quickBooksConnectHandler}
            className="block mx-5 py-3 bg-secondary hover:bg-primary rounded-md text-white"
          >
            Connect QuickBooks
          </button>
        )}
      </div>
    </div>
  );
};

export default QuickBooksComponent;
