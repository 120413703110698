import { Box, Avatar, TextField, Button } from '@mui/material';

const getInitials = (firstName, lastName) => {
  return `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
};

export const memberNameColumn = {
  field: 'memberName',
  headerName: 'Team Member',
  headerClassName: 'grid_header_txt',
  headerAlign: 'center',
  sortable: true,
  minWidth: 150,
  flex: 1,
  renderCell: (params) => (
    <Box className='grid_cell_container leading-4 '>
      <Avatar className='bg-primary font-medium text-base w-8 h-8 mr-4'>
        {/* Use employee initials */}
        {getInitials(
          params.row?.employeeJob?.employee?.firstName,
          params.row?.employeeJob?.employee?.lastName
        )}
      </Avatar>
      <Box>
        <Box className='font-medium text-xs text-primary'>
          {/* Combine employee first & last name */}
          {`${params.row?.employeeJob?.employee?.firstName} ${params.row?.employeeJob?.employee?.lastName}`}
        </Box>
        <Box className='text-ss font-regular text-neutral-900 text-opacity-50'>
          {/* Use jobTitle instead of member.account */}
          {params.row?.employeeJob?.jobTitle?.title}
        </Box>
      </Box>
    </Box>
  ),
};

export const cellTextFiledString = (params) => {
  console.log(`🚀 ~ cellTextFiledString ~ params:`, params);
  return (
    <Box className='grid_cell_container justify-center'>
      <TextField
        defaultValue={params.row[params.field]}
        className='cursor-text'
        variant={'outlined'}
        type={params.colDef?.type || 'string'}
        id='standard-basic'
        size='small'
        InputProps={{
          className: 'txt_bold_base pr-2 py-1',
        }}
        onChange={async (e) => {
          console.log(
            `🚀 ~ cellTextFiledString ~ e.target.value:`,
            e.target.value
          );
          await params.api.setEditCellValue(
            params.id,
            params.field,
            e.target.value
          );
        }}
      />
    </Box>
  );
};

export const colStatic = (
  field,
  headerName,
  minWidth = 130,
  headerAlign = 'center'
) => {
  return {
    field,
    // headerName: (
    //   <Box className='m-auto border-2 flex min-w-full h-full'>Wage Rate</Box>
    // ),
    headerName,
    headerClassName: 'grid_header_txt',
    headerAlign: 'center',
    sortable: true,
    minWidth: 120,
    renderCell: (params) => (
      <Box className='grid_cell_container justify-center'>
        {params?.row?.wageRate}
      </Box>
    ),
  };
};

export const colTextField = (
  field,
  headerName,
  minWidth = 130,
  headerAlign = 'center',
  textVariant = 'standard'
) => {
  return {
    field,
    headerName,
    headerClassName: 'grid_header_txt',
    headerAlign: 'center',
    sortable: true,
    minWidth: minWidth,
    //TODO: should be a parameter
    flex: 1,
    renderCell: (params) => (
      <Box className='grid_cell_container'>
        <TextField
          defaultValue={params.row[params.field]}
          className='cursor-text'
          variant={textVariant}
          id='standard-basic'
          size='small'
          InputProps={{
            className: 'txt_bold_base pr-2 py-1',
          }}
        />
      </Box>
    ),
  };
};
export const colTextFieldVariant = (
  field,
  headerName,
  minWidth = 130,
  headerAlign = 'center'
) => {
  const textField = colTextField(field, headerName, minWidth, headerAlign);
  return {
    ...textField,
    renderCell: (params) => (
      <Box className='grid_cell_container'>
        <TextField
          defaultValue={params?.row?.regular}
          id='standard-basic'
          size='small'
          variant={params?.row?.id === 1 ? 'outlined' : 'standard'}
          InputProps={{
            className: 'txt_bold_base pr-2 py-1',
          }}
        />
      </Box>
    ),
  };
};

export const colButton = (
  field,
  headerName,
  minWidth = 130,
  headerAlign = 'center'
) => {
  return {
    field,
    headerName,
    headerClassName: 'grid_header_txt',
    headerAlign: 'center',
    sortable: true,
    minWidth,

    renderCell: (params) => (
      <Box className='grid_cell_container'>
        <Button
          className='font-circular button_outlined_small m-auto'
          variant='standard'
          // sx={{
          //   fontSize: '16px',
          //   fontWeight: '700',
          //   color: '#7849FF',
          //   borderColor: '#7849FF',
          //   mx: '8px',
          // }}
        >
          {params?.row?.earnings}
        </Button>
      </Box>
    ),
  };
};

export const colStaticTwoRows = (
  field,
  headerName,
  minWidth = 130,
  headerAlign = 'center'
) => {
  return {
    field: 'grossWages',
    headerName,
    sortable: true,
    minWidth: 150,
    flex: 0.5,
    renderCell: (params) => {
      const memberValues = Object.values(params.row[params.field] || {});
      return (
        <Box className='font-circular h-full flex flex-col items-center justify-center align-middle leading-4 pr-5'>
          <TextField
            defaultValue={memberValues[0]}
            size='small'
            id='standard-basic'
            variant='standard'
            InputProps={{
              className: 'txt_bold_base py-1',
            }}
          />
          <Box className='text-xs font-normal ml-auto'>{memberValues[1]}</Box>
        </Box>
      );
    },
  };
};

// export const colDropDown = () => {
//   return {
//     field: 'paymentMethod',
//     headerName: (
//       <Box
//         className='font-circular'
//         sx={{
//           fontSize: '16px',
//           fontWeight: '700',
//         }}
//       >
//         Payment Method
//       </Box>
//     ),
//     sortable: true,
//     // minWidth: 200,
//     flex: 1,
//     renderCell: (params) => (
//       <Box
//         sx={{
//           minWidth: 120,
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center',
//           height: '100%',
//         }}
//       >
//         <FormControl full-width sx={{ width: '257px' }}>
//           <InputLabel
//             id={`demo-simple-select-label-${params.row.id}`}
//             className='font-circular'
//             sx={{
//               fontSize: '16px',
//               fontWeight: '700',
//             }}
//           >
//             {params?.row?.paymentMethod}
//           </InputLabel>
//           <Select
//             labelId={`demo-simple-select-label-${params.row.id}`}
//             id={`demo-simple-select-${params.row.id}`}
//             value={payment[params.row.id] || ''}
//             label='Paper payment'
//             onChange={(e) => handlePaymentChange(params.row.id, e.target.value)}
//             className=''
//           >
//             <MenuItem value={10}>Ten</MenuItem>
//             <MenuItem value={20}>Twenty</MenuItem>
//             <MenuItem value={30}>Thirty</MenuItem>
//           </Select>
//         </FormControl>
//       </Box>
//     ),
//   };
// };
