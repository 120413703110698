import { useFormikContext } from 'formik';
import React, { useState } from 'react';
function isPlainObject(obj) {
  return (
    typeof obj === 'object' &&
    // !Array.isArray(obj) &&
    obj !== null &&
    !(obj instanceof Date) &&
    !(obj instanceof Function)
  );
}
const generateDataView = (data, isError = false, width = 0) => {
  let view = [];
  for (var i in data) {
    // if (!!data[i] && typeof data[i] == "object") {
    if (isPlainObject(data[i])) {
      // console.log(i, o[i]);
      view.push(
        <div>
          <div className='bg-[#777777] text-white text-center' key={i}>
            {i}
          </div>
          {generateDataView(data[i], isError, width)}
        </div>
      );
    } else {
      view.push(
        <div className='flex mb-0' key={i}>
          <div
            className='text-right mr-0 text-black bg-[#3531af] bg-opacity-20 border-b border-[#e7e7e7]'
            style={{
              minWidth: `${width * 5}px`,
              // backgroundColor: "yellow",
            }}
          >
            {i}:
          </div>
          <div
            className={`text-left w-[100%] px-1 font-semibold border-b border-blue-300 bg-blue-200 text-blue-700 ${
              isError ? 'text-red' : ''
            }`}
          >
            {data[i] instanceof Date
              ? new Date(data[i]).toISOString()
              : JSON.stringify(data[i])}
          </div>
        </div>
      );
    }
  }
  return view;
};

const traverse = (o) => {
  const width = [];
  for (var i in o) {
    // if (!!o[i] && typeof o[i] == "object") {
    if (isPlainObject(o[i])) {
      // console.log(i, o[i]);
      width.push(...traverse(o[i]));
    } else {
      // console.log(i, o[i]);
      width.push(i.length);
    }
  }
  return width;
};
const FormikStatus = ({ errors, values, ...props }) => {
  const formikContext = useFormikContext();
  const [showValues, setShowValues] = useState(false);
  const [showError, setShowError] = useState(true);
  const keys = traverse(formikContext.values);
  const keyWidth = Math.max(...traverse(formikContext.values));
  if (process.env.REACT_APP_FORMIK_DEBUG !== 'true') {
    return null;
  }
  return (
    <>
      <div className='flex flex-col max-w-fit justify-between  top-10 left-0 absolute text-xs p-1 z-50 font-mono'>
        <div className='mb-1'>
          <div className='flex min-w-fit bg-[#cd3a3a]  text-white font-extrabold'>
            <div className='text-center  min-w-[130px] flex-grow'>
              FORMIK Errors
            </div>
            <div
              className={`ml-1 px-1 flex justify-center cursor-pointer items-center`}
              onClick={() => setShowError(!showError)}
            >
              {showError ? 'HIDE' : 'SHOW'}
            </div>
          </div>
          {/* <pre>{JSON.stringify(formikContext.errors, null, 2)}</pre> */}
          {showError
            ? generateDataView(formikContext.errors, true, keyWidth)
            : null}
        </div>
        <div>
          <div className='flex min-w-fit bg-blue-500 text-white font-extrabold'>
            <div className='text-center min-w-[130px] flex-grow'>
              FORMIK Values
            </div>
            <div
              className={`ml-1 px-1 flex justify-center cursor-pointer items-center`}
              onClick={() => setShowValues(!showValues)}
            >
              {showValues ? 'HIDE' : 'SHOW'}
            </div>
          </div>
          {/* <pre>{JSON.stringify(formikContext.values, null, 2)}</pre> */}
          {showValues
            ? generateDataView(formikContext.values, false, keyWidth)
            : null}
        </div>
      </div>
    </>
  );
};

export default FormikStatus;
