import moment from 'moment';

function currency(number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}
function number(number) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

function shortDate(utcDate) {
  // Convert the UTC date to a local date
  const localDate = new Date(utcDate);

  // Use Intl.DateTimeFormat to format the local date
  return new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: '2-digit',
  }).format(localDate);
}

function duration(utcDate) {
  const date = moment.utc(utcDate);
  const now = moment();

  const duration = moment.duration(date.diff(now));
  const weeks = duration.asWeeks();
  const days = duration.asDays();
  const hrs = duration.asHours();

  let message;

  if (weeks > 0) {
    message = `Due in ${Math.floor(weeks)} week(s) ${days} day(s)`;
  } else if (days > 0) {
    message = `Due in ${days} day(s) & ${hrs} hour(s)`;
  } else {
    message = `Due in ${hrs} hour(s)`;
  }
  return message;
}

const Format = {
  currency,
  number,
  shortDate,
  duration,
};

export { Format };
