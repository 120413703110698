import React from "react";
import { useSelector } from "react-redux";
import { selectCompany } from "../../redux/features/auth.slice";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import Shortcut from "./Shortcut";
import SearchBar from "./SearchBar";

const WelcomeSection = () => {
  const company = useSelector(selectCompany);

  return (
    <section className='flex md:flex py-11 justify-center '>
      <div className='flex flex-col justify-center align-middle  border-borderColor '>
        <h3 className='text-3xl py-8 font-circular font-medium  text-center text-white'>
          Good afternoon, {company.name}
        </h3>
        <div className='w-full '>
          <SearchBar />
        </div>
        <div className='my-5 flex gap-10 '>
          {/* <div>
            <div className='shortcuts-container'>
              <Icon icon='fluent:people-team-16-regular' />
            </div>
            <span className='text-black'>Directory </span>
          </div> */}
          <Shortcut
            name='Directory'
            iconName={"fluent:people-team-16-regular"}
            onShortcutClick={() => console.log("Shortcut DIRECTORY")}
          />
          <Shortcut
            name='HR411'
            iconName='ri:folder-user-line'
            onShortcutClick={() => console.log("Shortcut HR411")}
          />
          <Shortcut
            name='Post a job'
            iconName='ri:folder-user-line'
            onShortcutClick={() => console.log("Shortcut Post a job")}
          />
          <Shortcut
            name='Doc Vault'
            iconName='ion:document-lock-outline'
            onShortcutClick={() => console.log("Shortcut Doc Vault")}
          />
          <Shortcut
            name='Exclusive perks'
            iconName='lets-icons:gift-light'
            onShortcutClick={() => console.log("Shortcut Exclusive perks")}
          />
          <Shortcut
            name='Edit'
            iconName='tdesign:edit'
            onShortcutClick={() => console.log("Shortcut Edit")}
          />
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
