import React from 'react';

const ComponentContainer = ({ title, children }) => {
  return (
    <div className='bg-white p-5 rounded-xl h-full flex flex-col'>
      <div className='py-5 text-xl font-circular font-semibold'>
        <h2 className='px-5'>{title}</h2>
        <hr className='h-[2px] mt-4 bg-zinc-300 border-0'></hr>
      </div>
      <div className='component_body'></div>
      {children}
    </div>
  );
};

export default ComponentContainer;
