import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, FormControl } from '@mui/material';
import * as Yup from 'yup';

import FieldTextField from '../../commonComponents/FormikMuiFields/FieldTextField';
import OnboardingContentWrapper from './components/OnboardingContentWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useNavigation } from 'react-router-dom';
import { register } from '../../redux/features/user.slice';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  // companyName: "",
  password: '',
  confirmPassword: '',
  phone: '',
};

const validationSchema = Yup.object({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required Field'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required Field'),
  email: Yup.string().email('Invalid email').required('Required Field'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required Field'),
  confirmPassword: Yup.string()
    .required('Required Field')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  phone: Yup.string()
    .matches('^\\d{8,14}$', 'Invalid Phone number, must be 8 to 14 digits')
    .required('Required Field'),
});
const CreateUserAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth?.userInfo?.isVerified === false) {
      //TODO: [Temporarily] disable Email Verification
      // navigate('/onboarding/verify');
      navigate('/onboarding/company');
    } else if (auth?.userInfo?.isVerified === true) {
      navigate('/onboarding/company');
    }
  }, [auth?.userInfo, navigate]);

  return (
    <OnboardingContentWrapper>
      <div className='flex-1 text-2xl mt-10 text-center font-bold px-2'>
        Create a Beyond Account
      </div>
      <div className='flex-1 p-1 flex flex-row '>
        <div className='flex-grow'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              dispatch(register(values));
            }}
          >
            <Form noValidate>
              <div className='flex flex-col md:flex-row justify-between w-[100%]'>
                <FormControl
                  margin='normal'
                  className='drop-shadow-md mr-1 w-[48%]'
                >
                  <Field
                    component={FieldTextField}
                    name='firstName'
                    label='First Name'
                    required
                  />
                </FormControl>
                <FormControl
                  margin='normal'
                  className='drop-shadow-md ml-1 w-[48%]'
                >
                  <Field
                    component={FieldTextField}
                    name='lastName'
                    label='Last Name'
                    required
                  />
                </FormControl>
              </div>
              <div>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    component={FieldTextField}
                    name='email'
                    label='Email'
                    placeholder='example@company.com'
                    required
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    component={FieldTextField}
                    name='password'
                    label='Password'
                    type='password'
                    required
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    component={FieldTextField}
                    name='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    required
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  margin='normal'
                  className='drop-shadow-md'
                >
                  <Field
                    component={FieldTextField}
                    name='phone'
                    label='Phone'
                    required
                  />
                </FormControl>
              </div>
              <div className='mt-4 flex justify-end'>
                <Button
                  type='submit'
                  className='bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase'
                  variant='outlined'
                >
                  Create Account
                </Button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default CreateUserAccount;
