import { FormControl } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";
import { requiredPhone } from "../../../helpers/yupValidationRoles";
import { setOnboardUserFormDataField } from "../../../redux/features/onboardEmployee.slice";

// update Validation Schema
export const validationSchema = Yup.object({
  residentAddress: Yup.object().shape({
    street1: Yup.string().required("Required Field"),
    street2: Yup.string(),
    city: Yup.string().required("Required Field"),
    state: Yup.string().required("Required Field"),
    zipCode: Yup.string().required("Required Field"),
    phone: requiredPhone,
  }),
});

const EmployeeResidentAddressForm = () => {
  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    // Create & Dispatch UserOnboardUpdateField action
    dispatch(
      setOnboardUserFormDataField({
        parent: fieldName.split(".")[0],
        fieldName: fieldName.split(".")[1],
        fieldValue,
      })
    );
  };
  return (
    <>
      <h1 className="text-xl">Resident Address</h1>
      <p className="font-semibold">Please share your resident location:</p>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="residentAddress.street1"
          label="Street 1"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          name="residentAddress.street2"
          label="Street 2"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="residentAddress.city"
          label="City"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="residentAddress.state"
          label="State"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        ></Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="residentAddress.zipCode"
          label="Zip Code"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="residentAddress.phone"
          label="Phone Number"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default EmployeeResidentAddressForm;
