import React, { useEffect, useState } from "react";
import OnboardingContentWrapper from "../components/OnboardingContentWrapper";
import OnboardingStepper from "../components/OnboardingStepper";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const EmployeeDocumentSignForm = () => {
  const navigate = useNavigate();
  //TODO: replace useState with redux suseSelector
  const [isDocumentsSigned, setIsDocumentsSigned] = useState(false);

  useEffect(() => {
    //TODO: Set UserDocumentStep
    //dispatch(updateActiveStep(bankAccountStep));
  }, []);
  return (
    <OnboardingContentWrapper>
      <div className="flex-1 text-2xl text-center font-bold px-2 min-w-fit">
        <OnboardingStepper isUserOnboard={true} />
      </div>
      <div className="flex flex-col p-4 min-w-[80%]">
        <h1 className="text-xl">Documents</h1>
        <p className="font-semibold">
          View and sign documents provided to you by Beyond.
        </p>

        <div className="flex text-xl font-semibold my-6 border-b-2 border-black text-center">
          <div className="w-[20%]">Name</div>
          <div className="w-[50%]">Description</div>
          {/* <div className="w-[30%]">Action</div> */}
        </div>
        <div className=" flex ">
          <div className="w-[20%] mx-1 font-semibold">Form I-9</div>
          <div className="w-[80%] mx-1">
            Form XYZ Lorem ipsum dolor sit amet, consectetur adipisicing edivt.
            Maiores, labore dolorum in, quod accusamus, dolorem rem harum
            quaerat architecto ipsum odit optio quo laudantium soluta adivquid
            totam repellat quasi. Magnam?
          </div>
          {/* <div className="w-[30%] mx-1 flex justify-center align-top">
            <button className="button_primary max-h-10">Sign Document</button>
          </div> */}
        </div>
        <div className="flex flex-col w-full justify-center p-10 align-middle">
          {/* <FormControlLabel
            required
            control={<Checkbox />}
            label="I agree to electronically sign this form"
          /> */}

          <div className="flex items-start">
            <Checkbox sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }} />
            <p className="">
              I agree to electronically sign this form. I am aware that federal
              law provides for imprisonment and/or fines for false statements,
              or the use of false documents, in connection with the completion
              of this form. I attest, under penalty of perjury, that this
              information, including my selection of the box attesting to my
              citizenship or immigration status, is true and correct.
            </p>
          </div>
          <div className="">
            <FormControl className=" px-10 py-4">
              <FormLabel className="text-lg font-semibold -ml-5">
                Did you use a preparer/translator?
              </FormLabel>
              <RadioGroup>
                <FormControlLabel
                  className=" -my-2"
                  value={true}
                  control={<Radio />}
                  label="Yes, I used a preparer/translator"
                />
                <FormControlLabel
                  className=" -my-2"
                  value={false}
                  control={<Radio />}
                  label="No, I completed this myself"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="w-full mx-1 flex justify-end p-4 border-b-2 border-[#c7c7c7]">
            <button className="button_primary max-h-10">Sign Document</button>
          </div>
        </div>

        <div className="flex justify-end align-end mt-6 ">
          <button
            className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
            // type="submit"
            // disabled={!isDocumentsSigned}
            onClick={() => navigate("/onboarding/employee/introduction")}
          >
            Next ≫
          </button>
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default EmployeeDocumentSignForm;
