import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";

const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  "& .MuiInputLabel-asterisk": {
    // .MuiInputLabel-required::before": {
    fontSize: "0.75rem" /* Change font size to your preference */,
    fontWeight: 600 /* Change font weight to your preference */,
    // content: "*",
    color: "red" /* Change to your desired color */,
  },
}));

export default StyledInputLabel;
