import React, { useEffect, useState } from 'react';
import ActiveLink from '../utility/ActiveLink';
import './Navbar.css';
import { Icon } from '@iconify/react';
import { useLocation, useNavigate } from 'react-router-dom';
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [isPeopleActive, setIsPeopleActive] = useState(false);
  const [isPayrollActive, setIsPayrollActive] = useState(false);

  // const handlePeopleClicked = () => {
  //   setIsPeopleActive(!isPeopleActive);
  //   navigate('/people');
  // };
  useEffect(() => {
    // if (location.pathname.includes('/people')) setIsPeopleActive(true);
    if (location.pathname.includes('/payroll')) setIsPayrollActive(true);
  }, [isPayrollActive, location]);

  return (
    <nav className='side-navbar'>
      {/* <ActiveLink to={'/'}>
        <Icon icon='tabler:gauge' />
        <span className='menu-text'> Dashboard </span>
      </ActiveLink> */}
      {/* <ActiveLink to={"/payments"}>
        <Icon icon="material-symbols:attach-money-rounded" />
        <span className="menu-text"> Payments </span>
      </ActiveLink>
      <ActiveLink to={"/accounting"}>
        <Icon icon="map:accounting" />
        <span className="menu-text"> Accounting </span>
      </ActiveLink> */}
      <ActiveLink to={'/funding'}>
        <Icon icon='material-symbols:insert-chart-outline-rounded' />{' '}
        <span className='menu-text'> Funding </span>
      </ActiveLink>
      {/* <ActiveLink to={"/payroll"}>
        <Icon icon='material-symbols:attach-money-rounded' />
        <span className='menu-text flex-1'> Payroll </span>
        {isPeopleActive ? (
          <Icon icon='material-symbols:expand-more' />
        ) : (
          <Icon icon='material-symbols:expand-less' />
        )}
      </ActiveLink>
      {isPayrollActive && (
        <div in={isPeopleActive} timeout='100' className='sub-menu-container '>
          <ActiveLink to={"/payroll/run"} sub={true}>
            <Icon icon='fluent:people-team-16-regular' />
            <span className={`menu-text `}>Payroll Run</span>
          </ActiveLink>
        </div>
      )} */}
      {/* <ActiveLink to={'/people'}>
        <Icon icon='heroicons:users' />
        <span className='menu-text flex-1'> People </span>
        {isPeopleActive ? (
          <Icon icon='material-symbols:expand-more' />
        ) : (
          <Icon icon='material-symbols:expand-less' />
        )}
      </ActiveLink> */}
      {/* {isPeopleActive && (
        <div in={isPeopleActive} timeout='100' className='sub-menu-container '>
          <ActiveLink to={'/people/team-members'} sub={true}>
            <Icon icon='fluent:people-team-16-regular' />
            <span className={`menu-text `}>Team Members</span>
          </ActiveLink>
          {/* <ActiveLink to={"/people/team"} sub={true}>
          <Icon icon="fluent:people-team-16-regular" />
          <span className={`menu-text `}>Team </span>
        </ActiveLink> 
        </div>
      )}
       */}
      {/* <List className="w-full text-3xl font-semibold border">
        <ListItemButton
          onClick={handlePeopleClicked}
          className="text-4xl border bg-red py-4 rounded-md"
        >
          <ListItemIcon>
            <Icon icon="heroicons:users" />
          </ListItemIcon>
          <ListItemText
            // className="bg-yellow text-4xl text-red"
            primary={`People ${isPeopleActive}`}
          />
          {isPeopleActive ? (
            <Icon icon="material-symbols:expand-more" />
          ) : (
            <Icon icon="material-symbols:expand-less" />
          )}
        </ListItemButton>
        <Collapse in={isPeopleActive} timeout="auto">
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => navigate("/people/team-members")}
            >
              <ListItemIcon>
                <Icon icon="fluent:people-team-16-regular" />
              </ListItemIcon>
              <ListItemText primary="Team Members" />
            </ListItemButton>
          </List>
        </Collapse>
      </List> */}
      {/* <ActiveLink to={"/reports"}>
        <Icon icon='material-symbols:insert-chart-outline-rounded' />{" "}
        <span className='menu-text'> Reports </span>
      </ActiveLink>
      <ActiveLink to={"/document"}>
        <Icon icon='carbon:document' />{" "}
        <span className='menu-text'> Documents </span>
      </ActiveLink>
      <ActiveLink to={"/contact"}>
        <Icon icon='ph:user-bold' />{" "}
        <span className='menu-text'> Contacts </span>
      </ActiveLink>
      <ActiveLink to={"/apps-offers"}>
        <Icon icon='ic:round-star-outline' />
        <span className='menu-text'> Apps & Offers </span>
      </ActiveLink> */}
    </nav>
  );
};

export default Navbar;
