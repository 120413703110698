import { useField } from 'formik';
import React, { useEffect } from 'react';
import StyledTextField from '../MuiStyled/StyledTextField';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  createFilterOptions,
} from '@mui/material';
import { toast } from 'react-toastify';

const filter = createFilterOptions();

const FieldFreeSoloDialog = ({
  onFieldValueChanged,
  helperText,
  textFieldProps,
  form: { setFieldValue },
  label,
  required,
  handleDBAdd,
  dbTitle = '',
  dbValue = '',
  ...props
}) => {
  const [field, meta] = useField(props.field);
  const [value, setValue] = React.useState(null);
  const [open, toggleOpen] = React.useState(false);
  const hasError = meta.touched && Boolean(meta.error);
  const error = meta.touched && meta.error;

  const handleChange = (value) => {
    setFieldValue(field.name, value);
    onFieldValueChanged(field.name, value);
  };

  const handleClose = () => {
    setDialogValue({
      title: '',
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    let dbRecord = {};
    //HAndles adding to Database
    if (handleDBAdd instanceof Function) {
      const result = await handleDBAdd(dialogValue.title);
      if (result?.ok === true) {
        dbRecord = result.data;
        setValue({
          title: dbRecord[dbTitle],
          value: dbRecord[dbValue],
        });
        handleClose();
      } else {
        toast.error(result.error);
      }
    } else {
      //Handle adding just text to options
      setValue({
        title: dialogValue.title,
      });
      handleClose();
    }
  };
  useEffect(() => {
    if (value !== null) {
      handleChange(value);
    } else {
      handleChange('');
    }
  }, [value]);
  return (
    <>
      <Autocomplete
        {...field}
        {...props}
        value={value}
        onInputChange={(event, newValue, reason) => {
          // console.log(
          //   `INPUT CHANGED - Reason: ${reason} | newValue: ${newValue}`
          // );
        }}
        renderInput={(props) => (
          <StyledTextField
            {...props}
            {...textFieldProps}
            label={label}
            required={required}
            helperText={helperText}
            error={error}
          />
        )}
        onChange={(event, newValue) => {
          if (typeof newValue === 'string') {
            setTimeout(() => {
              toggleOpen(true);
              setDialogValue({
                title: newValue,
              });
            });
          } else if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        // onChange={handleChange}
        getOptionLabel={(option) => {
          // e.g. value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={(props, option) => (
          <li {...props} key={option.value}>
            {option.title}
          </li>
        )}
        freeSolo
      />
      <Dialog open={open} onClose={handleClose}>
        {/* <form onSubmit={handleSubmit}> */}
        <DialogTitle className='w-[400px]'>{`Add a new ${label}`}</DialogTitle>
        <DialogContent>
          <StyledTextField
            fullWidth
            autoFocus
            margin='dense'
            id='name'
            value={dialogValue.title}
            onChange={(event) =>
              setDialogValue({
                ...dialogValue,
                title: event.target.value,
              })
            }
            label={label}
            type='text'
            // variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button className='text-red font-semi-bol' onClick={handleClose}>
            Cancel
          </Button>
          <Button className='button_primary' onClick={handleSubmit}>
            Add
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
    </>
  );
};

export default FieldFreeSoloDialog;
