import React from "react";
import OnboardingContentWrapper from "../components/OnboardingContentWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCredentials,
  selectEmployeeInfo,
} from "../../../redux/features/auth.slice";

const EmployeeOnboardingCompleted = () => {
  const dispatch = useDispatch();
  const employee = useSelector(selectEmployeeInfo);

  return (
    <OnboardingContentWrapper>
      <div className="flex flex-col p-4 w-full mx-auto">
        <div className="flex flex-col flex-grow items-center justify-center p-4">
          <p className="text-xl text-center">
            Nice job,{" "}
            <span className="font-semibold">{employee.firstName}!</span>
            <h1 className="font-bold text-3xl">You've completed setup</h1>
          </p>
          <p className="p-10">
            your account will now be reviewed by your company admin. After that,
            you'll be able to sign in and download your paystubs and tax forms,
            update personal info, and more.
          </p>
        </div>

        <div className="flex justify-center my-6 ">
          <button
            className="bg-primary text-white px-10 py-2 rounded-md hover:bg-secondary font-semibold uppercase "
            onClick={() => dispatch(clearCredentials())}
          >
            Sign Out
          </button>
        </div>
      </div>
    </OnboardingContentWrapper>
  );
};

export default EmployeeOnboardingCompleted;
