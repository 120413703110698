import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Icon } from '@iconify/react';
import ComponentContainer from '../../commonComponents/layout/ComponentContainer';

ChartJS.register(ArcElement, Tooltip, Legend);

const LastPayroll = () => {
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'USD Required',
        data: [75.0],
        backgroundColor: ['rgba(127, 73, 255, 0.2)'],
        borderColor: ['rgba(127, 73, 255, 0.2)'],
        borderWidth: 0,
      },
    ],
  };
  return (
    <ComponentContainer title={'Last Payroll'}>
      <div className='component_body flex-col'>
        <div className='flex flex-1 justify-between gap-9 mt-3'>
          <div className='flex flex-col flex-1 bg-gray-200 h-24 rounded-lg p-5 text-neutral-500'>
            Check date
            <span className='text-2xl font-bold text-black'>10/10/2024</span>
          </div>
          <div className='flex flex-col flex-1 bg-gray-200 h-24 rounded-lg p-5'>
            Pay Period
            <span className='text-2xl font-bold'>12/25 - 01/07</span>
          </div>
        </div>
        <div className='p-10'>
          <Doughnut data={data} options={{ cutout: '65%', width: 300 }} />
        </div>
      </div>
      <div className='component_header  text-primary font-semibold text-base'>
        <div className='flex justify-between px-4'>
          <span className='flex items-center gap-1'>
            <Icon icon='ph:plus-circle-fill' className='' />
            Report Package
          </span>
          <span className='flex items-center gap-1'>
            <Icon icon='prime:truck' />
            Track Delivery
          </span>
        </div>
        <hr className='h-[2px] my-4 bg-neutral-400 border-0'></hr>
        <div className='flex justify-center'>Payroll details</div>
      </div>
    </ComponentContainer>
  );
};

export default LastPayroll;
