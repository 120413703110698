import APISecured from '../utility/APISecureClient';

const register = async (user) => {
  const response = await APISecured.post('/users', { user });
  return response.data;
};

const verifyUser = async (verifyEmail, verifyCode) => {
  const response = await APISecured.post('/users/verify-user', {
    verificationCode: verifyCode,
    email: verifyEmail,
  });
  return response.data;
};

const forgotPassword = async (userEmail) => {
  const response = await APISecured.post('/users/forgot-password', {
    userEmail,
  });
  return response.data;
};

const resetPassword = async (userEmail, otp, newPassword) => {
  const response = await APISecured.post('/users/reset-password', {
    userEmail,
    otp,
    newPassword,
  });
  return response.data;
};
const UserAPI = {
  register,
  verifyUser,
  forgotPassword,
  resetPassword,
};

export { UserAPI };
