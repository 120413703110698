import { Icon } from '@iconify/react';
import React from 'react';
import Layout from '../../commonComponents/Layout';

const Report = () => {
    return (
        <Layout>
        <div className='text-center'>
        <div className='box-wrapper flex justify-between align-middle'>
                <div className="title box"><h3 className='text-5xl'>Financial Reports</h3> </div>
            </div>
            <div className="table-wrapper border border-borderColor rounded-md mt-9 overflow-hidden">
                <div className="table-heading grid grid-cols-3 bg-gray p-5">
                    <span className='font-bold inline-flex items-center'>Name <Icon  icon="ic:round-keyboard-arrow-down" /></span>
                    <span className='font-bold'>Last Updated</span>
                    <span className='flex justify-end'><Icon className='' height={30} icon="bi:filter-left" /></span>
                </div>
                <div className="grid grid-cols-1 p-5 border-b border-borderColor text-center">
                    No data...
                </div>

            </div>
        </div>
        </Layout>
    );
};

export default Report;