import React, { useEffect, useState } from 'react';
import Layout from '../../commonComponents/Layout';
import { Button, Collapse } from 'antd';
import { useSelector } from 'react-redux';
import { selectCompany } from '../../redux/features/auth.slice';
import { IntegrationsAPI } from '../../services/Integrations.api';
import JarisConnect from './JarisConnect';
import ReactGA from "react-ga4";
const { Panel } = Collapse;

const FundingPage = () => {
  const company = useSelector(selectCompany);
  const [jarisMerchant, setJarisMerchant] = useState(null);
  const confirmJaris = async () => {
    const newMerchant = await IntegrationsAPI.Jaris.createJarisMerchant(
      company.id
    );
    setJarisMerchant(newMerchant.data.jarisMerchant);
  };

  useEffect(() => {
    document.title = "Register";
    ReactGA.send({ hitType: "pageview", page: "/funding", title: "Funding" });

  }, []);


  const pStyles = {
    maxWidth: '75%',
    marginTop: '2vh',
    marginBottom: '2vh',
    textDecoration: 'italic',
  };

  const pageStyles = {
    paddingTop: '0px',
    paddingLeft: '20px',
    paddingRight: '20px',
  };

  const button = {
    marginBottom: '2vh',
    // "paddingTop":"1vh",
    // "paddingBottom":"2vh"
  };

  const liStyle = {
    listStyleType: 'circle',
    paddingLeft: '30px',
  };
  useEffect(() => {
    const fetchJarisMerchant = async () => {
      const merchant = await IntegrationsAPI.Jaris.retrieveMerchant(company.id);
      setJarisMerchant(merchant.data.jarisMerchant);
    };
    fetchJarisMerchant();
  }, []);
  return (
    <Layout>
      <div className='text-center'>
        <div className='box-wrapper flex justify-between align-middle'>
          <div className='title box'>
            <h3 className='text-5xl'>
              {' '}
              {jarisMerchant == null ? 'Apply for funding' : null}
            </h3>
          </div>
        </div>
      </div>
      <div>
        {jarisMerchant === null ? (
          <>
            <p className='mt-9' style={pStyles}>
              By allowing access, you agree that we can share your Beyond
              account data with Jaris.io and that Jaris.io can share your data
              with us, including as described below. To learn more, see our
              Terms of Service and Privacy Statement.
            </p>

            <p className='max-w-[75%] my-4'>
              A FICO of at least 600 is required in order to be eligible for a
              Beyond Capital loan. If you are unsure of your FICO score please
              feel free to apply, we conduct a soft credit pull which doesn't
              affect your credit.
            </p>
            <button
              onClick={confirmJaris}
              className='button_primary'
              style={button}
            >
              Allow Access
            </button>
            <Collapse className='max-w-[75%]' defaultActiveKey={['1']}>
              <Panel header='Details about your data' key='1'>
                <p>
                  When you select Connect we will grant Jaris.io access to your
                  Beyond account data.
                </p>
                <p>Your information including:</p>
                <ul style={liStyle}>
                  <li className=''>Email Address</li>
                  <li className=''>Data about your company</li>
                  <li className=''>
                    Data about your customers, vendors, and/or employees
                  </li>
                  <li className=''>Transaction history</li>
                  <li className=''>
                    Any updates you may make to your Beyond account data after
                    you connect
                  </li>
                </ul>
              </Panel>
            </Collapse>
          </>
        ) : (
          <div style={pageStyles}>
            <JarisConnect jarisMerchant={jarisMerchant} />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default FundingPage;
