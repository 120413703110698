import { FormControl } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { updateField } from "../../../redux/features/onboardCompany.slice";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";

export const validationSchema = Yup.object({
  companyName: Yup.string().required("Required Field"),
  companyEmail: Yup.string().email("Invalid email").required("Required Field"),
  ownerFirstName: Yup.string().required("Required Field"),
  ownerLastName: Yup.string().required("Required Field"),
  ownerEmail: Yup.string().email("Invalid Email").required("Required Field"),
  ownerTitle: Yup.string().required("Required Field"),
  ownerPhone: Yup.string()
    .matches("^\\d{8,14}$", "Invalid Phone number, must be 8 to 14 digits")
    .required("Required Field"),
});

const CompanyOwnershipForm = () => {
  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(updateField({ field: fieldName, value: fieldValue }));
  };
  return (
    <>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="companyName"
          label="What is the name of your company (DBA)"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="companyEmail"
          label="What is the email of your company"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <div className="flex flex-col md:flex-row justify-between w-[100%]">
        <FormControl margin="normal" className="drop-shadow-md mr-1 w-[48%]">
          <Field
            required
            name="ownerFirstName"
            label="Owner First Name"
            component={FieldTextField}
            onFieldValueChanged={handleFieldValueChange}
          />
        </FormControl>
        <FormControl margin="normal" className="drop-shadow-md ml-1 w-[48%]">
          <Field
            required
            name="ownerLastName"
            label="Owner Last Name"
            component={FieldTextField}
            onFieldValueChanged={handleFieldValueChange}
          />
        </FormControl>
      </div>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="ownerEmail"
          label="Owner Email"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        ></Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="ownerTitle"
          label="Owner Title"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="ownerPhone"
          label="Owner Phone"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default CompanyOwnershipForm;
