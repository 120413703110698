import React from "react";
import OnboardingLayout from "./OnboardingLayout";

const OnboardingContentWrapper = ({ children }) => {
  return (
    <OnboardingLayout>
      <div className="flex flex-col h-fit  bg-white md:flex-row lg:w-[70%] md:w-[80%] md:m-16 m-4 p-4 shadow-md rounded-2xl min-w-[400px]">
        {children}
      </div>
    </OnboardingLayout>
  );
};

export default OnboardingContentWrapper;
