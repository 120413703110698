import * as Yup from 'yup';
export const requiredName = Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
  .required('Required Field');

export const PhoneNumber = Yup.string().matches(
  '^\\d{8,14}$',
  'Invalid Phone number, must be 8 to 14 digits'
);

export const requiredPhone = Yup.string()
  .matches('^\\d{8,14}$', 'Invalid Phone number, must be 8 to 14 digits')
  .required('Required Field');

export const requiredEmail = Yup.string()
  .email('Invalid email')
  .required('Required Field');

export const requiredSocialSecurity = Yup.string().required('Required Field');

export const requiredString = Yup.string().required('Required Field');

export const requiredNumber = Yup.number().required('Required Field');

export const requiredPositiveNumber = requiredNumber.positive(
  'Please enter a number greater than zero'
);

export const positiveNumber = Yup.number().positive(
  'Please enter a number greater than zero'
);

export const requiredPositiveOrZeroNumber = requiredNumber.min(
  0,
  'Please enter a non-negative integer (including zero)'
);

export const requiredPositiveInteger = requiredPositiveOrZeroNumber.integer(
  'Please enter a whole number (integer)'
);

export const requiredBoolean = Yup.boolean().required('Required Field');

export const requiredDropdown = Yup.mixed().test(
  'is-selected',
  'Required Field',
  function (value) {
    return !!value && !!value.value;
  }
);
