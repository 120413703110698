import { FormControl, MenuItem } from "@mui/material";
import { Field } from "formik";
import React from "react";
import * as Yup from "yup";
import FieldSelect from "../../../commonComponents/FormikMuiFields/FieldSelect";
import { setOnboardUserFormDataField } from "../../../redux/features/onboardEmployee.slice";
import { useDispatch } from "react-redux";

export const validationSchema = Yup.object({
  employmentEligibility: Yup.object({
    citizenshipImmigrationStatus: Yup.string().required("Required Field"),
    // .oneOf(["A citizen of the United States"]),
  }),
});
const EmployeeEmploymentEligibilityForm = () => {
  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(
      setOnboardUserFormDataField({
        parent: fieldName.split(".")[0],
        fieldName: fieldName.split(".")[1],
        fieldValue,
      })
    );
  };
  return (
    <>
      <h1 className="text-xl">Employment Eligibility</h1>
      <p className="font-semibold">
        The federal government requires verification of your eligibility to work
        in the US. For more info, check out the Form I-9.
      </p>

      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          fullWidth
          required
          name="employmentEligibility.citizenshipImmigrationStatus"
          label="I AM"
          formHelperText={{
            children:
              "This info will be used to fill out Form 1-9, which proves that you’re legally allowed to work in the US.",
          }}
          component={FieldSelect}
          onFieldValueChanged={handleFieldValueChange}
        >
          <MenuItem value={"A citizen of the United States"}>
            A citizen of the United States
          </MenuItem>
          <MenuItem value={"A noncitizen national of the United States"}>
            A noncitizen national of the United States
          </MenuItem>
          <MenuItem value={"A lawful permanent resident"}>
            A lawful permanent resident
          </MenuItem>
          <MenuItem
            value={
              "A noncitizen (other than Item Numbers 2. and 3. above) authorized to work"
            }
          >
            A noncitizen (other than Item Numbers 2. and 3. above) authorized to
            work
          </MenuItem>
        </Field>
      </FormControl>
    </>
  );
};

export default EmployeeEmploymentEligibilityForm;
