import { FormControl, MenuItem } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateField } from "../../../redux/features/onboardCompany.slice";
import FieldSelectCheckers from "../../../commonComponents/FormikMuiFields/FieldSelectCheckers";
import FieldSelect from "../../../commonComponents/FormikMuiFields/FieldSelect";
import FieldDatePicker from "../../../commonComponents/FormikMuiFields/FieldDatePicker";
import FieldRadioGroup from "../../../commonComponents/FormikMuiFields/FieldRadioGroup";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";

export const validationSchema = Yup.object({
  payrollDesireStartDate: Yup.string().required("Required Field"),
  payrollPayPeriodEnd: Yup.string().required("Required Field"),
  isFirstPayroll: Yup.boolean(),
  payFrequency: Yup.string().required("Required Field"),
  exPayrollCompany: Yup.string().when("isFirstPayroll", {
    is: false,
    then: (schema) => schema.required("Required Field"),
  }),
  payingWhom: Yup.array().of(Yup.string()).min(1),
});

const CompanyPayrollForm = ({ formik }) => {
  const dispatch = useDispatch();

  const payingWhomOptions = [
    { label: "My Self", value: "myself" },
    { label: "Employees", value: "employees" },
    { label: "Contractors", value: "contractors" },
  ];

  const handleFieldValueChange = (fieldName, fieldValue) => {
    dispatch(updateField({ field: fieldName, value: fieldValue }));
  };

  return (
    <>
      <FormControl margin="normal" className="drop-shadow-md">
        <Field
          required
          className={"w-[100%] lg:w-[75%]"}
          component={FieldDatePicker}
          name="payrollDesireStartDate"
          label="What's your desired first payday with Beyond?"
          id="company-form-payroll-desire-start-date"
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Field
          fullWidth
          required
          className="drop-shadow-md"
          label="Who are you planning to pay?"
          name="payingWhom"
          multiple
          component={FieldSelectCheckers}
          renderValue={(selected) =>
            selected
              .map(
                (value) =>
                  payingWhomOptions.find((item) => item.value === value).label
              )
              .join(", ")
          }
          options={payingWhomOptions}
          onFieldValueChanged={handleFieldValueChange}
        ></Field>
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Field
          fullWidth
          required
          className="drop-shadow-md"
          component={FieldSelect}
          label="What is your desired pay frequency?"
          name="payFrequency"
          multiple={false}
          onFieldValueChanged={handleFieldValueChange}
        >
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="biweekly">Biweekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="quarterly">Quarterly</MenuItem>
        </Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          className={"w-[100%] lg:w-[75%]"}
          component={FieldDatePicker}
          name="payrollPayPeriodEnd"
          label="When does your pay period end?"
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl margin="normal">
        <Field
          component={FieldRadioGroup}
          name="isFirstPayroll"
          label="Will this be your first payroll of 2023?"
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Field
          fullWidth
          className="drop-shadow-md"
          component={FieldSelect}
          label="What payroll company are you currently using?"
          name="exPayrollCompany"
          onFieldValueChanged={handleFieldValueChange}
        >
          {payrollCompanies.map((company) => (
            <MenuItem key={company.value} value={company.value}>
              {company.label}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </>
  );
};

const payrollCompanies = [
  { label: "Acorn", value: "Acorn" },
  { label: "ADP", value: "ADP" },
  { label: "Alliance HCM", value: "Alliance HCM" },
  { label: "APS Payroll", value: "APS Payroll" },
  { label: "Bamboo", value: "Bamboo" },
  { label: "Evolution", value: "Evolution" },
  { label: "Gusto", value: "Gusto" },
  { label: "Isolved", value: "Isolved" },
  { label: "JustWorks", value: "JustWorks" },
  { label: "Mpay", value: "Mpay" },
  { label: "NetChex", value: "NetChex" },
  { label: "OnPay", value: "OnPay" },
  { label: "Oracle for NetSuite", value: "Oracle for NetSuite" },
  { label: "Patriot Software", value: "Patriot Software" },
  { label: "Paychex", value: "Paychex" },
  { label: "Paycom", value: "Paycom" },
  { label: "PayCor", value: "PayCor" },
  { label: "Paylocity", value: "Paylocity" },
  { label: "QuickBooks", value: "QuickBooks" },
  { label: "Rippling", value: "Rippling" },
  { label: "Roll by ADP", value: "Roll by ADP" },
  { label: "Run by ADP", value: "Run by ADP" },
  { label: "Square", value: "Square" },
  { label: "Square Payroll", value: "Square Payroll" },
  { label: "TriNet Zenefits", value: "TriNet Zenefits" },
  { label: "Wage Point", value: "Wage Point" },
  { label: "Wave Payroll", value: "Wave Payroll" },
];
export default CompanyPayrollForm;
