import * as React from 'react';
import './payrollRun.css';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Avatar, TextField } from '@mui/material';
import { rowsStep2 as rows } from './dummydata';
import { colTextField, memberNameColumn } from './components/DataGridColumns';

const PayrollRunStep2 = () => {
  const columns = [
    memberNameColumn,
    colTextField('grossWages', 'Gross Wages'),
    colTextField('employeeBenefits', 'Employee Benefits', 160),
    colTextField('employeeTaxes', 'Employee Taxes', 160),
    colTextField('postTax', 'Post-tax Deductions', 160),
    colTextField('hetPay', 'Het Pay', 160),
    colTextField('companyTax', 'Company Tax', 160),
    colTextField('companyBenefits', 'Company Benefits', 160),
    colTextField('tetaiCost', 'Tetai Payroll Cost', 160),
  ];

  return (
    <>
      <Box
        sx={{
          height: 'auto',
          width: '100%',
          top: '180px',
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSizeOptions={[5]}
          getRowId={(row) => row.id}
          disableRowSelectionOnClick
          rowHeight={110}
          getRowClassName={(params) =>
            `row-${params.row.id % 2 === 0 ? 'even' : 'odd'}`
          }
        />
      </Box>
    </>
  );
};

export default PayrollRunStep2;
