import React, { useEffect, useState } from "react";
import Layout from "../../commonComponents/Layout";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

function QuickBookCallback() {
  const { userInfo, userToken, company } = useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [qbAuthCodeError, setQbAuthCodeError] = useState();
  const [qbUserInfo, setQbUserInfo] = useState();

  const parseQBAuthUrl = async () => {
    try {
      setIsLoading(true);
      const qbError = searchParams.get("error");
      if (qbError) {
        setQbAuthCodeError(qbError);
        setIsLoading(false);
        return;
      }
      const data = { companyId: company._id, url: window.location.href };
      const userInfo = await fetch(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/qb/authorize`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(data),
        }
      ).then((res) => res.json());
      setIsLoading(false);
      if (userInfo.success) {
        setQbUserInfo(userInfo.data);
      } else {
        setQbAuthCodeError(userInfo.error);
        console.log(qbAuthCodeError);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //localhost:3000/qb/callback?error=access_denied&state=testState
  useEffect(() => {
    if (userToken) {
      parseQBAuthUrl();
    }
  }, [userInfo]);

  return (
    <Layout>
      <div className="box-wrapper flex justify-between align-middle">
        <div className="title box">
          <h3 className="text-5xl">Connect to QuickBooks</h3>
        </div>
      </div>
      {isLoading ? (
        <p>Loading.........</p>
      ) : (
        <>
          {qbAuthCodeError ? (
            <p className="text-red">{JSON.stringify(qbAuthCodeError)}</p>
          ) : (
            <div className="flex flex-col">
              <div>connected</div>
              <div className="">
                <pre>{JSON.stringify(qbUserInfo, null, 2)}</pre>
              </div>
              <button
                className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md"
                onClick={() => navigate("/qb", { state: qbUserInfo })}
              >
                Continue
              </button>{" "}
            </div>
          )}
        </>
      )}
      <div></div>
    </Layout>
  );
}

export default QuickBookCallback;
