// payrollSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setError, setMessage } from './message.slice';

import { PayrollAPI } from '../../services/payroll.api';

export const getLatestPayroll = createAsyncThunk(
  'payroll/getLatestPayroll',
  async (_, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;
      console.log(`🚀 ~ activeCompany:`, activeCompany);

      const result = await PayrollAPI.getLatestPayroll(activeCompany.id);
      console.log(`🚀 ~ result.data.payroll:`, result.data.payroll);
      if (!result.data.payroll) {
        thunkAPI.dispatch(createPayroll());
        await thunkAPI.dispatch(getLatestPayroll());
        return;
      } // return result.data;
      if (result?.ok === true) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createPayroll = createAsyncThunk(
  'payroll/createPayroll',
  async (_, thunkAPI) => {
    try {
      const activeCompany = thunkAPI.getState().auth.company;
      const result = await PayrollAPI.createPayroll(activeCompany.id, {});
      if (result?.ok === true) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
// Async thunk for fetching payroll from API
export const retrievePayrollData = createAsyncThunk(
  'payroll/retrievePayrollData',
  async (_, thunkAPI) => {
    try {
      const payroll = thunkAPI.getState().payroll.payroll;
      const result = await PayrollAPI.retrievePayrollData(payroll.id); // Replace with your API function
      if (result?.ok === true) {
        return result.data;
      } else {
        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk for updating a payroll detail in the database
export const updatePayrollDetail = createAsyncThunk(
  'payroll/updatePayrollDetail',
  async (updatedPayrollDetail, thunkAPI) => {
    console.log(`🚀 ~ updatedPayrollDetail:`, updatedPayrollDetail);
    const {
      id,
      regularHrs,
      overTimeHrs,
      paidTimeOffHrs,
      sickLeavesHrs,
      paidHolidaysHrs,
      cashTips,
      paycheckTips,
      otherEarnings,
      grossWages,
    } = updatedPayrollDetail;
    const payrollDetailUpdate = {
      id,
      regularHrs,
      overTimeHrs,
      paidTimeOffHrs,
      sickLeavesHrs,
      paidHolidaysHrs,
      cashTips,
      paycheckTips,
      otherEarnings,
      grossWages,
    };
    console.log(`🚀 ~ payrollDetailUpdate:`, payrollDetailUpdate);
    try {
      const result = await PayrollAPI.updatePayrollDetailRecord(
        payrollDetailUpdate
      ); // Replace with your API function
      console.log(`🚀 ~ result:`, result);
      if (result?.ok === true) {
        // thunkAPI.dispatch(setMessage({ message: result.message }));
        return result.data;
      } else {
        // thunkAPI.dispatch(setError({ message: result.message }));

        return thunkAPI.rejectWithValue(result.error || result.message);
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Initial state
const initialStateValues = {
  payroll: null,
  payrollData: null,
  error: null,
  loading: false, // Added loading state
  rowModesModel: {}, // Add this line
  action: 'none',
};

// Slice
const payrollSlice = createSlice({
  name: 'payroll',
  initialState: initialStateValues,
  reducers: {
    clearPayroll: (state) => {
      state = initialStateValues;
      console.log(`🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ state:`, state);
    },
    setRowModesModel: (state, action) => {
      state.rowModesModel = action.payload;
    },
    updateRowMode: (state, action) => {
      const { id, mode, ignoreModifications } = action.payload;
      state.rowModesModel[id] = { mode, ignoreModifications };
    },
    setAction: (state, action) => {
      state.action = action.payload;
    },
    resetAction: (state) => {
      state.action = 'none';
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch payroll
      .addCase(getLatestPayroll.pending, (state) => {
        state.loading = true; // Set loading state to true
        state.error = null; // Clear previous error
      })
      .addCase(getLatestPayroll.fulfilled, (state, action) => {
        state.payroll = action.payload?.payroll; // Set fetched payroll
        // console.log(`🚀 ~ .addCase ~ payrollData:`, state.payrollData);
        state.loading = false; // Set loading state to false
      })
      .addCase(getLatestPayroll.rejected, (state, action) => {
        state.error = action.error.message; // Set error message
        state.loading = false; // Set loading state to false
      })
      .addCase(createPayroll.pending, (state) => {
        state.loading = true; // Set loading state to true
        state.error = null; // Clear previous error
      })
      .addCase(createPayroll.fulfilled, (state, action) => {
        state.payroll = action.payload.payroll; // Set fetched payroll
        // console.log(`🚀 ~ .addCase ~ payrollData:`, state.payrollData);
        state.loading = false; // Set loading state to false
      })
      .addCase(createPayroll.rejected, (state, action) => {
        state.error = action.error.message; // Set error message
        state.loading = false; // Set loading state to false
      })
      .addCase(retrievePayrollData.pending, (state) => {
        state.loading = true; // Set loading state to true
        state.error = null; // Clear previous error
      })
      .addCase(retrievePayrollData.fulfilled, (state, action) => {
        state.payrollData = action.payload.payroll; // Set fetched payroll
        // console.log(`🚀 ~ .addCase ~ payrollData:`, state.payrollData);
        state.loading = false; // Set loading state to false
      })
      .addCase(retrievePayrollData.rejected, (state, action) => {
        state.error = action.error.message; // Set error message
        state.loading = false; // Set loading state to false
      })
      // Update payroll detail
      .addCase(updatePayrollDetail.pending, (state) => {
        state.loading = true; // Set loading state to true
        state.error = null; // Clear previous error
      })
      .addCase(updatePayrollDetail.fulfilled, (state, action) => {
        console.log(`🚀 ~ .addCase ~ action:`, action.payload);
        // Update the payroll detail in the state with the updated one
        // state.payrollData.payrollDetail = state.payrollData.payrollDetail.map(
        //   (detail) =>
        //     detail.id === action.payload.id
        //       ? { ...detail, ...action.payload.updatedPayrollDetail }
        //       : detail
        // );
        state.payrollData.payrollDetail = state.payrollData.payrollDetail.map(
          (detail) => {
            if (detail.id === action.payload.updatedPayrollDetail.id) {
              const updatedDetail = {
                ...detail,
                ...action.payload.updatedPayrollDetail,
              };

              console.log(
                `🚀 ~ .addCase:updatePayrollDetail ~ updatedDetail:`,
                updatedDetail
              );
              return updatedDetail;
            } else {
              return detail;
            }
          }
        );
        state.rowModesModel = {
          ...state.rowModesModel,
          [action.payload.updatedPayrollDetail.id]: { mode: 'view' },
        };
        // console.log(
        //   `🚀 ~ .addCase ~ state.rowModesModel:`,
        //   state.rowModesModel[action.payload.updatedPayrollDetail.id]
        // );

        state.loading = false; // Set loading state to false
      })
      .addCase(updatePayrollDetail.rejected, (state, action) => {
        state.error = action.error.message; // Set error message
        state.loading = false; // Set loading state to false
      });
  },
});

// Selectors
export const selectPayroll = (state) => state.payroll.payroll;
export const selectPayrollData = (state) => state.payroll.payrollData;
export const selectError = (state) => state.payroll.error;
export const selectLoading = (state) => state.payroll.loading; // Added loading selector
export const selectRowModesModel = (state) => state.payroll.rowModesModel;
export const selectAction = (state) => state.payroll.action;

export const {
  setRowModesModel,
  updateRowMode,
  setAction,
  resetAction,
  clearPayroll,
} = payrollSlice.actions;

// Export actions and reducer
export default payrollSlice.reducer;
