import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import HomePage from '../pages/Home/Home.page';
import Report from '../pages/Report/Report.page';
import Document from '../pages/Document/Document.page';
import Contract from '../pages/Contract/Contract.page';

import AppOffer from '../pages/AppOffer/AppOffer.page';
import Login from '../pages/Login/Login.page';
import ForgetPassword from '../pages/Login/ForgetPassword.page';
import OnboardingRoutes from './OnboardingRoutes';
import PrivateRoute from './PrivateRoute';
import AccountingRoutes from './AccountingRoutes';
import StripePayments from '../pages/StripePayments/StripePayments.page';
import CreateUserAccount from '../pages/Onboarding/CreateUserAccount';
import ConfirmUserEmail from '../pages/Onboarding/ConfirmUserEmail';
import EmployeeInvitation from '../pages/Onboarding/EmployeeInvitation';
import PeopleRoutes from './PeopleRoutes';
import FundingPage from '../pages/Funding/Funding.page';
import PayrollPage from '../pages/Payroll/Payroll.page';
import PayrollRunPage from '../pages/Payroll/PayrollRun/PayrollRun.page';
import ResetPasswordPage from '../pages/Login/ResetPassword.page';
import Register from '../pages/Register/Register';

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />

        <Route path='/payroll-run' element={<PayrollRunPage />} />

        <Route path='/forgot-password' element={<ForgetPassword />} />
        <Route path='/reset-password' element={<ResetPasswordPage />} />
        <Route path='/onboarding/account' element={<CreateUserAccount />} />
        <Route path='/onboarding/verify' element={<ConfirmUserEmail />} />
        <Route path='/onboarding/invitation' element={<EmployeeInvitation />} />

        {
          //#region PROTECTED ROUTES
        }
        <Route element={<PrivateRoute />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/dashboard' element={<HomePage />} />
          <Route path='/onboarding/*' element={<OnboardingRoutes />} />
          <Route path='/reports' element={<Report />} />
          <Route path='/document' element={<Document />} />
          <Route path='/contact' element={<Contract />} />
          <Route path='/apps-offers' element={<AppOffer />} />
          <Route path='/payments' element={<StripePayments />} />
          <Route path='/people/*' element={<PeopleRoutes />} />
          <Route path='/funding/' element={<FundingPage />} />
          <Route path='/accounting/*' element={<AccountingRoutes />} />
          <Route path='/payroll/*' element={<PayrollPage />} />
          <Route path='/payroll/payroll-run' element={<PayrollRunPage />} />
        </Route>
        {
          //#endregion PROTECTED ROUTES
        }
        {
          //#region NOT USED ROUTES -----------------------------------------------
        }
        {/* REGISTER ROUTES */}
        {/* <Route path="register/create" element={<CreateAccount />} />
      <Route path="register/verify" element={<VerifyEmail />} />
      <Route path="register/company" element={<CompanyInfo />} />
      <Route path="register/plan" element={<SubscriptionPlans />} />
      <Route path="register/checkout" element={<SubscriptionPayment />} />
      <Route path="register/payment" element={<SubscriptionPayment />} />
      <Route path="register/onboard" element={<ScheduleOnBoard />} />
      <Route path="settings/my-profile" element={<MyProfile />} /> */}
        {/* BILLING ROUTES
      <Route path="company/billing" element={<Billing />} /> */}
        {/* REFER ROUTES
      <Route path="refer-a-friend" element={<ReferAFriend />} /> */}
        {/* RESOURCES ROUTES
      <Route
        path="resources/financial-calculators"
        element={<FinancialCalculators />}
      />
      <Route path="resources/ebooks" element={<Ebooks />} />
      <Route path="resources/faq" element={<Faq />} /> */}
        {/* ADMIN DASHBOARD
      <Route path="dashboard/admin" element={<AdminRouter />}>
        <Route path="users" element={<UsersPage />} />
        <Route path="testimonial" element={<TestimonialPage />} />
        <Route path="files" element={<Files />} />
      </Route> */}
        {/* DEMO CHILD ROUTES
      <Route
        path="contacts"
        element={
          <div>
            Parent Contacts Page Parent
            <Outlet />
          </div>
        }
      >
        <Route path="a/:contactId" element={<div>Child component ID</div>} />
      </Route> */}
        {
          // #endregion NOT USED ROUTES -----------------------------------------------
        }
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
