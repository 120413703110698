import React, { useEffect, useState } from "react";
import Layout from "../../commonComponents/Layout";

import StripeConnection from "./StripeConnection.component";
import { useSelector } from "react-redux";
import {
  selectCompany,
  selectToken,
  selectUserInfo,
} from "../../redux/features/auth.slice";

const StripePayments = () => {
  const user = useSelector(selectUserInfo);
  const userToken = useSelector(selectToken);
  const company = useSelector(selectCompany);

  const [isLoading, setIsLoading] = useState(false);
  const [statusErrors, setStatusErrors] = useState();
  const [stripeConnectionStatus, setStripeConnectionStatus] = useState(false);

  const getConnectedAccountStatus = async () => {
    try {
      setIsLoading(true);
      let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/stripe/account/status/${company._id}`;
      const stripeConnectedAccountStatus = await fetch(url, {
        headers: { Authorization: `Bearer ${userToken}` },
      }).then((res) => res.json());
      setIsLoading(false);
      if (stripeConnectedAccountStatus.success) {
        setStripeConnectionStatus(stripeConnectedAccountStatus.data.status);
      } else {
        setStatusErrors(stripeConnectedAccountStatus.error);
      }
    } catch (error) {
      console.log(error);
      setStatusErrors(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userToken) getConnectedAccountStatus();
  }, [userToken]);

  return (
    <Layout>
      <div className="box-wrapper flex justify-between align-middle">
        <div className="title box">
          <h3 className="text-5xl">Payments</h3>
        </div>
      </div>
      {isLoading ? (
        <p>Loading.........</p>
      ) : (
        <>
          {statusErrors ? (
            <p className="text-red">{JSON.stringify(statusErrors)}</p>
          ) : (
            <div>
              <div className="rounded-md mt-5 overflow-hidden">
                <StripeConnection
                  StripeConnectionStatus={stripeConnectionStatus}
                />
              </div>
            </div>
          )}
        </>
      )}
    </Layout>
  );
};
export default StripePayments;
