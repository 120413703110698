import { APISecured } from '../utility/APISecureClient';

const login = async (userEmail, userPassword) => {
  const response = await APISecured.post('/auth', {
    email: userEmail,
    password: userPassword,
  });
  return response.data;
};

const AuthAPI = {
  login,
};

export { AuthAPI };
