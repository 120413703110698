import { FormControl } from "@mui/material";
import { Field } from "formik";
import React from "react";
import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";
import FieldDatePicker from "../../../commonComponents/FormikMuiFields/FieldDatePicker";
import * as Yup from "yup";
import {
  requiredEmail,
  requiredName,
  requiredPhone,
  requiredSocialSecurity,
} from "../../../helpers/yupValidationRoles";
import { setOnboardUserField } from "../../../redux/features/onboardEmployee.slice";
import { useDispatch } from "react-redux";

export const validationSchema = Yup.object({
  firstName: requiredName,
  preferredFirstName: requiredName,
  middleInitial: requiredName,
  lastName: requiredName,
  birthday: Yup.string().required("Required Field"),
  socialSecurityNumber: requiredSocialSecurity,
  recoveryPhone: requiredPhone,
  recoveryEmail: requiredEmail,
});
const EmployeePersonalInformationForm = () => {
  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    //Create & Dispatch UserOnboardUpdateField action
    dispatch(setOnboardUserField({ fieldName, fieldValue }));
  };
  return (
    <>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="firstName"
          label="What is your first name?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="middleInitial"
          label="What is your middle initial?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="lastName"
          label="What is your last name?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>

      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="preferredFirstName"
          label="What is your preferred name?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl margin="normal" className="drop-shadow-md">
        <Field
          required
          className={"w-[100%] lg:w-[75%]"}
          component={FieldDatePicker}
          name="birthday"
          label="Please enter your birthday"
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="socialSecurityNumber"
          label="What is your social security number?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="recoveryEmail"
          label="What is your recovery email address?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          name="recoveryPhone"
          label="What is your recovery phone number?"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default EmployeePersonalInformationForm;
