import React, { useEffect } from 'react';
import { loadExternalScript } from '../../utility/ExternalScriptLoader';

const JarisConnect = ({ jarisMerchant }) => {
  useEffect(() => {
    // Load the jaris.js script dynamically
    loadExternalScript(process.env.REACT_APP_JARIS_SCRIPT_URL)
      .then(() => {
        // Script loaded successfully
        console.log('jarisMerchant', jarisMerchant);
        // eslint-disable-next-line no-undef
        const handler = jaris.init('connect', {
          token: jarisMerchant.token,
          shopId: jarisMerchant.shops[0].id,
        });

        const element = document.getElementById('jaris-component');

        if (element) {
          handler.mount(element);
        } else {
          console.error('Element with id "jaris-component" not found.');
        }
      })
      .catch((error) => {
        console.error('Error loading jaris.js:', error);
      });
  }, []);
  return (
    <div className='mt-4 flex border-1 border-black  bg-white h-[600px]'>
      {/* Provide an element for the external JavaScript component */}
      <div
        id='jaris-component'
        className='flex-1 w-[100%] text-lg bg-white min-h-[80vh]'
      ></div>
      {/* Your other JSX content */}
    </div>
  );
};

export default JarisConnect;
