import { Link } from 'react-router-dom';
import '../Login.css';
import newLogo from '../../../images/new_logo.svg';
import newLogoW from '../../../images/new_logo_white.svg';
const LoginPageLayout = ({ title, formComponent }) => {
  return (
    <div className='page-wrapper font-circular login text-center md:text-left flex flex-col align-middle justify-center h-screen'>
      <Link to='/' className='logo-wrapper mb-8 flex justify-center md:block'>
        <img
          className='hidden md:inline-block h-20 w-80 mx-4'
          src={newLogoW}
          alt='Hire Beyond Logo'
        />
        <img className='md:hidden h-20 w-80 mx-4' src={newLogo} alt='Logo' />
      </Link>
      <section className='login-page-section md:flex flex-wrap '>
        <div className='sidebar w-54 mb-5 mr-1 px-4 '>
          <h1 className='md:text-7xl block text-4xl mb-16 md:text-white'>
            {title}
          </h1>
          <div className='hidden md:inline-block md:mt-16'>
            <p className='md:text-white text-3xl mb-3'>
              {' '}
              Don't have an account?
            </p>
            <Link
              to='/register'
              className='md:text-white text-3xl font-semibold hover:underline'
            >
              Sign up now
            </Link>
          </div>
        </div>
        <div className='form-content flex-1'>{formComponent}</div>
        <div className='md:hidden mt-8'>
          <p className='md:text-white text-3xl mb-3'> Don't have an account?</p>
          <Link
            to='/register'
            className='md:text-white text-3xl font-semibold hover:underline'
          >
            Sign up now
          </Link>
        </div>
      </section>
    </div>
  );
};

export default LoginPageLayout;
