import { Route, Routes } from "react-router-dom";

const WizardStep = ({ path, element, description, name }) => {
  return (
    <Routes>
      <Route path={path} element={element} />
    </Routes>
  );
};

export default WizardStep;
