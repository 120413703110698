import React from "react";
import { Route, Routes } from "react-router-dom";
import Accounting from "../pages/Accounting/Accounting.page";
import QuickBookCallback from "../pages/Accounting/QuickBookCallback";
import QuickBookConnectedComponent from "../pages/Accounting/QuickBookConnectedComponent";
import XeroConnectionCallback from "../pages/Accounting/XeroConnectionCallback";

const AccountingRoutes = () => {
  return (
    <Routes>
      <Route index element={<Accounting />} />
      <Route path="/qb/callback" element={<QuickBookCallback />} />
      <Route path="/qb/connected" element={<QuickBookConnectedComponent />} />
      <Route path="/xero/callback" element={<XeroConnectionCallback />} />
      {/* <Route path="/xero/connected" element={<QuickBookConnectedComponent />} /> */}
    </Routes>
  );
};

export default AccountingRoutes;
