import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Register } from '../../redux/features/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './register.css';
import newLogo from '../../images/new_logo.svg';
import ReactGA from "react-ga4";
import moment from 'moment';
import { clearCredentials, setCredentials } from '../../redux/features/auth.slice';
import { generateSessionKeys } from '../../utility/crypto.utils';


// import newLogoW from '../../images/new_logo_white.svg';


const Registration = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [firstName, setfirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setname] = useState('');
    const [monthlyRevenue, setMonthlyRevenue] = useState('');
    const [dateEstablished, setDateEstablished] = useState('');
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');
    const [businessType, setBusinessType] = useState('');
    // const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [EIN, setEIN] = useState('');
    const [share, setShare] = useState('');
    const [street1, setStreet1] = useState('');
    const [street2, setStreet2] = useState('');
    const [zipCode, setZipCode] = useState('');

    useEffect(() => {
        document.title = "Register";
        ReactGA.send({ hitType: "pageview", page: "/register", title: "Register" });

      }, []);

      const handleSelectChange = (event) => {
        setBusinessType(event.target.value);
      };

      const handleSelectStateChange = (event) => {
        setSelectedState(event.target.value);
      };
      
      const handleChange = (event) => {
        let value = event.target.value;

            // Remove any non-numeric characters
            value = value.replace(/\D/g, '');

            // Add a dash after the first two digits
            if (value.length > 2) {
            value = `${value.slice(0, 2)}-${value.slice(2)}`;
            }

            setEIN(value);
      }

    const HandleSubmit = async (e) => {
    //    const dispatch = useDispatch();
        e.preventDefault();

        // Basic validation
        if (!firstName || !email || !password || !name || !monthlyRevenue || !dateEstablished || !lastName || !phone || !title || !businessType || !EIN || !share || !street1 || !city || !selectedState || !zipCode) {
            setError('Please fill in all fields');
            return;
        }

        let legalAddress = { street1, street2, city, state: selectedState, zipCode, phone};        
        let user =  { firstName, email, password, lastName, phone};
        let company = { name, monthlyRevenue, legalAddress, businessType, EIN, share};
        company.dateEstablished = moment(dateEstablished).toISOString();


        company.capitalOnly = true
        company.ownership = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            title: title
        }
        let data = { user, company};
        try {
            // Make an API request to register the user
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/users/register`, data);     
            console.log('User registered successfully!', response.data.data);
            const session = generateSessionKeys();

            let payload = {
                userInfo: {...response.data.data.userInfo, session},
                company: response.data.data.company,
                userToken: response.data.data.token,
            }
            console.log(payload)
            dispatch(setCredentials(payload)); 

            // Redirect to login or home page after successful registration
            navigate('/')
        } catch (error) {
            console.error('There was an error registering!', error);
            setError('Failed to register. Please try again.');
        }
    };

    return (
        <>
            <div className='header'>
                <img className='md:inline-block h-20 w-80 mx-4' src={newLogo} alt='Logo' />
            </div>
        <div className="registration-container">
            <div className="content-container">
                <h2>Ready to apply for business financing?</h2>
                <h3>Great, you're in the right place.</h3>
                <ul>
                    <li>Registering is fast and free</li>
                    <li>Get a decision quickly with no obligation</li>
                    <li>Applying will not affect your credit score</li>
                </ul>
            </div>
            <div className="form-container">
            {error && <p className="error">{error}</p>}
            <form onSubmit={HandleSubmit} className='form-divs-container'>
                <div className='half-width'>
                    <label htmlFor="name">First Name:</label>
                    <input
                        type="text"
                        id="name"
                        value={firstName}
                      
                        onChange={(e) => setfirstName(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="name">Last Name:</label>
                    <input
                        type="text"
                        id="lastName"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="name">Company Name:</label>
                    <input
                        type="name"
                        id="name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="name">Business Type:</label>
                    <select id="my-select" value={businessType} onChange={handleSelectChange}>
                        <option value="">--Please choose an option--</option>
                        <option value="sole_proprietor">Sole Proprietor</option>
                        <option value="single_member_llc">Single Member LLC</option>
                        <option value="LLC">LLC</option>
                        </select>
                </div>
                <div className='half-width'>
                    <label htmlFor="name">Phone:</label>
                    <input
                        type="text"
                        id="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="text">Title:</label>
                    <input

                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="text">EIN:</label>
                    <input
                        type="text"
                        id="EIN"
                        value={EIN}
                        maxLength="10" // Maximum length to accommodate the EIN format XX-XXXXXXX
                        minLength="10"
                        onChange={handleChange}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="text">Share:</label>
                    <input
                        type="number"
                        id="share"
                        min="1"
                        max="100"
                        value={share}
                        onChange={(e) => setShare(e.target.value)}
                    />
                </div>
                <p style={{fontWeight: 700}}>What's your business address?</p>

                <div style={{minWidth: "100%", display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                <div className='half-width'>
                    <label htmlFor="text">Street 1:</label>
                    <input
                        type="text"
                        id="street1"
                        value={street1}
                        onChange={(e) => setStreet1(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="text">Street 2:</label>
                    <input
                        type="text"
                        id="street2"
                        value={street2}
                        onChange={(e) => setStreet2(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="text">City:</label>
                    <input
                        type="text"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="state-select">State:</label>
                    <select id="state-select" value={selectedState} onChange={handleSelectStateChange}>
                        <option value="">--Please choose a state--</option>
                        {states.map((state) => (
                        <option key={state.abbreviation} value={state.abbreviation}>
                            {state.name}
                        </option>
                        ))}
                    </select>
                    </div>
                    <div className='half-width'>
                    <label htmlFor="state-select">Zip Code:</label>
                    <input
                        type="number"
                        id="zipCode"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                    />
                    </div>
                </div>
                <div className='half-width'>
                    <label htmlFor="monthlyRevenue">Monthly Revenue:</label>
                    <input
                        type="text"
                        id="monthlyRevenue"
                        value={monthlyRevenue}
                        onChange={(e) => setMonthlyRevenue(e.target.value)}
                    />
                </div>
                <div className='half-width'>
                    <label htmlFor="dateEstablished">Date Established:</label>
                    <input
                        type="date"
                        id="dateEstablished"
                        value={dateEstablished}
                        onChange={(e) => setDateEstablished(e.target.value)}
                    />
                </div>
                <button className='button-primary login-btn py-4 bg-secondary hover:bg-primary text-white font-bold'
 type="submit">Register</button>
            </form>
        </div>
    </div>
    </>
    );
};

const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];
  
export default Registration;
