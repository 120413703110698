import React, { useState } from 'react';
import TeamMemberForm from './TeamMemberForm';
import { Icon } from '@iconify/react';
import { useDispatch } from 'react-redux';
import {
  closeAddEmployeeDrawer,
  resetAddEmployeeForm,
} from '../../redux/features/companyEmployees.slice';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

const AddNewTeamMemberPanel = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleConfirm = () => {
    dispatch(closeAddEmployeeDrawer());
    dispatch(resetAddEmployeeForm());
    handleClose();
  };
  const dispatch = useDispatch();
  return (
    <div className='w-[50vw] min-w-[600px] relative'>
      <div className='flex justify-between items-center bg-primary top-0 sticky z-30 shadow-md shadow-[#737373] p-2'>
        <h2 className='text-white text-2xl font-semibold font-sans '>
          Add New Team Member
        </h2>
        <button
          className='text-white hover:text-red'
          onClick={() => setOpenDialog(true)}
        >
          <Icon icon='gg:close-r' className='text-3xl button_secondary' />
        </button>
      </div>
      <div className='p-5 '>
        {/* form */}
        <TeamMemberForm />
      </div>
      <Dialog open={openDialog} onClose={handleClose}>
        {/* <form onSubmit={handleSubmit}> */}
        <DialogTitle className='w-[400px]'>{`Close Add new team member`}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close Add Team Member and clear all data?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className='button_success' onClick={handleClose}>
            Cancel
          </button>
          <button className='button_danger' onClick={handleConfirm}>
            Clear
          </button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
    </div>
  );
};

export default AddNewTeamMemberPanel;
