import { Icon } from '@iconify/react';
import React from 'react';
import Layout from '../../commonComponents/Layout';

const Contract = () => {
    return (
        <Layout>
            <div className='box-wrapper flex justify-between align-middle'>
                <div className="title box"><h3 className='text-5xl'>Contacts & Owners</h3> </div>
                <div className="button-box">
                    <button className='text-xl border border-primary hover:border-secondary hover:bg-secondary text-primary py-2 px-8 rounded-md mr-5'>Manage Ownership</button>
                    <button className='text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md'>Add Contact</button>
                </div>
            </div>
            <h3 className='text-xl mt-9'>Primary Contact</h3>
            <div className="table-wrapper border border-borderColor rounded-md mt-5 overflow-hidden">
            {
                // <div className="table-heading grid grid-cols-4 bg-gray p-5">
                //     <span className='font-bold'>Name</span>
                //     <span className='font-bold inline-flex items-center'>Ownership </span>
                //     <span className='font-bold'>Preferred Contact </span>
                //     <span className='flex justify-end'></span>
                // </div>
                // <div className="grid grid-cols-4 p-5 border-b border-borderColor">
                //     <span className=''>
                //         <span className='font-bold block font-xl'> Rich Sibert</span>
                //         <span> <Icon className='inline-block' icon="ep:select" /><span>Portal Access</span></span>
                //     </span>
                //     <span className=''> <span className='rounded-full bg-primary py-1 px-5 text-white text-sm'>100%</span> </span>
                //     <span className='flex'>
                //         <Icon className='m-2 text-borderColor hover:text-primary' height={25} icon="material-symbols:mail-rounded" />
                //         <Icon className='m-2 text-borderColor hover:text-primary' height={25} icon="ic:baseline-phone-in-talk" />
                //         <Icon className='m-2 text-borderColor hover:text-primary' height={25} icon="mdi:chat-processing-outline" />
                //     </span>
                //     <span className=' text-right'>Edit | Delete</span>
                // </div>
              }
            </div>
        </Layout>
    );
};

export default Contract;
