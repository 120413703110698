import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  selectCompany,
  selectToken,
  selectUserInfo,
} from "../../redux/features/auth.slice";

const XeroConnection = () => {
  const user = useSelector(selectUserInfo);
  const company = useSelector(selectCompany);
  const userToken = useSelector(selectToken);

  const [isLoading, setIsLoading] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);
  const [xeroConnectionError, setXeroConnectionError] = useState(null);
  const [isXeroConnected, setIsXeroConnected] = useState(false);
  const [xeroConnectedAccount, setXeroConnectedAccount] = useState(null);

  const getXeroConnectionStatus = async () => {
    setIsLoading(true);
    setXeroConnectionError(null);
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/xero/connections/status/${company._id}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    ).then((res) => res.json());
    if (response.success === true) {
      console.info(response.data);
      setIsXeroConnected(response.data?.status);
      setXeroConnectedAccount(response.data.xeroAccount);
    } else {
      setXeroConnectionError(response.error);
      setIsXeroConnected(false);
    }
    setIsLoading(false);
  };

  const quickBooksConnectHandler = async () => {
    setIsRevoking(true);
    setXeroConnectionError(null);
    const xeroConnectURL = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/xero/connections`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    ).then((res) => res.json());
    if (xeroConnectURL.success === true) {
      console.info(xeroConnectURL.data);
      window.open(xeroConnectURL.data.url, "_self");
      setIsRevoking(false);
    } else {
      setXeroConnectionError(xeroConnectURL.error);
    }
  };

  const xeroAccountRevokeAccessHandler = async (connectionId) => {
    setIsLoading(true);
    setXeroConnectionError(null);
    // const reqBody = { companyId: company._id };
    const revoked = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/xero/connections/${company._id}`,
      {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        // body: JSON.stringify(reqBody),
      }
    ).then((res) => res.json());
    console.log(
      "🚀 ~ file: XeroConnection.jsx:73 ~ xeroAccountRevokeAccessHandler ~ revoked:",
      revoked
    );
    setIsLoading(false);

    if (revoked.success === true) {
      console.info(revoked);
      setIsXeroConnected(false);
    } else {
      setXeroConnectionError(revoked.error);
    }
  };

  useEffect(() => {
    if (userToken && company) getXeroConnectionStatus();
  }, [company, userToken]);

  return (
    <div className="mt-2 rounded-md border border-borderColor">
      <div className="flex justify-between mt-2 p-6 ">
        <div className="flex justify-center items-center">
          <img
            className="w-28 mx-3"
            src="https://client.xendoo.com/images/xero-logo.svg"
            alt=""
          />
          <h1 className="text-2xl">XERO Accounting Software</h1>
        </div>
        {xeroConnectionError && (
          <p className="text-red">{JSON.stringify(xeroConnectionError)}</p>
        )}
        <div className="flex flex-col align-middle justify-center px-2">
          {isLoading ? (
            <p>loading ....</p>
          ) : (
            !isXeroConnected && (
              <button
                onClick={quickBooksConnectHandler}
                className="text-xl bg-primary hover:bg-secondary text-white py-2 px-8 rounded-md"
              >
                Connect Xero
              </button>
            )
          )}
        </div>
      </div>
      {xeroConnectedAccount?.status === "CONNECTED" && isXeroConnected && (
        <div className="flex justify-between items-center m-6 border-t border-b p-2">
          <div>{xeroConnectedAccount.xero_tenant.tenantName.toUpperCase()}</div>
          <div>{xeroConnectedAccount.xero_tenant.tenantType}</div>
          <div>{xeroConnectedAccount.status}</div>

          <button
            onClick={xeroAccountRevokeAccessHandler}
            disabled={isRevoking}
            className="text-xl bg-red hover:bg-secondary text-white py-2 px-8 rounded-md"
          >
            Revoke Access
          </button>
        </div>
      )}
    </div>
  );
};

export default XeroConnection;
