import React, { useEffect, useState } from "react";
import QuickBooksComponent from "./QuickBooksComponent";
import Layout from "../../commonComponents/Layout";
import QuickBookConnectedComponent from "./QuickBookConnectedComponent";
import { useSelector } from "react-redux";
import XeroConnection from "./XeroConnection";

function Accounting() {
  const { userInfo, userToken, company } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [quickBooksConnectError, setQuickBooksConnectError] = useState();
  const [isQBConnected, setIsQBConnected] = useState(false);

  const quickBooksConnectStatus = async () => {
    setIsLoading(true);
    setQuickBooksConnectError(null);
    const status = await fetch(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/qb/status/${company._id}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    ).then((res) => res.json());
    setIsLoading(false);
    if (status.success) {
      console.info(status.data);
      setIsQBConnected(status.data);
    } else {
      setQuickBooksConnectError(status.error);
      setIsQBConnected(false);
    }
  };

  const connectedStatusHandler = (status) => {
    setIsQBConnected(status);
  };
  useEffect(() => {
    if (userToken) quickBooksConnectStatus();
  }, [userToken]);
  return (
    <Layout>
      <div className="box-wrapper flex justify-between align-middle">
        <div className="title box mb-6">
          <h3 className="text-5xl">Accounting</h3>
        </div>
      </div>
      {/* <div className="mt-2">
        {isLoading ? (
          <p>Loading.........</p>
        ) : (
          <>
            <div>
              <div>
                <QuickBooksComponent
                  isConnected={isQBConnected}
                  isConnectedHandler={connectedStatusHandler}
                />
              </div>
              {isQBConnected && (
                <div>
                  <QuickBookConnectedComponent />
                </div>
              )}
            </div>
          </>
        )}
      </div> */}

      <div className="mt-2">
        {isLoading ? (
          <p>Loading.........</p>
        ) : (
          <>
            <div>
              <div>
                <XeroConnection />
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
}

export default Accounting;
