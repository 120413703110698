import CompanyPayrollForm from './OnboardCompanyForms/CompanyPayrollForm';
import Wizard from '../../commonComponents/FormikRoutedWizard/Wizard';
import WizardStep from '../../commonComponents/FormikRoutedWizard/WizardStep';
import { validationSchema as payrollValidationSchema } from './OnboardCompanyForms/CompanyPayrollForm';
import { validationSchema as ownershipValidationSchema } from './OnboardCompanyForms/CompanyOwnershipForm';
import { validationSchema as legalAddressValidationSchema } from './OnboardCompanyForms/CompanyLegalAddressesForm';
import { validationSchema as mailingAddressValidationSchema } from './OnboardCompanyForms/CompanyMailingAddressesForm';
import { validationSchema as federalTaxValidationSchema } from './OnboardCompanyForms/CompanyFederalTaxForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  addCompany,
  selectOnboardStatus,
  setFormSteps,
  updateActiveStep,
} from '../../redux/features/onboardCompany.slice';
import CompanyOwnershipForm from './OnboardCompanyForms/CompanyOwnershipForm';
import OnboardingContentWrapper from './components/OnboardingContentWrapper';
import CompanyLegalAddressesForm from './OnboardCompanyForms/CompanyLegalAddressesForm';
import CompanyMailingAddressForm from './OnboardCompanyForms/CompanyMailingAddressesForm';
import CompanyFederalTaxForm from './OnboardCompanyForms/CompanyFederalTaxForm';
import OnboardingStepper from './components/OnboardingStepper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { selectCompany } from '../../redux/features/auth.slice';

const validationSchema = [
  payrollValidationSchema,
  ownershipValidationSchema,
  legalAddressValidationSchema,
  mailingAddressValidationSchema,
  federalTaxValidationSchema,
];
const OnboardCompany = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onboardData = useSelector((state) => state.onboardCompany.formData);
  const activeStep = useSelector((state) => state.onboardCompany.activeStep);
  const onboardStatus = useSelector(selectOnboardStatus);
  const company = useSelector(selectCompany);
  const steps = useSelector((state) => state.onboardCompany.formSteps);

  useEffect(() => {
    if (onboardStatus === 'succeeded') {
      // navigate('/onboarding/company/bank-account');
      toast.success('Progress saved');
    } else if (onboardStatus === 'failed') {
      toast.error(
        'Company was not created. Please try again later. If issue persists, contact support.'
      );
    }
  }, [onboardStatus]);
  useEffect(() => {
    if (company && steps) {
      //TODO: [Temporarily] disable forced navigation to check for BankAccount
      if (company?.EIN?.length === 9) {
        navigate('/onboarding/company/completed');
      }
      // Temporarily disable needing plaid link
      // if (company?.plaid?.length === 0) {
      //   navigate('/onboarding/company/bank-account');
      // }
    }
  }, [company, steps]);
  return (
    <OnboardingContentWrapper>
      <div className='flex-1 text-2xl mt-10 text-center font-bold px-2'>
        <OnboardingStepper />
      </div>

      <div className='flex-1 p-1 flex flex-row '>
        <Wizard
          wizardRoutePath='/onboarding/company'
          initialValues={onboardData}
          onSubmit={(values) => {
            dispatch(addCompany(values));
          }}
          validationSchema={validationSchema[activeStep]}
          onFormStepsChange={(steps) => {
            dispatch(setFormSteps(steps));
          }}
          onActiveStepChange={(activeStep) => {
            dispatch(updateActiveStep(activeStep));
          }}
        >
          <WizardStep
            label='Payroll'
            path='payroll'
            element={<CompanyPayrollForm />}
            description={'Company Payroll Configuration'}
          />
          <WizardStep
            label='Ownership'
            path='ownership'
            element={<CompanyOwnershipForm />}
            description={'Company Ownership Structure'}
          />
          <WizardStep
            label='Legal Address'
            path='legal-address'
            element={<CompanyLegalAddressesForm />}
            description={'Company Legal Address'}
          />
          <WizardStep
            label='Mailing Address'
            path='mailing-address'
            element={<CompanyMailingAddressForm />}
            description={'Company Mailing Address'}
          />
          <WizardStep
            label='Federal Tax'
            path='tax'
            element={<CompanyFederalTaxForm />}
            description={'Company Federal Tax'}
          />
        </Wizard>
      </div>
    </OnboardingContentWrapper>
  );
};

export default OnboardCompany;
