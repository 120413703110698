import { FormControl } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import FieldTextField from "../../../commonComponents/FormikMuiFields/FieldTextField";
import FieldCheckbox from "../../../commonComponents/FormikMuiFields/FieldCheckboxWithLabel";
import { useEffect, useState } from "react";
import {
  copyResidentAddressIntoWorkAddress,
  copyResidentAddressIntoWorkAddressIntoAddress,
  selectSameAsResidentAddress,
  setOnboardUserFormDataField,
} from "../../../redux/features/onboardEmployee.slice";
import { PhoneNumber } from "../../../helpers/yupValidationRoles";

export const validationSchema = Yup.object({
  workAddress: Yup.object().shape({
    sameAsResidentAddress: Yup.boolean(),
    street1: Yup.string().when("sameAsResidentAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    street2: Yup.string(),
    city: Yup.string().when("sameAsResidentAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    state: Yup.string().when("sameAsResidentAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    zipCode: Yup.string().when("sameAsResidentAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
    phone: PhoneNumber.when("sameAsResidentAddress", {
      is: false,
      then: (schema) => schema.required("Required Field"),
    }),
  }),
});

const EmployeeWorkAddressForm = () => {
  const sameAsResidentAddress = useSelector(selectSameAsResidentAddress);

  const dispatch = useDispatch();

  const handleFieldValueChange = (fieldName, fieldValue) => {
    //: Create & Dispatch UserOnboardUpdateField action
    dispatch(
      setOnboardUserFormDataField({
        parent: fieldName.split(".")[0],
        fieldName: fieldName.split(".")[1],
        fieldValue,
      })
    );
  };
  useEffect(() => {
    if (sameAsResidentAddress) {
      //: update with Dispatch & action to copy residentAddress
      dispatch(copyResidentAddressIntoWorkAddress());
    }
  }, [dispatch, sameAsResidentAddress]);
  return (
    <>
      <h1 className="text-xl">Work Address</h1>
      <p className="font-semibold">Please enter your work address:</p>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          name="workAddress.sameAsResidentAddress"
          label="Same as legal address?"
          component={FieldCheckbox}
          onFieldValueChanged={handleFieldValueChange}
        >
          Same as Resident Address
        </Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          required
          disabled={sameAsResidentAddress}
          name="workAddress.street1"
          label="Street 1"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsResidentAddress}
          name="workAddress.street2"
          label="Street 2"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsResidentAddress}
          required
          name="workAddress.city"
          label="City"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsResidentAddress}
          required
          name="workAddress.state"
          label="State"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        ></Field>
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsResidentAddress}
          required
          name="workAddress.zipCode"
          label="Zip Code"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
      <FormControl fullWidth margin="normal" className="drop-shadow-md">
        <Field
          disabled={sameAsResidentAddress}
          required
          name="workAddress.phone"
          label="Phone Number"
          component={FieldTextField}
          onFieldValueChanged={handleFieldValueChange}
        />
      </FormControl>
    </>
  );
};

export default EmployeeWorkAddressForm;
