import { Icon } from "@iconify/react";
import React from "react";

const Shortcut = ({ name, iconName, onShortcutClick }) => {
  return (
    <div
      onClick={onShortcutClick}
      className='flex cursor-pointer flex-col justify-center items-center align-middle text-center'
    >
      <div className='shortcuts-container'>
        <Icon icon={iconName} />
      </div>
      <div className='flex text-sm'>{name}</div>
    </div>
  );
};

export default Shortcut;
