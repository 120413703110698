import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Stack, StepLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import PayrollRunStep1 from './PayrollRunStep1';
import PayrollRunStep2 from './PayrollRunStep2';
import PayrollRunStep3 from './PayrollRunStep3';
import './payrollRun.css';
import CustomButton from './CustomButton';

const steps = ['HOURS AND TIME OFF', 'NET PAY', 'REVIEW AND SUBMIT'];

const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7849FF',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7849FF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#B9B9B9',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const ColorLibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#000000',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#7849FF',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: '#ffffff',
  }),
  ...(ownerState.completed && {
    backgroundColor: '#7849FF',
    color: '#ffffff',
  }),
}));

function ColorLibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: (
      <Typography
        className='font-circular'
        sx={{ fontSize: '20px', fontWeight: '500' }}
      >
        1
      </Typography>
    ),
    2: (
      <Typography
        className='font-circular'
        sx={{ fontSize: '20px', fontWeight: '500' }}
      >
        2
      </Typography>
    ),
    3: (
      <Typography
        className='font-circular'
        sx={{ fontSize: '20px', fontWeight: '500' }}
      >
        3
      </Typography>
    ),
  };

  return (
    <ColorLibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorLibStepIconRoot>
  );
}

const PayrollRunPage = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const pages = [<PayrollRunStep1 />, <PayrollRunStep2 />, <PayrollRunStep3 />];

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          pt: '45px',
          pb: '28px',
          ml: 'auto',
          bgcolor: '#FBFBFB',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between' },
        }}
      >
        <Box
          sx={{
            width: {
              xs: 'auto',
              md: '50%',
            },
            mx: 'auto',
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
            connector={<ColorLibConnector />}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepButton
                  color='inherit'
                  onClick={handleStep(index)}
                  completed={completed[index]}
                >
                  <StepLabel
                    StepIconComponent={ColorLibStepIcon}
                    className='font-circular'
                  >
                    {label}
                  </StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Box>
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            mt: { xs: '30px', md: '15px' },
            height: '100px',
            backgroundColor: 'yellow',
          }}
        > */}
        <Stack spacing={1} direction='row' className='flex px-5  items-center'>
          <button className='button_link'>Due in 9 hr 4 min</button>
          <button className='button_outlined'>Save and Exit</button>
          <button
            variant='standard'
            className='button_primary -px-2'
            onClick={handleNext}
            disabled={activeStep === steps.length - 1 && !completed[activeStep]}
          >
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </button>
        </Stack>
        {/* </Box> */}
      </Box>
      <div>{pages[activeStep]}</div>
    </Box>
  );
};

export default PayrollRunPage;
