import forge from 'node-forge';

export const generateSessionKeys = () => {
  try {
    const sessionKey = forge.random.getBytesSync(32);
    const iv = forge.random.getBytesSync(16);
    return {
      key: forge.util.encode64(sessionKey),
      iv: forge.util.bytesToHex(iv),
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const encryptSession = (publicKey, session) => {
  try {
    // Encrypt the session key using the user's public key
    const publicKeyObj = forge.pki.publicKeyFromPem(publicKey);
    const sessionBuffer = forge.util.createBuffer(JSON.stringify(session));
    const encryptedSession = publicKeyObj.encrypt(
      sessionBuffer.getBytes(),
      'RSA-OAEP',
      {
        md: forge.md.sha256.create(),
      }
    );
    return forge.util.encode64(encryptedSession);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const encryptData = (data, session) => {
  try {
    const cipher = forge.cipher.createCipher(
      'AES-CBC',
      forge.util.createBuffer(forge.util.decode64(session.key))
    );
    cipher.start({
      iv: forge.util.createBuffer(forge.util.hexToBytes(session.iv)),
    });
    cipher.update(forge.util.createBuffer(data, 'utf-8'));
    cipher.finish();
    let encryptedData = cipher.output.getBytes();
    return forge.util.encode64(encryptedData);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const decryptData = (encryptedData, session) => {
  try {
    const decipher = forge.cipher.createDecipher(
      'AES-CBC',
      forge.util.createBuffer(forge.util.decode64(session.key))
    );
    decipher.start({
      iv: forge.util.createBuffer(forge.util.hexToBytes(session.iv)),
    });
    decipher.update(
      forge.util.createBuffer(forge.util.decode64(encryptedData))
    );
    decipher.finish();
    let decryptedData = decipher.output.toString();
    return decryptedData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
